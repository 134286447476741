import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Modal_alert from "../../../common/modal";


function RegisterPayplan(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);

    const [btnText, setBtntext] = useState("");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [delete_show, setDeleteShow] = useState(false);
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const [monetaryUnit] = useState([{unit:'won', text:"원"}, {unit:'dollar', text:"달러"}, {unit:'yen', text:"엔"}]);
    const [plan_type] = useState([{type:'MONTHLY', text:'월간'}, {type:'YEARLY', text:"연간"}, {type:'IRREGULAR', text:'비정기'}]);
    const[values, setValues] = useState({uid : 0, planname:"", servicetype:"", plantype:plan_type[0].type, fee:"", monetary:monetaryUnit[0].unit, credit:"", valid_month:"", ispublic:false});

    const handleChange = e => {
        console.log(e.target.name+": "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handlePublic = () =>{
        setValues({
            ...values,
            ispublic :  !(values.ispublic),
        })
    };

    const handleSubmit_delete = (event) => {
        event.preventDefault();
        setDeleteShow(false);
        deletePayplan();
      };

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }else{
        regist();
      }

      setValidated(true);
      
    };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/Payplan");
    };
    const handleShow = () => setShow(true);

    
    const getServiceType = () =>{
        axios.get('/rest/admin/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setServiceType(()=>{return response.data});
                if(location.state == null || location.state.mode != 'mod'){
                    setValues({
                        ...values,
                        servicetype: response.data[0].uid,
                    })
                }
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );

    }
    const getPayplan = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/getPayplan',
        { params : {uid : location.state.uid}}).then(response =>{
            setValues({uid : response.data.uid, planname:response.data.plan_name, servicetype:response.data.service_uid, plantype:response.data.plan_type, fee: response.data.fee, monetary: response.data.monetary, credit:response.data.credit_size, ispublic:response.data.ispublic, valid_month:response.data.valid_month});
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const deletePayplan = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deletePayplan',
        { params : {uid : location.state.uid}}).then(response =>{
            setDeleteCompleteShow(true);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const regist = () =>{
        setLoading(true);
        getServiceType();
        console.log(values.ispublic);
        axios.post('/rest/admin/registerPayplan',
        { 
            uid: values.uid,
            plan_name: values.planname,
            service_uid: Number(values.servicetype),
            plan_type : values.plantype,
            fee : Number(values.fee),
            monetary_unit : values.monetary,
            credit_size : Number(values.credit),
            valid_month : Number(values.valid_month),
            ispublic : values.ispublic
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("플랜 등록 성공");
                    setModalContent("플랜등록에 성공하였습니다.");
                    setRegisterResult(true);
                }else{
                    setModalTitle("플랜 등록 실패");
                    setModalContent("플랜등록에 실패하였습니다.");
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(() => {}, []);
    useEffect(() => {
        if(location.state != null && location.state.mode == 'mod'){
            setTitle(()=>{
                return "플랜 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
            getPayplan();
        }else{
            setTitle(()=>{
                return "플랜 신규 등록";
            })
            setBtntext(()=>{
                return "등록";
            })
            setValues({uid : 0, planname:"", servicetype:0, plantype:plan_type[0].type, fee:"", monetary:monetaryUnit[0].unit, credit:"", ispublic:false});
        }
        getServiceType();
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>플랜이름</Form.Label>
                                    <Form.Control required type="text" name="planname" value={values.planname} onChange={handleChange} placeholder="플랜이름 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        플랜이름을 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        홈페이지에 공개될 플랜이름입니다.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>서비스종류</Form.Label>
                                    <Form.Select name="servicetype" value={values.servicetype} onChange={handleChange} aria-label="서비스종류 선택">
                                        {serviceType.map(function(item, idx){
                                            return (<option key={idx} value={item.uid}>{item.service_name}</option>);
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>플랜 종류</Form.Label>
                                    <Form.Select name="plantype" value={values.plantype} onChange={handleChange} aria-label="플랜종류 선택">
                                        {plan_type.map(function(item, idx){
                                            return (<option key={idx} value={item.type}>{item.text}</option>);
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>요금</Form.Label>
                                    <Form.Control required type="number" name="fee" value={values.fee} onChange={handleChange} placeholder="요금 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        요금을 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        1회 결제당 요금입니다.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>화폐단위</Form.Label>
                                    <Form.Select name="monetary" value={values.monetary} onChange={handleChange} aria-label="화폐단위 선택">
                                        {monetaryUnit.map(function(item, idx){
                                            return(<option key={idx} value={item.unit}>{item.text}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>크레딧</Form.Label>
                                    <Form.Control required type="number" name="credit" value={values.credit} onChange={handleChange} placeholder="크레딧 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        크레딧을 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        1회당 지급되는 크레딧입니다.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>유효기간(개월)</Form.Label>
                                    <Form.Control required type="number" name="valid_month" defaultValue={values.valid_month} onChange={handleChange} placeholder="유효개월 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        크레딧의 유효개월을 입력해주세요.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        크레딧의 유효개월입니다.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>공개여부</Form.Label>
                                    <Form.Check name="ispublic" type="switch" checked={values.ispublic} onChange={handlePublic} label="공개"></Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            
                            <Col sm={1}>
                                <Button type="button" className={`w-100 btn btn-secondary ${location.state != null && location.state.mode == 'mod' ? "" : "d-none"}`} onClick={()=>{setDeleteShow(true)}}>삭제</Button>
                            </Col>
                            <Col sm={1}>
                                <Button type="submit" className="w-100">{btnText}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                {location.state != null && location.state.mode == 'mod' ? 
                <><Modal_alert show={delete_show} handleClose={()=>{setDeleteShow(false)}} handleSubmit={handleSubmit_delete} modal_title="" content="정말 플랜을 삭제하시겠습니까?" secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={delete_complete_show} handleClose={()=>{setDeleteCompleteShow(false); navigate("/admin/Payplan"); }} modal_title="삭제완료" content="플랜이 삭제되었습니다." primary_btn={primary_btn}/></>
                :
                ""
                }
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
            </div>
        </>
        );

}

export default RegisterPayplan;
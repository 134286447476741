import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";


function WebAPI_NLP(props){
    
    const [strings, setStrings] = useState([["", ""]]);
    const [result, setResult] = useState("");

    const api_code = "nlp";
    const [api_version, setAPIVersion] = useState("");

    const onChangeAPIVersion = (e)=>{
        setAPIVersion(e.target.value);
    };

    const addMultiwordsRow = ()=>{
        setStrings([...strings, ["", ""]]);
    }
    const addBatchwordsRow = ()=>{
        setStrings([...strings, ""]);
        setStrings([...strings, "",""]);
    }


    const Request_SingleWords = ()=>{
        if(strings[0][0] == "" || strings[0][1] == "" ){
            alert("단어를 입력해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        props.setLoading(true);
        let data = {
            service_uid : 1,
            api_code : api_code,
            api_subname : "singleWords",
            'api-version' : api_version,
            string1:strings[0][0],
            string2:strings[0][1],
        };
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);

        axios.post('/rest/webapi', formData).then((response)=>{
            if(response.data.result == 'success'){
                let res = "유사도 비교 : "+strings[0][0]+", "+strings[0][1];
                res += "\r\n유사도 : "+response.data.data.similarity;
                setResult(res);
            }else{
                if(response.data.data != undefined){
                    if(response.data.data.error == 425||response.data.data.error == 427){
                        alert("크레딧이 모두 소진되었습니다. 크레딧을 구입해 주세요.");
                        window.open('/subscribe');
                    }else{
                        alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                    }
                }else{
                    alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                }
            }
            props.setLoading(false);
        }).catch((error)=>{
            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
            props.setLoading(false);
        });

    }

    const Request_MultiWords = ()=>{
        let arr = [];
        for(let i=0; i<strings.length; i++){
            if(strings[i][0]!="" && strings[i][1] != "")
                arr[i] = {"st1":strings[i][0], "st2":strings[i][1]};
        }

        if(arr.length == 0){
            alert("단어를 입력해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        props.setLoading(true);
        let data = {
            service_uid : 1,
            api_code : api_code,
            api_subname : "multiWords",
            'api-version' : api_version,
            data : JSON.stringify(arr)
        };
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);
        axios.post('/rest/webapi', formData).then((response)=>{
            if(response.data.result == 'success'){
                let res = "";
                response.data.data.data.map(function(a, idx){
                    res += "유사도 비교 : "+strings[idx][0]+", "+strings[idx][1];
                    res += "\r\n유사도 : "+a+"\r\n";  
                })
                setResult(res);
            }else{
                if(response.data.data != undefined){
                    if(response.data.data.error == 425||response.data.data.error == 427){
                        alert("크레딧이 모두 소진되었습니다. 크레딧을 구입해 주세요.");
                        window.open('/subscribe');
                    }else{
                        alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                    }
                }else{
                    alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                }
            }
            props.setLoading(false);
        }).catch((error)=>{
            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요"+error);
            props.setLoading(false);
        });

    }
    const Request_BatchWords = ()=>{
        let arr = [];
        for(let i=0; i<strings.length; i++){
            if(strings[i]!="")
                arr[i] = strings[i];
        }

        if(arr.length == 0){
            alert("단어를 입력해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        props.setLoading(true);
        let data = {
            service_uid : 1,
            api_code : api_code,
            api_subname : "batchWords",
            'api-version' : api_version,
            data : JSON.stringify(arr)
        };
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);
        axios.post('/rest/webapi', formData).then((response)=>{
            if(response.data.result == 'success'){
                let res = "";
                let i=0;
                let j=i+1;
                let cur_word = strings[i];
                let tar_word = strings[j];
                response.data.data.data.map(function(a, idx){
                    if(j<strings.length){
                        tar_word = strings[j];
                        j++;
                    }else{
                        i++;
                        j=i+1;
                        cur_word = strings[i];
                        tar_word = strings[j];
                        j++;
                    }
                    res += "유사도 비교 : "+cur_word+", "+tar_word;
                    res += "\r\n유사도 : "+a+"\r\n";  
                })
                setResult(res);
            }else{
                if(response.data.data != undefined){
                    if(response.data.data.error == 425||response.data.data.error == 427){
                        alert("크레딧이 모두 소진되었습니다. 크레딧을 구입해 주세요.");
                        window.open('/subscribe');
                    }else{
                        alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                    }
                }else{
                    alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                }
            }
            props.setLoading(false);
        }).catch((error)=>{
            alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요"+error);
            props.setLoading(false);
        });

    }

    const resetConfig = (isBatch)=>{
        if(isBatch)
            setStrings(["", ""]);
        else
            setStrings([["", ""]]);
        setAPIVersion("");
        setResult("");
    }
    
    useEffect(()=>{
        console.log("strings :"+JSON.stringify(strings));
        console.log("strings[0] :"+strings[0]);
        //console.log("strings[0][0] :"+strings[0][0]);
        //console.log("strings[0][1] :"+strings[0][1]);
    }, [strings]);
    

    return(
        <>
            <Breadcrumb className="section-content">
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <Breadcrumb.Item active>자연어처리 AI 웹 API</Breadcrumb.Item>
            </Breadcrumb>
            <section className="sections-white">
                <Container className="section-content">
                    <Tabs onSelect={(key)=>{resetConfig(key=='batchWords')}}>
                        <Tab eventKey="singleWords" title="한 단어쌍"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>자료 입력 및 설명</Form.Label>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong>※ 한 단어쌍 연산 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>한 단어쌍 연산은 입력된 형태소를 추가 분석하여 각 유사도의 평균을 출력합니다. <br/>[예시 : 사과나무, 진공청소기 비교] <br/>사과, 나무, 진공, 청소기의 4단어 개별 비교 후 평균 </span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="mt-3">
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2023-02-12">2023-02-12</option>
                                                    <option value="2023-04-28">2023-04-28</option>
                                                </Form.Select>
                                            </InputGroup>
                                            <InputGroup className="mt-4">
                                                <InputGroup.Text>비교단어1</InputGroup.Text>
                                                <Form.Control type="text" name="string1" value={strings[0][0] || ""} onChange={(e)=>{setStrings(strings.map(function(a, idx){if(idx==0) return [e.target.value, a[1]]; else return a;}))}} />
                                            </InputGroup>
                                            <InputGroup className="mt-4">
                                                <InputGroup.Text>비교단어2</InputGroup.Text>
                                                <Form.Control type="text" name="string2" value={strings[0][1] || ""} onChange={(e)=>{setStrings(strings.map(function(a, idx){if(idx==0) return [a[0], e.target.value]; else return a;}))}}/>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-02-12 : 한국어 위키백과 자료를 학습한 인공지능 모델을 이용합니다. <br/>개념의 정의 등에 대한 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                    <Row>
                                                        <span>2023-04-28 : 한국지능정보사회진흥원의 AI Hub에서 제공하는 자료를 학습한 <br/>인공지능 모델을 이용합니다. 뉴스기사 등에 등장하는 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="float-end mt-4">
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{Request_SingleWords()}}>유사도 연산</Button>
                                            </InputGroup>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>결과 로그</Form.Label>
                                        <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={15} value={result} readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="multiWords" title="여러 단어쌍"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>자료 입력 및 설명</Form.Label>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong>※ 한 단어쌍 연산 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>한 단어쌍 연산은 입력된 형태소를 추가 분석하여 각 유사도의 평균을 출력합니다. <br/>[예시 : 사과나무, 진공청소기 비교] <br/>사과, 나무, 진공, 청소기의 4단어 개별 비교 후 평균 </span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="mt-3">
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2023-02-12">2023-02-12</option>
                                                    <option value="2023-04-28">2023-04-28</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        {
                                            strings.map(function(a, idx_){
                                                return(
                                                    <Row key={idx_}>
                                                        <InputGroup className="mt-4">
                                                            <InputGroup.Text>비교단어1</InputGroup.Text>
                                                            <Form.Control type="text" name="string1" value={strings[idx_][0] || ""} onChange={(e)=>{setStrings(strings.map(function(a, idx){if(idx==idx_) return [e.target.value, a[1]]; else return a;}))}} />
                                                            <InputGroup.Text>비교단어2</InputGroup.Text>
                                                            <Form.Control type="text" name="string2" value={strings[idx_][1] || ""} onChange={(e)=>{setStrings(strings.map(function(a, idx){if(idx==idx_) return [a[0], e.target.value]; else return a;}))}}/>
                                                            <Button variant="secondary" onClick={()=>{addMultiwordsRow()}}>+</Button>
                                                        </InputGroup>
                                                    </Row>
                                                )
                                            })
                                        }
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-02-12 : 한국어 위키백과 자료를 학습한 인공지능 모델을 이용합니다. <br/>개념의 정의 등에 대한 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                    <Row>
                                                        <span>2023-04-28 : 한국지능정보사회진흥원의 AI Hub에서 제공하는 자료를 학습한 <br/>인공지능 모델을 이용합니다. 뉴스기사 등에 등장하는 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="float-end mt-4">
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{Request_MultiWords()}}>유사도 연산</Button>
                                            </InputGroup>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} >
                                    <Form.Group className="h-100">
                                        <Form.Label>결과 로그</Form.Label>
                                        <Form.Control as="textarea" className="h-100" style={{backgroundColor:"white"}} rows={15} value={result} readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="batchWords" title="여러 단어쌍(고속)"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>자료 입력 및 설명</Form.Label>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong>※ 여러 단어쌍(고속) 연산 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>여러 단어쌍(고속) 연산은 같은 단어가 포함되는 단어쌍이 있는 경우,<br/>같은 단어를 여러번 입력하지 않고 입력된 단어 간 모든 유사도를 연산합니다.<br/>[예시 : 사과나무, 진공청소기, 에어컨 비교] <br/>[사과나무, 진공청소기], [사과나무, 에어컨], [진공청소기, 에어컨]의 전체 유사도를 모두 고속 연산</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="mt-3">
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    <option value="" >API버전 선택</option>
                                                    <option value="2023-02-12">2023-02-12</option>
                                                    <option value="2023-04-28">2023-04-28</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        {
                                            strings.map(function(a, idx_){
                                                if(idx_%2 == 1)
                                                    return("");
                                                return(
                                                    <Row key={idx_}>
                                                        <InputGroup className="mt-4">
                                                            <InputGroup.Text>단어입력</InputGroup.Text>
                                                            <Form.Control type="text" name="string1" value={strings[idx_] || ""} onChange={(e)=>{setStrings(strings.map(function(a, idx){if(idx==idx_) return e.target.value; else return a;}))}} />
                                                            <InputGroup.Text>단어입력</InputGroup.Text>
                                                            <Form.Control type="text" name="string2" value={strings[idx_+1] || ""} onChange={(e)=>{setStrings(strings.map(function(a, idx){if(idx==idx_+1) return e.target.value; else return a;}))}}/>
                                                            <Button variant="secondary" onClick={()=>{addBatchwordsRow()}}>+</Button>
                                                        </InputGroup>
                                                    </Row>
                                                )
                                            })
                                        }
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-02-12 : 한국어 위키백과 자료를 학습한 인공지능 모델을 이용합니다. <br/>개념의 정의 등에 대한 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                    <Row>
                                                        <span>2023-04-28 : 한국지능정보사회진흥원의 AI Hub에서 제공하는 자료를 학습한 <br/>인공지능 모델을 이용합니다. 뉴스기사 등에 등장하는 단어 비교에 유리합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="float-end mt-4">
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{Request_BatchWords()}}>유사도 연산</Button>
                                            </InputGroup>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} >
                                    <Form.Group className="h-100">
                                        <Form.Label>결과 로그</Form.Label>
                                        <Form.Control as="textarea" className="h-100" style={{backgroundColor:"white"}} rows={15} value={result} readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>

                    </Tabs>
                </Container>
           </section>
        </>
    );

}
export default WebAPI_NLP;
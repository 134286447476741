import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/login_modal.css';
import { useCookies } from "react-cookie";

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      준비중입니다.. 조금만 기다려 주세요!
    </Tooltip>
  );


function LoginModal(props){
    const navigate = useNavigate();
    const [data_, setData] = useState({id:"", pw:""});
    const [modal_title, setModalTitle] = useState("로그인 실패");
    const [modal_content, setModalContent] = useState("로그인에 실패하였습니다. 입력하신 계정정보를 확인하십시오.");
    const [secondary, setSecondary] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId"]);
    const [isRemember, setIsRemember] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleInputIdPw = e => {
        const  {name, value} = e.target;
        setData({...data_, [name]:value});
    }
	const Login_func = (e) =>{
        const form = e.currentTarget;
		e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }else{
            axios.post('/rest/login',
            {
                id: data_.id,
                pw: data_.pw
            }).then(response => {
                if(response.data.result == 'success'){
                    if(isRemember == true){
                        console.log(isRemember);
                        setCookie("rememberUserId", data_.id, {maxAge: 2000});
                    }else{
                        removeCookie("rememberUserId");
                    }
                    navigate("/");
                    props.setLoginShow(false);
                    props.setIsLogged(true);
                }else{
                    //handleShow(true);
                    //setValidated(false);
                    setIsInvalid(true);
                    e.stopPropagation();
                }
            });
        }
		setValidated(true);
        
	};
    useEffect(() => {
        if (cookies.rememberUserId !== undefined) {
            setData({...data_, id:cookies.rememberUserId});
            setIsRemember(true);
        }
      }, []);
    const handleOnChange = (e) => {
        setIsRemember(e.target.checked);
    };
    return(
        <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className=''
        dialogClassName="modal-25w"
        centered show={props.show} onHide={props.handleClose}>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={Login_func}>
			    	<Container className="title middle text-center">
                        <img className="w-md-50" src={process.env.PUBLIC_URL + '/assets/img/logo_footer.png'} alt="로고"></img>
                    </Container>
			    	<Container className="title middle text-center">로그인</Container>
			        <Container>
			            <Form.Group>
			                <Form.Label>아이디</Form.Label>
   			                <Form.Control required isInvalid={isInvalid} type="text" name="id" placeholder="아이디 입력" value={data_.id} onChange={handleInputIdPw}></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                아이디를 올바르게 입력해주세요.
                            </Form.Control.Feedback>
	                    </Form.Group>
 	 	                <Form.Group className="mt-3">
			    	 	    <Form.Label>비밀번호</Form.Label>
                     		<Form.Control required isInvalid={isInvalid} type="password" name="pw" placeholder="비밀번호" value={data_.pw} onChange={handleInputIdPw}></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                비밀번호를 올바르게 입력해주세요.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Container>
  			    	<Container className="form-check login mt-4 mx-3">
  			    	    <Form.Check type="checkbox" id="saveID" onChange={(e)=>{handleOnChange(e)}} checked={isRemember}></Form.Check>
    		    	    <Form.Check.Label>아이디 저장</Form.Check.Label>
 			    	</Container>
 			    	<Container className="loginbt mt-4 container px-0">
 			    	    <Container className="text-center">
  			    			    <Button type="submit" className="w-100" >로그인</Button>
                                <button type='button' className='mt-3' style={{width:"fit-content", background:"none", border:"none"}} onClick={()=>{window.open("/rest/socialLogin?social=naver", "_blank", 'width=850px,height=850px,location=no,status=no,scrollbars=yes')}}><img style={{height:"45px"}} src={process.env.PUBLIC_URL + '/assets/img/naver_login.png'}/></button><br/>
                                <button type='button' className='mt-3' style={{width:"fit-content", background:"none", border:"none"}} onClick={()=>{window.open("/rest/socialLogin?social=google", "_blank", 'width=850px,height=850px,location=no,status=no,scrollbars=yes')}}><img style={{height:"45px"}} src={process.env.PUBLIC_URL + '/assets/img/google_login.png'}/></button>
  			    	    </Container>
                        <Container className=''>
                            <Container className=" mx-md-0 mx-auto mt-3 text-center">
                                <Form.Check.Label className="me-3 px-0 login_middle" ><Link to="/findid" onClick={()=>{props.setLoginShow(false)}}  className="text-secondary">아이디찾기</Link></Form.Check.Label>
	  	        			    <Form.Check.Label className=" login_middle px-0 ms-1" ><Link to="/findpw" onClick={()=>{props.setLoginShow(false)}} className="text-secondary">비밀번호찾기</Link></Form.Check.Label>
 			    	   		</Container>
                            <Container className=" mx-md-0 mx-auto mt-3 text-center">
                                <Form.Check.Label className="me-3 px-0 login_middle" ><Link to="/register/intro" onClick={()=>{props.show(false)}}  className="text-secondary">회원가입</Link></Form.Check.Label>
 			    	   		</Container>
                        </Container>
  			    	</Container>
			    </Form>
            </Modal.Body>
        </Modal>
    );

}

export default LoginModal;
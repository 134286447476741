import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Modal_alert from "../../modal.js";


function RegisterVendor(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);

    const [btnText, setBtntext] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [delete_show, setDeleteShow] = useState(false);
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const[values, setValues] = useState({uid : 0, vendor_name:"", as_person:"", as_tel:"", as_address:"", homepage:""});

    const handleChange = e => {
        console.log(e.target.name+": "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }

    const handlePublic = () =>{
        setValues({
            ...values,
            ispublic :  !(values.ispublic),
        })
    };

    const handleSubmit_delete = (event) => {
        event.preventDefault();
        setDeleteShow(false);
        deleteVendor();
      };

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }else{
        regist();
      }

      setValidated(true);
      
    };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/VendorList");
    };
    const handleShow = () => setShow(true);

    const getVendor = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/getVendorInfo',
        { params : {uid : location.state.uid}}).then(response =>{
            setValues(response.data.data);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const deleteVendor = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deleteVendor',
        { params : {uid : location.state.uid}}).then(response =>{
            setDeleteCompleteShow(true);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const regist = () =>{
        setLoading(true);
        axios.post('/rest/admin/registerVendor',
        { 
            uid: values.uid,
            vendor_name: values.vendor_name,
            as_person : values.as_person,
            as_tel : values.as_tel,
            as_address : values.as_address,
            homepage : values.homepage
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    if(values.uid > 0){
                        setModalTitle("유통사 수정 성공");
                        setModalContent("유통사수정에 성공하였습니다.");
                    }else{
                        setModalTitle("유통사 등록 성공");
                        setModalContent("유통사등록에 성공하였습니다.");
                    }
                    setRegisterResult(true);
                }else{
                    if(values.uid > 0){
                        setModalTitle("유통사 수정 실패");
                        setModalContent("유통사수정에 실패하였습니다.");
                    }else{
                        setModalTitle("유통사 등록 실패");
                        setModalContent("유통사등록에 실패하였습니다.");
                    }
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(() => {}, []);
    useEffect(() => {
        if(location.state != null && location.state.mode == 'mod'){
            setTitle(()=>{
                return "유통사 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
            getVendor();
        }else{
            setTitle(()=>{
                return "유통사 신규 등록";
            })
            setBtntext(()=>{
                return "등록";
            })
            setValues({uid : 0, vendor_name:"", as_person:"", as_tel:"", as_address:"", homepage:""});
        }
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>유통사명</Form.Label>
                                    <Form.Control required type="text" name="vendor_name" value={values.vendor_name} onChange={handleChange} placeholder="유통사명 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        유통사명을 입력해주세요.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>AS담당자</Form.Label>
                                    <Form.Control required type="text" name="as_person" value={values.as_person} onChange={handleChange} placeholder="AS담당자 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        AS담당자를 입력해주세요.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>연락처</Form.Label>
                                    <Form.Control required type="text" name="as_tel" value={values.as_tel} onChange={handleChange} placeholder="연락처 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        연락처를 입력해주세요.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>주소</Form.Label>
                                    <Form.Control required type="text" name="as_address" value={values.as_address} onChange={handleChange} placeholder="주소 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        주소를 입력해주세요.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>홈페이지</Form.Label>
                                    <Form.Control required type="text" name="homepage" value={values.homepage} onChange={handleChange} placeholder="홈페이지 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        홈페이지를 입력해주세요.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            
                            <Col sm={1}>
                                <Button type="button" className={`w-100 btn btn-secondary ${location.state != null && location.state.mode == 'mod' ? "" : "d-none"}`} onClick={()=>{setDeleteShow(true)}}>삭제</Button>
                            </Col>
                            <Col sm={1}>
                                <Button type="submit" className="w-100">{btnText}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                {location.state != null && location.state.mode == 'mod' ? 
                <><Modal_alert show={delete_show} handleClose={()=>{setDeleteShow(false)}} handleSubmit={handleSubmit_delete} modal_title="" content="정말 유통사를 삭제하시겠습니까?" secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={delete_complete_show} handleClose={()=>{setDeleteCompleteShow(false); navigate("/admin/VendorList"); }} modal_title="삭제완료" content="유통사가 삭제되었습니다." primary_btn={primary_btn}/></>
                :
                ""
                }
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
            </div>
        </>
        );

}

export default RegisterVendor;
import React from "react";


function Agreement(){
    return(
    <>
        <div className="sections mt-5 pt-5">
            <section className="section-content card mt-4 mb-4 subcribe-price-section">
                <div className="policy-header mb-4">
                    <h4><strong>이용약관</strong></h4>
                    <h6></h6>
                </div>
                <div className="policy-body">
                <iframe src="https://file.namisnt.com/pdfjs/web/viewer.html?file=https://file.namisnt.com/docu/나미에스엔티_AI_API_이용약관_230607.pdf" style={{width:"100%", height:"1000px"}}></iframe>
                </div>
            </section>
        </div>
    </>
    );

}

export default Agreement;
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Container, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import React from "react";
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/login_modal.css';
import { useCookies } from "react-cookie";

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      준비중입니다.. 조금만 기다려 주세요!
    </Tooltip>
  );


function SearchModal(props){
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [data, setData] = useState({model:[], products:[]});
    const [modal_title, setModalTitle] = useState("로그인 실패");
    const [modal_content, setModalContent] = useState("로그인에 실패하였습니다. 입력하신 계정정보를 확인하십시오.");
    const [secondary, setSecondary] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId"]);
    const [isRemember, setIsRemember] = useState(false);

    const handleChange = e => {
        setQuery(e.target.value);
    }
    const concatCategory = (list)=>{
        let str = "";
        for(var i=0; i<list.length; i++){
            str += list[i].category_name;
            if(i+1 < list.length)
                str += " > ";
        }
        return str;
    }

    const requestList = () =>{
        axios.get('/rest/searchProduct', 
            {params:
            {
                page: 0,
                page_limit : 5,
                query : query
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                //let totalSize = response.data.count;
                //let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setData(list => response.data.data);
                //setTotalSize(totalSize);
                //setTotalPage(totalPage_);
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
	
    useEffect(()=>{
        if(query.length > 0)
            requestList();
    }, [query])
    useEffect(() => {
        setQuery("");
    }, [props.show]);
    useEffect(() => {
      }, []);
    return(
        <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className=''
        dialogClassName="modal-50w"
        centered show={props.show} onHide={props.handleClose}>
            <Modal.Body>
			    <Container className="title middle text-center">검색</Container>
			    <Container>
			        <Form.Group className='mb-3'>
   			            <Form.Control type="text" name="query" placeholder="모델명 혹은 제품시리얼 입력" value={query} onChange={handleChange}></Form.Control>
	                </Form.Group>
                    {
                        query.length == 0 || (data.model.length == 0 && data.products.length == 0)? 
                        <>
                            <Row className='p-3'>
                                <div className='fs-5'>1. 모델명과 제품시리얼은 어디에 있나요?</div>
                                <div className='fs-6 ps-4'>
                                    <div className='fs-6'>① 나미표준PC/나미커스텀PC</div>
                                    <div className='ps-4'>나미표준PC/나미커스텀PC는 본체 측면에 보증라벨이 부착되어 있습니다.</div>
                                    <div className='ps-4'>나미커스텀PC의 경우 모델명이 동일하므로(NMPC-CUSTOM, NP-CUSTOM),</div>
                                    <div className='ps-4'>제품 바코드 아래에 있는 제품시리얼을 입력하셔야 합니다.</div>
                                </div>
                            </Row>
                            <Row className='p-3'>
                                <div className='fs-5'>2. 제품시리얼이 제대로 보이지 않으면 어떡하나요?</div>
                                <div className='fs-6 ps-4'>
                                    <div className='ps-4'>제조과정에서 제품시리얼이 잘린경우, 시리얼 바코드가 훼손되지 않았다면</div>
                                    <div className='ps-4'>바코드 스캐너 어플 등을 이용하여 확인할 수 있습니다.</div>
                                    <div className='ps-4'>보증라벨 사진과 함께 고객센터로 문의주시면 제품시리얼을 알려드립니다.</div>
                                </div>
                            </Row>
                            <Row className='p-3'>
                                <div className='fs-5'>3. 나미에스엔티에서 공급한 노트북은 어떡하나요?</div>
                                <div className='fs-6 ps-4'>
                                    <div className='ps-4'>자사에서 공급한 노트북 등 타사모델은</div>
                                    <div className='ps-4'>고객센터로 문의주시면 친절히 상담해드리겠습니다.</div>
                                </div>
                            </Row>
                        </>
                        :
                        <>
                            <Container>
                                <div className='fs-4 fw-bold'>모델</div>
                                <div className='p-3'>
                                    {data.model.map(function(a,i){     
                                        return(                           
                                            <Row key={i} className='border-bottom py-2' style={{cursor:"pointer"}} onClick={(e)=>{navigate("/cs/modelinfo/"+a.uid); props.setSearchShow(false);}}>
                                                <Col xs={6} lg={2}>
                                                    <img className="w-100 pe-3" src={"/rest/downloadFile?filename="+a.img_loc}/>
                                                </Col>
                                                <Col>
                                                    <Row className='fw-bold'>
                                                        {a.model_name}
                                                    </Row>
                                                    <Row className='text-secondary'>
                                                        {concatCategory(a.categories)}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </Container>
                            <Container>
                                <div className='fs-4 fw-bold'>제품</div>
                                <div className='p-3'>
                                    {data.products.map(function(a,i){                               
                                        return ( 
                                            <Row key={i} className='border-bottom py-2'  style={{cursor:"pointer"}} onClick={(e)=>{navigate("/cs/modelinfo/"+a.uid); props.setSearchShow(false);}}>
                                                <Col xs={6} lg={2}>
                                                    <img className="w-100" src={"/rest/downloadFile?filename="+a.img_loc}/>
                                                </Col>
                                                <Col>
                                                    {a.model_name}
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </Container>
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    );

}

export default SearchModal;
import React from "react";
import { Tab, Tabs } from "react-bootstrap";


function Agreements(){
    return(
        <>
            <section className="section-content mb-4 subcribe-price-section">
                <div className="policy-header mb-4">
                    <h4><strong>이용약관</strong></h4>
                    <h6></h6>
                </div>
                <Tabs defaultActiveKey="hosting">
                    <Tab eventKey="hosting" title="웹호스팅">
                        <div className="policy-body">
                            <iframe src="https://file.namisnt.com/pdfjs/web/viewer.html?file=https://file.namisnt.com/docu/나미에스엔티_호스팅_이용약관_210422.pdf" style={{width:"100%", height:"1000px"}}></iframe>
                        </div>
                    </Tab>
                </Tabs>
            </section>
        </>
        );

}

export default Agreements;
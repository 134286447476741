import React from "react";
import { Form, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { useEffect } from "react";

function Modal_Password_Reset_alert(props){

  const [pw_check, setPWcheck] = useState(true);
  const [pw_cfm_check, setPWCFMcheck] = useState(true);
  const [pw_valid, setPWValid] = useState(false);
  const [pw_cfm_valid, setPWCFMValid] = useState(false);

  const handlePWChange = e => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
    if(e.target.value == "" || !passwordRegex.test(e.target.value) ){
        setPWcheck(false);
        setPWValid(false);
    }else{
        setPWcheck(true);
        setPWValid(true);
    }
    props.setSecretPw(e.target.value)
}
const handlePWCfmChange = e => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
    if(e.target.value == "" || !passwordRegex.test(e.target.value)  || props.pw !== e.target.value){
        setPWCFMcheck(false);
        setPWCFMValid(false);
    }else{
        setPWCFMcheck(true);
        setPWCFMValid(true);
    }
    props.setSecretPwCfm(e.target.value)
}
    return (
        <>
            <Modal
              show={props.show}
              onHide={props.handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{props.modal_title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {props.content}
                <Row className="mt-3">
                  <Form.Group>
                      <Form.Label>비밀번호</Form.Label>
                      <Form.Control isValid={pw_valid} isInvalid={!pw_check} required type="password" name="pw" value={props.pw} onChange={handlePWChange} placeholder="사용하실 비밀번호를 입력해주세요."/>
                      <Form.Control.Feedback type="invalid">
                          8자이상, 특수문자가 포함된 비밀번호를 입력해주세요.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="valid">좋은 비밀번호입니다 :)</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group>
                      <Form.Label>비밀번호확인</Form.Label>
                      <Form.Control isValid={pw_cfm_valid} isInvalid={!pw_cfm_check} required type="password" name="pw_cfm" value={props.pw_cfm} onChange={handlePWCfmChange} placeholder="비밀번호를 다시 입력해주세요."/>
                      <Form.Control.Feedback type="invalid">
                          비밀번호와 확인이 일치하지 않습니다.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="valid">두 비밀번호가 모두 일치합니다 :D</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer>
              {props.secondary && <Button variant="secondary" onClick={()=>{setPWCFMcheck(true); setPWcheck(true); setPWValid(false); setPWCFMValid(false); props.handleClose();}}>
                  닫기
                </Button>}
                <Button variant="primary" onClick={!(props.secondary && props.handleSubmit) ? props.handleClose : ()=>{setPWCFMcheck(true); setPWcheck(true); setPWValid(false); setPWCFMValid(false); props.handleClose();props.handleSubmit();}} >{props.primary_btn}</Button>
              </Modal.Footer>
            </Modal>
        </>
      );

}

export default Modal_Password_Reset_alert;
import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Admin_header from "../../common/admin/header";
import Admin_Sidebar from "../../common/admin/sidebar";
import Admin_dashboard_content from "../../common/admin/dashboard/dashboard_content";
import axios from "axios";


function Dashboard(props){
    const navigate = useNavigate();
    const [UserName, setUserName] = useState("");
    const [toggle_active, setToggle] = useState(false);
    const handleSidebarToggle = ()=>{
		setToggle(!toggle_active);
	}
    useEffect(() => {
        axios.get('/sso/rest/admin/getUserInfo').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setUserName(response.data.name);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
      },[]);
    const Logout_func = (e) =>{
		e.preventDefault();
		axios.get('/sso/rest/admin/logout').then(response => {
            if(response.data.result === 'success'){
                navigate("/admin/login");
            }
        });
	};
    return(
    <div>
        <Admin_header toggle_={ toggle_active} handleSidebarToggle={handleSidebarToggle} />
        <Admin_dashboard_content menu={props.menu} />
        <Admin_Sidebar toggle_={toggle_active} UserName={UserName} Logout_func={Logout_func} handleSidebarToggle={handleSidebarToggle} />
    </div>
    );

}

export default Dashboard;
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header.js';
import Footer from '../common/footer.js';
import Banner from "../common/banner.js"
import '../css/banner.css';
import SubcribePrice from "./elements/subcribe/subcribe_price.js";


function Subscribe(){

    return (
        <div className="App">
                <div className="banner-slider">
                    <Banner />
                </div>
                <div className="sections">
                    <SubcribePrice />
                </div>
        </div>

    );

}

export default Subscribe;
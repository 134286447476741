import React from "react";
import axios from "axios";
import { useLocation, useNavigate, Link} from "react-router-dom";
import Loading from "../../common/Loading.js";
import ListSearch from "../../common/listSearch.js";
import PageSelector from "../../common/page_selector.js";
import { useState, useEffect } from "react";
import Status_view from "./modules/status_view.js";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row } from "react-bootstrap";
import Modal_Password_alert from "../../common/modal_password.js";
import Modal_alert from "../../common/modal.js";
import Modal_Coupon_alert from "../../common/modal_coupon.js";

function GenuineList(){
    const navigate = useNavigate();
    const [title] = useState("보유기기현황");
    const [loading, setLoading] = useState(false);
    const [genuineList, setGenuineList] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const [searchFilter, setSearchFilter] = useState([{val:"serialnumber", name:"제품시리얼"}, {val:"model_name", name:"모델명"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");

    const [coupon_code, setCouponCode] = useState("");
    const [couponinput_show, setCouponInputShow] = useState(false);
    const [coupon_fail_show, setCouponFailShow] = useState(false);
    const [coupon_succ_show, setCouponSuccShow] = useState(false);
    const [coupon_fail_msg, setCouponFailMSG] = useState("");

    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");

    const PageHandler = (page)=>{
        setPage(page);
        //requestList(search_kind, query,page);
    }

    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
            setMyinfoPW("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const requestList = (q_type, q) =>{
        setLoading(true);
        axios.get('/rest/service/getGenuineList', 
            {params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/mypage");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setGenuineList(list => response.data.list);
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/mypage");
                console.log(error);
            }
        );
    }
    useEffect(()=>{}, [search_kind, query]);

    //
    useEffect(() => {
        requestList(search_kind,query,);
      },[page]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <section className="section-content" style={{paddingBottom:"0"}}>
        <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <BreadcrumbItem active>보유기기현황</BreadcrumbItem>
            </Breadcrumb>
        </section>
        <div className={`card-shadow content sections-white`}  style={{marginTop:"1rem"}} >
            <div>
                <h5><strong>{title}</strong></h5>
            </div>
            <Row>
                <Col className="py-3 ps-3">
                    <Button variant="secondary" onClick={()=>{navigate("/cs/genuine")}}>정품등록</Button>
                </Col>
                <Col>
                    <ListSearch goSearch={requestList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
                </Col>
            </Row>
            <div>
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div style={{overflowX:'Auto'}}>
                {
                        genuineList.length > 0 ?
                        genuineList.map(function(a, idx){
                                return(
                                    <Card key={idx} className='border border-white'>
                                        <Card.Body>
                                            <Row className='px-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Row >
                                                            <Col lg={1}>
                                                                <img className="w-100" src={"/rest/downloadFile?filename="+a.img_loc}/>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <h6 className='fw-bold'>{a.model_name}</h6>
                                                                <h6>S/N : {a.serialnumber}</h6>
                                                                <h6>등록일자 : {a.created_date}</h6>
                                                            </Col>
                                                            <Col className='text-end'>

                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                );
                            })
                        :
                        <>
                            <h6 className='text-center pb-4 m-0'>보유기기가 없습니다.</h6>
                        </>
                    }
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
            <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); setMyinfoPW("");}} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>
        </>

    );

}

export default GenuineList;
import 'bootstrap/dist/css/bootstrap.css'
import React from "react";

const Dropdown = props => {
    return (
        <article className={`${props.visibility ? 'dropdown-fade-in' : 'dropdown-fade-out'}`}>
            {props.visibility && props.children}
        </article>
    )
};

export default Dropdown;
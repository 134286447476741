import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../common/Loading";
import Status_view from "./modules/status_view";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Breadcrumb, Container } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Modal_alert from "../../common/modal";
import Modal_Password_alert from "../../common/modal_password";
import Modal_Request_Receipt from "../../common/modal_request_receipt";

function Order_view(){
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");

    const [receipt_type_pair] = useState([{type:"cashReceipt", name:"현금영수증"},{type:"tax", name:"세금계산서"}]);
    const [check_type, setCheckType] = useState(0); //0 : 현금영수증, 1 : 세금계산서
    const [cashReceiptType, setCashReceiptType] = useState(false); //false : 개인, true : 사업자
    const [cashReceiptCode, setCashReceiptCode] = useState("");
    const [ReceiptShow, setReceiptShow] = useState(false);
    const [receipt_requested, setReceiptRequested] = useState(false);
    const [receipt_failed_show, setReceiptFailedShow] = useState(false);

    const [order_cancel_show, setOrderCancelShow] = useState(false);
    const [order_canceled_show, setOrderCanceledShow] = useState(false);
    const [order_cancel_failed_show, setOrderCancelFailedShow] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");

    const [payAmount, setPayAmount] = useState(0);

    const [totalpayed, setTotalPayed] = useState(0);

    const[values, setValues] = useState({order_id :"",ordered_date : "", step:10, total_fee: 0,payment_method:"", depositor:"", items:[], payments:[]});

    const [step] = useState({10: "주문완료", 15:"결제대기",20:"결제완료", 25:"크레딧지급대기", 30:"크레딧지급완료", 80:"결제취소", 90:"주문취소"});
    const [method] = useState({cash: "현금/무통장", creditcard:"신용/체크카드", coupon:"쿠폰결제", virtual_account:"가상계좌"});

    const [receipt_type] = useState({cashReceipt : "현금영수증", tax:"세금계산서"});

    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const receiptSubmit = ()=>{
        setLoading(true);
        if(check_type == 1)
            return;
        axios.post('/rest/requestCashReceipt',{
                order_id : location.state.order_id,
                cashreceiptType : cashReceiptType ? "business" : "person",
                code : cashReceiptCode
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setReceiptShow(false);
                if(response.data.result == 'success'){ 
                    alert("영수증 발급 요청이 완료되었습니다 :)");
                    getOrder();
                }else{
                    setReceiptFailedShow(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const cancelOrder = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/cancelOrder',
        { params : {order_id : location.state.order_id}}).then(response =>{
            if(response.data.result == 'success'){
                setOrderCancelShow(false);
                setOrderCanceledShow(true);
            }else{
                setOrderCancelFailedShow(true);
            }
        }).catch(
            error=>{
                setOrderCancelFailedShow(true);
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }

    const getOrder = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/getOrder',
        { params : {order_id : location.state.order_id}}).then(response =>{
            let total_payed = 0;
            let receipt_completed = false;
            let cash = false;
            setValues({order_id : response.data.data.order_id, id : response.data.data.id, name:response.data.data.name, ordered_date : response.data.data.ordered_date, step:response.data.data.step, total_fee: response.data.data.total_fee,payment_method:response.data.data.payment_method, depositor:response.data.data.depositor, items:response.data.data.orderitem, payments:response.data.data.payments});
            response.data.data.payments.map(function(item, idx){
                total_payed += item.payment_amount;
                console.log(item.method);
                if(item.method == "cash")
                    cash=true;
                if(item.receipt != '{}'){
                    var receipt_json = JSON.parse(item.receipt);
                    if(receipt_json.status == "completed" || receipt_json.status=="requested"){
                        setReceiptRequested(true);
                    }
                }
            });
            if(cash ==false)
                setReceiptRequested(true);
            setTotalPayed(total_payed);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    useEffect(()=>{getOrder()}, []);
    useEffect(()=>{}, [ReceiptShow]);

    return(
        <>
            {loading == true ? <Loading/> : ""}
            <section className="section-content" style={{paddingBottom:"0"}}>
            <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
                <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item href="/mypage/dashboard">
                        마이페이지
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        주문서 상세 조회
                    </Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <section className="sections-white mt-1 pb-3">
                <Form>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>주문단계</Form.Label>
                                    <br/>
                                    <strong>{step[values.step]}</strong>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>주문번호</Form.Label>
                                    <Form.Control required type="text" name="order_id" defaultValue={values.order_id} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>주문일시</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.ordered_date} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>결제수단</Form.Label>
                                    <Form.Control  type="text" defaultValue={method[values.payment_method]} readOnly/>
                                </Form.Group>
                            </Col>
                            {values.payment_method == "cash" ?
                                <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>입금자명</Form.Label>
                                        <Form.Control  type="text" defaultValue={values.depositor} readOnly/>
                                    </Form.Group>
                                </Col>
                                :
                                ""
                            }
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label>주문내역</Form.Label>
                                    <Container className="border p-3" style={{overflowX:'Auto'}}>
                                        <Table striped bordered hover size="sm" style={{minWidth:'1000px'}} >
                                            <thead>
                                                <tr>
                                                    <th>플랜이름</th>
                                                    <th>서비스유형</th>
                                                    <th>지급 크레딧</th>
                                                    <th>주문수량</th>
                                                    <th>주문금액</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {values.items.map(function(item, idx){
                                                return(<tr key={idx} className="border-bottom lh-lg">
                                                    <td>
                                                        {item.plan_name}
                                                    </td>
                                                    <td>
                                                        {item.service_name}
                                                    </td>
                                                    <td>
                                                        {item.credit_size}개
                                                    </td>
                                                    <td className="col-lg-1">
                                                            <strong>{item.ea}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{item.fee}원</strong>
                                                    </td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </Table>
                                    </Container>
                                </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>총 주문요금</Form.Label>
                                    <Form.Control required type="number" name="fee" value={values.total_fee} readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group>
                                    <Container className="d-flex justify-content-between mb-3">
                                        <Form.Label>결제내역</Form.Label>
                                        <div>
                                            {values.step >= 20 && values.step != 90 && receipt_requested == false ? <Button className="me-3" variant="secondary" size="sm" onClick={()=>{setReceiptShow(true)}}>현금영수증/세금계산서 발행 요청</Button> : ""}
                                            {values.step >= 20 && values.step != 90 ? <Button variant="secondary" size="sm" onClick={()=>{window.open("getReceipt/"+values.order_id, "_blank", 'width=850px,height=1440px,location=no,status=no,scrollbars=yes')}}>결제 영수증 출력</Button> : ""}
                                        </div>
                                    </Container>
                                    <Container className="border p-3">
                                        <Table striped responsive bordered hover size="sm" style={{minWidth:'1000px'}} >
                                            <thead>
                                                <tr>
                                                    <th>결제/승인번호</th>
                                                    <th>결제수단</th>
                                                    <th>결제금액</th>
                                                    <th>은행/카드사</th>
                                                    <th>결제일시</th>
                                                    <th style={{width:"200px"}}>영수증조회</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {values.payments.map(function(item, idx){
                                                return(<tr key={idx} className="border-bottom lh-lg">
                                                    <td>
                                                        {item.payment_code}
                                                    </td>
                                                    <td>
                                                        {method[item.method]}
                                                    </td>
                                                    <td>
                                                        <strong>{item.payment_amount}</strong>
                                                    </td>
                                                    <td>
                                                        {item.bank}
                                                    </td>
                                                    <td>
                                                        {item.created_date}
                                                    </td>
                                                    <td>
                                                        {item.receipt != '' && item.receipt != undefined ?
                                                            JSON.parse(item.receipt).status == "requested" ?
                                                                <span>{receipt_type[JSON.parse(item.receipt).type]} 발행요청완료</span>
                                                                :
                                                                JSON.parse(item.receipt).status == "completed" ?
                                                                <span>{receipt_type[JSON.parse(item.receipt).type]}<br/>승인번호 : {JSON.parse(item.receipt).code}</span>
                                                                :
                                                                ""
                                                            :
                                                            ""
                                                        }
                                                    </td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </Table>
                                    </Container>
                                </Form.Group>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>잔여 결제요금</Form.Label>
                                    <br/>
                                    <strong>{values.total_fee - totalpayed}</strong>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            {values.step < 15 ? 
                                <>
                                    <Col className="text-end">
                                        <Button type="button" className={`btn btn-danger`} onClick={()=>{setOrderCancelShow(true)}}>주문취소</Button>
                                    </Col>
                                </>
                                :
                                ""
                            }
                        </Row>
                    </Container>
                </Form>
            </section>
            <Modal_alert show={order_cancel_show} handleClose={()=>{setOrderCancelShow(false);}} handleSubmit={()=>{cancelOrder()}}  modal_title="주문취소" content="주문을 취소하시겠습니까?"  secondary={true} primary_btn={primary_btn} />
            <Modal_alert show={order_canceled_show} handleClose={()=>{setOrderCanceledShow(false);getOrder();}} modal_title="주문취소완료" content="주문이 취소되었습니다." primary_btn={primary_btn} />
            <Modal_alert show={order_cancel_failed_show} handleClose={()=>{setOrderCancelFailedShow(false);}} modal_title="주문취소실패" content="주문취소에 실패하였습니다." primary_btn={primary_btn} />
            <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
            <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); }} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>
            <Modal_alert show={receipt_failed_show} handleClose={()=>{setReceiptFailedShow(false); }} modal_title="영수증 발행 요청 실패" content="영수증 발행요청에 실패했습니다. 고객센터에 문의해주세요." primary_btn="확인"/>
            <Modal_Request_Receipt show={ReceiptShow} receipt_type={receipt_type_pair} cashReceiptCode={cashReceiptCode} cashReceiptType={cashReceiptType} check_type={check_type} setCheckType={setCheckType} setCashReceiptCode={setCashReceiptCode} setCashReceiptType={setCashReceiptType} secondary="닫기" handleClose={()=>{setReceiptShow(false);}} handleSubmit={()=>{receiptSubmit()}} modal_title="현금영수증/세금계산서 발행요청" content="현금영수증/세금계산서 발행을 위해 아래 정보를 입력해주세요."  primary_btn="요청"></Modal_Request_Receipt>
        </>
    );
}

export default Order_view;
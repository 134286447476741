import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Agreements from "./agreements";
import Warrenty from "./warrenty";
import Cost from "./cost";
import Dcenter from "./dcenter";
import Genuine from "./genuine";
function CustomerService(props){
    const [page, setPage] = useState(props.page);
    useEffect(()=>{
        
    }, [page]);
    useEffect(()=>{
        setPage(props.page);
    }, [props]);
    return(
        <>
            <div className="banner position-relative">
                <img style={{opacity:"0.3", maxHeight:"initial", overflow:"hidden"}} className="w-100" src="/assets/img/intro.jpg"/>
                <div className="position-absolute m-auto text-white w-100 banner-text">
                    <h1 className="text-center">고객님의 생각을 생각합니다.</h1>
                </div>
                <section className="px-lg-5 px-lg-0 px-3 position-absolute w-100 section" style={{paddingBottom:"0", bottom:"0"}}>
                    <Breadcrumb className='mt-3 px-5 section-content'>
                        <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {page == 'warrenty' ? '보증규정' : page=="agreement" ? "이용약관" : page=="cost" ? "점검/수리비 안내" : page=="genuine" ? "정품등록" : page=="download" ? "다운로드센터" : ""}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </section>
            </div>
            
            <div className="pt-0 sections">
                <section className="sections-white mt-0 p-0 p-lg-4">
                    <div className="section-content">
                        {
                            <>
                                { page == "warrenty"?
                                    <Warrenty/>
                                    :
                                  page == "agreement" ?
                                    <Agreements/>
                                    :
                                  page == "cost" ?
                                    <Cost/>
                                    :
                                  page == "genuine" ?
                                    <Genuine/>
                                    :
                                  page == "download" ? 
                                    <Dcenter/>
                                    :
                                    ""
                                }
                            </>
                        }
                    </div>
                </section>
            </div>
        </>
    );
}

export default CustomerService;
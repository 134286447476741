import React from "react";
import UserList from "./userList";
import CreditList from "./modelList";
import RegisterPayplan from "./registerPayplan";
import RegisterCoupon from "./registerCoupon";
import ModifyCoupon from "./modifyCoupon";
import VisitorLog from "./visitorLog";
import ModelList from "./modelList";
import ProductList from "./productList";
import VendorList from "./vendorList";
import EquipmentList from "./equipmentList";
import SoftwareOSList from "./softwareOSList";
import SoftwareCategoryList from "./softwareCategoryList";
import GenuineList from "./genuineList";
import QnaList from "./qnaList";
import ModelCategoryList from "./modelCategoryList";
import ASList from "./asList";
import RegisterEquipment from "./registerEquipment";
import RegisterVendor from "./registerVendor";
import RegisterModel from "./registerModel";
import RegisterProduct from "./registerProduct";
import RegisterQna from "./registerQna";
import ArticleList from "./articleList";
import RegisterArticle from "./registerArticle";

function Admin_dashboard_content(props){
    if(props.menu == "userlist"){
        return (
            <UserList/>
        );
    }else if(props.menu == 'ArticleList'){
        return(
            <ArticleList/>
        )
    }else if(props.menu == 'registerArticle'){
        return(
            <RegisterArticle/>
        )
    }else if(props.menu == 'ModelList'){
        return(
            <ModelList/>
        );
    }else if(props.menu == 'ModelCategoryList'){
        return(
            <ModelCategoryList/>
        )
    }else if(props.menu == 'ProductList'){
        return(
            <ProductList/>
        );
    }else if(props.menu == "creditlist"){
        return(
            <CreditList/>
        );
    }else if(props.menu == "VendorList"){
        return(
            <VendorList/>
        );
    }else if(props.menu == "RegisterVendor"){
        return(
            <RegisterVendor/>
        )
    }else if(props.menu == "EquipmentList"){
        return(
            <EquipmentList/>
        );
    }else if(props.menu == "SoftwareCategoryList"){
        return(
            <SoftwareCategoryList/>
        );
    }else if(props.menu == "GenuineList"){
        return(
            <GenuineList/>
        );
    }else if(props.menu == "registerpayplan"){
        return(
            <RegisterPayplan/>
        );
    }else if(props.menu == 'registerModel'){
        return(
            <RegisterModel/>
        );
    }else if(props.menu == 'registerProduct'){
        return(
            <RegisterProduct/>
        );
    }else if(props.menu == 'SoftwareOSList'){
        return(
            <SoftwareOSList/>
        );
    }else if(props.menu === 'registercoupon'){
        return(
            <RegisterCoupon/>
        );
    }else if(props.menu == 'modifycoupon'){
        return(
            <ModifyCoupon/>
        );
    }else if(props.menu == 'RegisterEquipment'){
        return(
            <RegisterEquipment/>
        );
    }else if (props.menu == 'visitorlog'){
        return(
            <VisitorLog/>
        );
    }else if(props.menu == 'QnaList'){
        return(
            <QnaList/>
        );
    }else if(props.menu == 'registerQna'){
        return(
            <RegisterQna/>
        );
    }else if(props.menu == 'ASList'){
        return(
            <ASList/>
        )
    }else{
        return (
            <>404 error : page not found</>
        );
    }


}

export default Admin_dashboard_content;
import React, { useEffect, useState } from "react";
import Banner from "../../common/banner";
import { Breadcrumb } from "react-bootstrap";
import Board_General from "./Board_General";
import Board_view from "./Board_view";
import { useParams } from "react-router-dom";
function Board(props){
    let params = useParams();
    const [page, setPage] = useState(props.page);
    useEffect(()=>{
        
    }, [page]);
    useEffect(()=>{setPage(props.page)},[props])
    return(
        <>
            <div className="banner position-relative">
                <img style={{opacity:"0.3", maxHeight:"initial", overflow:"hidden"}} className="w-100" src="/assets/img/intro.jpg"/>
                <div className="position-absolute m-auto text-white w-100 banner-text">
                    <h1 className="text-center">고객님의 생각을 생각합니다.</h1>
                </div>
                <section className="px-lg-5 px-lg-0 px-3 position-absolute w-100 section" style={{paddingBottom:"0", bottom:"0"}}>
                    <Breadcrumb className='mt-3 px-5 section-content'>
                        <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {page == 'news' ? '최신소식' : page=="promotion" ? "홍보자료" : page=="articles" ? "관련기사" : ""}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </section>
            </div>
            
            <div className="pt-0 sections">
                <section className="sections-white mt-0 p-0 p-lg-4 pt-4">
                    <div className="section-content">
                        {
                            props.type == "list" ? 
                                <>
                                { page == "news" || page =="promotion" || page=="articles" ?
                                    <Board_General board_code={page}/>
                                    :
                                    ""
                                }
                                </>
                            :
                            props.type == "view" ? 
                                <Board_view uid={params.uid} setPage={setPage}/>
                                :
                                ""
                        }
                    </div>
                </section>
            </div>
        </>
    );
}

export default Board;
import React from "react";
import { useState, useEffect } from "react";
import {Row, Col, Container, ListGroup, Card, Table, Navbar, Nav } from "react-bootstrap";
import Loading from "../../common/Loading";
import ListSearch from "../../common/listSearch";
import PageSelector from "../../common/page_selector";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Board_General(props){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const [searchFilter, setSearchFilter] = useState([{val:"title", name:"제목"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");


    const PageHandler = (page)=>{
        setPage(page);
        //requestList(search_kind, query, page);
    }

    const requestList = (q_type, q) =>{
        setLoading(true);
        axios.get('/rest/getBoardArticleList', 
            {params:
            {
                board_code: props.board_code,
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setListData(list => response.data.list);
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    useEffect(()=>{}, [search_kind, query]);
    //
    useEffect(()=>{setQuery(""); requestList(searchFilter[0].val,"");}, [props.board_code]);
    useEffect(() => {
        requestList(search_kind,query);
      },[page]);
    

    return(
        <>
        <div className="px-lg-5 mx-lg-5 pb-5">
            {loading == true ? <Loading/> : ""}
            <ListSearch goSearch={requestList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
            <div className="mb-3">
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div>
                <Table hover size="sm" >
                    <thead>
                        <tr className="d-none d-lg-table-row">
                            <th className="bg-light fw-medium text-center py-3" style={{width:"70px"}}>번호</th>
                            <th className="bg-light fw-medium text-center py-3">제목</th>
                            <th className="bg-light fw-medium text-center py-3"style={{width:"130px"}}>등록일</th>
                        </tr>
                    </thead>
                    {
                        
                        listData != null && listData != undefined && listData.length > 0 ? 
                        <tbody>
                            {listData.map(function(a, idx){
                                return (
                                    <tr key={idx}>
                                        <td className="text-center py-3 d-none d-lg-table-cell">{idx+1}</td>
                                        <td className="text-center py-3 d-none d-lg-table-cell"><Link to={"/articleView/"+a.article_info.uid}>{a.article_info.title}</Link></td>
                                        <td className="text-center py-3 d-none d-lg-table-cell">{a.article_info.created_date}</td>
                                        <td className="d-table-cell d-lg-none" colSpan={3}>
                                            <div className="text-secondary fw-medium">
                                                {idx+1} {a.article_info.created_date}
                                            </div>
                                            <div className="brd_item">
                                                <Link to={"/articleView/"+a.article_info.uid}>{a.article_info.title}</Link>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        :
                        ""
                    }
                    
                </Table>
                {
                        
                        listData != null && listData != undefined && listData.length > 0 ? 
                        ""
                        :
                        <div className="text-center my-3">게시글이 없습니다.</div>
                    }
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        </>
    );

}

export default Board_General;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../common/Loading";

function Genuine(){
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({model:[], products:[]});
    const [values, setValues] = useState({model_uid :0, model_name:"", serialnumber:"", man_year:2024, man_month:1})

    const handleChange = e => {
        setQuery(e.target.value);
    }
    const concatCategory = (list)=>{
        let str = "";
        for(var i=0; i<list.length; i++){
            str += list[i].category_name;
            if(i+1 < list.length)
                str += " > ";
        }
        return str;
    }

    const requestList = () =>{
        axios.get('/rest/searchProduct', 
            {params:
            {
                page: 0,
                page_limit : 5,
                query : query
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                //let totalSize = response.data.count;
                //let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setData(list => response.data.data);
                //setTotalSize(totalSize);
                //setTotalPage(totalPage_);
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
	const registerGenuine = ()=>{
        setLoading(true);
        axios.post('/rest/service/registerGenuine',values).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == 'success'){ 
                    alert("정품등록이 완료되었습니다 :)");
                    navigate("/mypage/dashboard")
                }else{
                    alert("정품등록에 실패하였습니다. 모델명과 제품시리얼, 제조년월이 정확한지 확인해주세요.");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    useEffect(()=>{
        if(query.length > 0)
            requestList();
    }, [query])
    return(
    <>
        {loading == true ? <Loading/> : ""}
        <section className="section-content mb-4 subcribe-price-section">
            <div className="policy-header mb-4">
                <h4><strong>정품등록</strong></h4>
                <h6></h6>
            </div>
            <div className="policy-body">
                <div className="h3 mb-3 fw-bold text-center">등록할 제품의 모델명과 제품시리얼, 제조년월을 입력해주세요.</div>
                <Form.Group className='mb-3'>
   			            <Form.Control type="text" className=" w-100 w-md-60 m-auto" name="query" placeholder="모델 검색" value={query} onChange={handleChange}></Form.Control>
	            </Form.Group>
                <Form.Group className='mb-3'>
   			            <Form.Control type="text" className=" w-100 w-md-60 m-auto" placeholder="모델을 선택해주세요." defaultValue={values.model_name} readOnly></Form.Control>
	            </Form.Group>
                <Form.Group className='mb-3'>
   			            <Form.Control type="text" className=" w-100 w-md-60 m-auto"  placeholder="제품시리얼 입력" value={values.serialnumber} onChange={(e)=>{setValues({...values, serialnumber:e.target.value});}}></Form.Control>
	            </Form.Group>
                <Form.Group className='mb-3 w-100 w-md-60 m-auto'>
                    <Row>
                        <Col>
                            <Form.Control type="number" className=""  placeholder="제조년도 입력" value={values.man_year} onChange={(e)=>{setValues({...values, man_year:Number(e.target.value)});}}></Form.Control>
                        </Col>
                        <Col>
                            <Form.Control type="number" className=""  placeholder="제조월 입력" value={values.man_month} onChange={(e)=>{setValues({...values, man_month:Number(e.target.value)});}}></Form.Control>
                        </Col>
                    </Row>
                </Form.Group>
                <div className=" w-100 w-md-60 m-auto text-center">
                    <Button className="w-100" onClick={(e)=>{registerGenuine();}}>정품등록</Button>
                </div>
                { query.length == 0 || (data.model.length == 0 && data.products.length == 0)? 
                    ""
                    :
                    <>
                        <Container>
                            <div className='fs-4 fw-bold'>모델</div>
                            <div className='p-3'>
                                {data.model.map(function(a,i){     
                                    return(                           
                                        <Row idx={i} className='border-bottom py-2'  style={{cursor:"pointer"}} onClick={(e)=>{setValues({...values, model_uid : a.uid, model_name:a.model_name}); setQuery("")}}>
                                            <Col xs={6} lg={2}>
                                                <img className="w-100 pe-3" src={"/rest/downloadFile?filename="+a.img_loc}/>
                                            </Col>
                                            <Col>
                                                <Row className='fw-bold'>
                                                    {a.model_name}
                                                </Row>
                                                <Row className='text-secondary'>
                                                    {concatCategory(a.categories)}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>
                        </Container>
                    </>
                }
            </div>
        </section>
    </>
    );

}

export default Genuine;
import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, ListGroup } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Modal_alert from "../../modal.js";


function RegisterProduct(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("제품 상세조회/수정");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);
    const [img_upload, setImgUpload] = useState(null);
    const [btnText, setBtntext] = useState("수정");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [product_delete_show, setProductDeleteShow] = useState(false);
    const [product_deleted_show, setProductDeletedShow] = useState(false);
    const [product_delete_failed_show, setProductDeleteFailedShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const [regist_show, setRegistShow] = useState(false);
    const [regist_complete_show, setRegistCompleteShow] = useState(false);
    const [regist_failed_show, setRegistFailedShow] = useState(false);
    const [update_show, setUpdateShow] = useState(false);
    const [update_complete_show, setUpdateCompleteShow] = useState(false);
    const [update_failed_show, setUpdateFailedShow] = useState(false);

    const [modelSearch, setModelsearch] = useState("");
    const [modelList, setModelList] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [modelListShow, setModelListShow] = useState(false);

    const [partsSearch, setPartssearch] = useState("");
    const [partsList, setPartsList] = useState([]);
    const [selectedParts, setSelectedParts] = useState([]);
    const [partsListShow, setPartsListShow] = useState(false);

    const [vendorSearch, setVendorsearch] = useState({idx:-1, query:""});
    const [vendorList, setVendorList] = useState([]);

    const[values, setValues] = useState({uid:0, product_name:"", model_uid:0, serialnumber:"", created_date:"", equipments:[]});


    const handleChange = e => {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }
    const onLoadFile = (e)=>{
        setImgUpload(e.target.files);
    }
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/ProductList");
    };
    const handleShow = () => setShow(true);
    const handleVendorSearch = (e, idx) => {
        setVendorsearch({idx:idx, query:e.target.value});
    }
    const handleModelSearch = e => {
        setModelsearch(e.target.value);
    }
    const deleteParts = (uid, e) =>{
        console.log("deletepayplan "+uid);
        setSelectedParts(selectedParts.filter(item => item.uid != uid));
   }
   const handlePartChange = (e, uid) => {
        if(selectedParts != undefined && selectedParts.length >0){
            setSelectedParts(selectedParts.map(function(a, i){

                 if(Number(a.uid) != Number(uid)){
                    return a;
                 }else{
                    return {...a, serialnumber:e.target.value};
                 }
            }));
        }
        setVendorsearch({idx:-1, query:""});
        //setVendorListShow(false);
    }
   const vendorSelectHandler = (e, uid) => {
        if(selectedParts != undefined && selectedParts.length >0){
            setSelectedParts(selectedParts.map(function(a, i){
                 if(Number(a.uid) != Number(uid)){
                    return a;
                 }else{
                    return {...a, vendor_uid:Number(e.target.getAttribute("vendor_uid")), vendor_name:e.target.getAttribute("vendor_name")};
                 }
            }));
        }
        setVendorsearch({idx:-1, query:""});
        //setVendorListShow(false);
    }
    const handlePublic = () =>{
        setValues({
            ...values,
            ispublic :  !(values.ispublic),
        })
    };
    const modelSelectHandler = (e) => {
        setSelectedModel([{uid : Number(e.target.getAttribute("uid")), model_name: e.target.getAttribute("model_name")},
        ])
        setModelsearch("");
        setModelListShow(false);
    }
    const getModelInfo = (model_uid) =>{
        if(model_uid == 0 || values.model_uid == model_uid)
            return;
        setLoading(()=>{return true});
        axios.get('/rest/admin/getModelInfo',
        { params : {uid : model_uid}}).then(response =>{
            setSelectedParts(response.data.data.equipments);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const getProductInfo = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/getProductInfo',
        { params : {uid : location.state.uid}}).then(response =>{
            setValues(response.data.data);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const searchVendor = (q) =>{
        axios.get('/rest/admin/getVendorList', 
            {params:
            {
                page : 0,
                page_limit : 10,
                q_type: 'name',
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setVendorList(()=>{return response.data.list});           
                
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const searchModel = (q) =>{
        axios.get('/rest/admin/getModelList', 
            {params:
            {
                page : 0,
                page_limit : 10,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setModelList(()=>{return response.data.list});           
                
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const deleteProduct = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deleteProduct',
        { params : {uid : location.state.uid}}).then(response =>{
            if(response.data.result == 'success'){
                setProductDeleteShow(false);
                setProductDeletedShow(true);
            }else{
                setProductDeleteFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const regist = () =>{
        setLoading(true);
        let data = {
            uid: values.uid,
            serialnumber: values.serialnumber,
            model_uid: Number(selectedModel[0].uid),
            parts: selectedParts,
        };
        console.log(data);
        axios.post('/rest/admin/registerProduct',data).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    if(data.uid > 0){
                        setModalTitle("제품 수정 성공");
                        setModalContent("제품수정에 성공하였습니다.");
                    }else{
                        setModalTitle("제품 등록 성공");
                        setModalContent("제품등록에 성공하였습니다.");
                    }
                    setRegisterResult(true);
                }else{
                    if(data.uid > 0){
                        setModalTitle("제품 수정 실패");
                        setModalContent("제품수정에 실패하였습니다.");
                    }else{
                        setModalTitle("제품 등록 실패");
                        setModalContent("제품등록에 실패하였습니다.");
                    }
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const updatePart = (part) =>{
        setLoading(true);
        let data = {
            uid: part.uid,
            serialnumber: part.serialnumber,
            vendor_uid: Number(part.vendor_uid),
        };
        console.log(data);
        axios.post('/rest/admin/updateProductEquipment',data).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    alert("부품정보 변경완료");
                }else{
                    alert("부품정보 변경실패");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(() => {
        if(modelSearch.length > 0)
            searchModel(modelSearch);
        else
            setModelListShow(false);
    }, [modelSearch]);
    useEffect(() => {
        if(modelList.length > 0){
            setModelListShow(true);
        }else{
            setModelListShow(false);
        }

    }, [modelList]);
    /*
    useEffect(() => {
        if(vendorList.length > 0){
            //setVendorListShow(true);
        }else{
            setVendorListShow(-1);
        }

    }, [vendorList]);
    */
    useEffect(()=>{
        if(selectedModel != undefined && selectedModel.length > 0 && selectedModel[0].uid != undefined){
            getModelInfo(selectedModel[0].uid);
        }
    }, [selectedModel])
    useEffect(()=>{

    }, [selectedParts]);
    useEffect(() => {
        if(vendorSearch.query.length > 0)
            searchVendor(vendorSearch.query);
        //else
        //    setVendorsearch({idx:-1, query:""});
    }, [vendorSearch]);
    useEffect(() => {
        if(values.model_uid != undefined)
        setSelectedModel([{uid : Number(values.model_uid), model_name: values.model_name},
    ])
        if(values.equipments != undefined && values.equipments.length >0){
            let pei = values.equipments.map(function(a,i){ return a.pei;});
            setSelectedParts(pei);
        }
        }, [values]);
    useEffect(()=>{}, [modelList, partsList]);
    useEffect(() => {
        if(location.state != null && location.state.mode == 'mod'){
            setTitle(()=>{
                return "제품 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
            getProductInfo();
        }else{
            setTitle(()=>{
                return "제품 신규 등록";
            })
            setBtntext(()=>{
                return "등록";
            })
            setValues({uid:0, product_name:"", category_uid:0, img_loc:"", ispublic:false, created_date:"", equipments:[]});
        }
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={(e)=>{e.preventDefault()}} onKeyDown={(e)=>{if(e.code === "Enter") e.preventDefault();}}>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>시리얼넘버</Form.Label>
                                    <Form.Control required type="text" name="serialnumber" defaultValue={values.serialnumber} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>모델 검색</Form.Label>
                                    <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none"}} value={modelSearch} onChange={handleModelSearch} placeholder="모델 검색" />
                                    <ListGroup className={`${modelListShow ? "" : "d-none"} position-absolute`}>
                                        {modelList.map(function(item, idx){
                                            return(<ListGroup.Item action key={idx} onClick={modelSelectHandler} uid={item.uid} model_name={item.model_name}>{item.model_name}</ListGroup.Item>)
                                        })}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>모델</Form.Label>
                                    <Form.Control required type="text" name="model_name" defaultValue={selectedModel.length > 0 ? selectedModel[0].model_name : ""} readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>등록일시</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.created_date} placeholder="등록되면 표시됩니다." readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                        <Form.Label>부품목록</Form.Label>
                                    <Container className="border p-3">
                                        {selectedParts.map(function(item, idx){
                                            return(<Row key={idx} className="border-bottom lh-lg pb-1">
                                                <Col lg={11}>
                                                    {item.equipment_name}
                                                    {item.type_name}
                                                    <Form.Control required type="text" name="parts_serialnumber" defaultValue={item.serialnumber != undefined ? item.serialnumber : ""} onChange={(e)=>{handlePartChange(e, item.uid)}} placeholder="부품 시리얼넘버"/>
                                                    <Row>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>유통사 검색</Form.Label>
                                                            <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none"}} value={vendorSearch.idx == idx ? vendorSearch.query : ""} onChange={(e)=>{handleVendorSearch(e, idx)}} placeholder="유통사 검색" />
                                                            <ListGroup className={`${Number(vendorSearch.idx) == Number(idx) ? "" : "d-none"} position-absolute`}>
                                                                {vendorList.map(function(item1, idx1){
                                                                    return(<ListGroup.Item action key={idx1} onClick={(e)=>{vendorSelectHandler(e, item.uid)}} vendor_uid={item1.uid} vendor_name={item1.vendor_name}>{item1.vendor_name}</ListGroup.Item>)
                                                                })}
                                                            </ListGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label>유통사</Form.Label>
                                                            <Form.Control required type="text" name="vendor_name" value={item.vendor_name != undefined ? item.vendor_name : ""} readOnly/>
                                                        </Form.Group>
                                                    </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={1}>
                                                    { values.uid > 0 ?
                                                        <Button variant="outline-danger" className="btn btn-sm py-0" onClick={(e)=>{updatePart(item)}}>수정</Button>
                                                        :
                                                        ""
                                                    }
                                                </Col>
                                            </Row>)
                                        })}
                                    </Container>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            {values.uid > 0 ? 
                                <>
                                    <Col className="text-start">
                                        <Button type="button" className={`btn btn-danger`} style={{marginTop:"2rem"}} onClick={()=>{setProductDeleteShow(true)}}>제품삭제</Button>
                                    </Col>
                                </>
                                :
                                ""
                            }
                            <Col className="text-end">
                                {values.uid > 0 ? 
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setUpdateShow(true)}}>제품정보수정</Button>
                                    :
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setRegistShow(true)}}>제품정보등록</Button>
                                }
                                
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={product_delete_show} handleClose={()=>{setProductDeleteShow(false);}} handleSubmit={()=>{deleteProduct()}}  modal_title="제품삭제" content="제품을 삭제하시겠습니까?"  secondary={true} primary_btn={primary_btn} />
                <Modal_alert show={product_deleted_show} handleClose={()=>{setProductDeletedShow(false);navigate("/admin/ProductList");}} modal_title="제품델삭제완료" content="제품이 삭제되었습니다." primary_btn={primary_btn} />
                <Modal_alert show={product_delete_failed_show} handleClose={()=>{setProductDeleteFailedShow(false);}} modal_title="제품삭제실패" content="제품삭제에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={regist_show} handleClose={()=>{setRegistShow(false)}} handleSubmit={regist} modal_title="" content={`제품을 등록하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={regist_complete_show} handleClose={()=>{setRegistCompleteShow(false); navigate("/admin/ProductList")}} modal_title="제품등록완료" content="제품이 등록되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={regist_failed_show} handleClose={()=>{setRegistFailedShow(false);}} modal_title="제품등록실패" content="제품등록에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
                <Modal_alert show={update_show} handleClose={()=>{setUpdateShow(false)}} handleSubmit={()=>{setUpdateShow(false); regist()}} modal_title="" content={`제품정보를 수정하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={update_complete_show} handleClose={()=>{setUpdateCompleteShow(false); navigate("/admin/ProductList")}} modal_title="제품정보수정완료" content="제품정보가 수정되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={update_failed_show} handleClose={()=>{setUpdateFailedShow(false);}} modal_title="제품정보수정실패" content="제품정보 수정에 실패하였습니다." primary_btn={primary_btn} />
            </div>
        </>
        );

}

export default RegisterProduct;
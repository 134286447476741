import React from "react";
import Loading from "../../../../common/Loading";
import Modal_Password_alert from "../../../../common/modal_password";
import Modal_alert from "../../../../common/modal";
import { useLocation, useNavigate } from "react-router-dom";
import WebAPI_NLP from "./nlp";
import WebAPI_BGRM from "./bgrm";
import { useState } from "react";
import Status_view from "../modules/status_view";
import axios from "axios";
import { useEffect } from "react";
import Subscribe_Banner from "./subscribe_banner";
import WebAPI_TTS from "./tts";

function WebAPI(){
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");

    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    useEffect(()=>{
    }, [loading]);

    return(
        <>
            {loading == true ? <Loading/> : ""}
            <section className="section-content" style={{paddingBottom:"0"}}>
                <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
            </section>
            <section className="mt-1 pb-0">
                {
                    location.state.service_uid == 1 ?
                        <WebAPI_NLP loading={loading} setLoading={setLoading}/>
                    :
                    location.state.service_uid == 2 ?
                        <WebAPI_BGRM loading={loading} setLoading={setLoading}/>
                    :
                    location.state.service_uid == 3 ?
                        <WebAPI_TTS loading={loading} setLoading={setLoading}/>
                    :
                    ""
                }
            </section>
            <div className="sections">
                <Subscribe_Banner/>
            </div>
            <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
            <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); }} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>
        </>
    );

}

export default WebAPI;
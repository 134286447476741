import React from "react";


function Warrenty(){
    return(
    <>
        <section className="section-content mb-4 subcribe-price-section">
            <div className="policy-header mb-4">
                <h4><strong>보증규정</strong></h4>
                <h6></h6>
            </div>
            <div className="policy-body">
            <iframe src="https://file.namisnt.com/pdfjs/web/viewer.html?file=https://file.namisnt.com/docu/나미에스엔티_보증규정_191212.pdf" style={{width:"100%", height:"1000px"}}></iframe>
            </div>
        </section>
    </>
    );

}

export default Warrenty;
import React from "react";

function Privacy_body(){
    return (
        <div className="policy-body">
                    <h6>나미에스엔티('https://ai.namisnt.com'이하 'NAMISNT AI')는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</h6>
                    <br/>
                    <h6>○ 이 개인정보처리방침은 2023년 7월 1부터 적용됩니다.</h6>
                    <br/>
                    <h6><strong>제1조(개인정보의 처리 목적)</strong></h6>
                    <h6>나미에스엔티('https://ai.namisnt.com'이하 'NAMISNT AI')는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</h6>
                    <br/>
                    <h6>1. 홈페이지 회원가입 및 관리</h6>
                    <br/>
                    <h6>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.</h6>
                    <br/>
                    <h6>2. 민원사무 처리</h6>
                    <br/>
                    <h6>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.</h6>
                    <br/>
                    <h6>3. 재화 또는 서비스 제공</h6>
                    <br/>
                    <h6>서비스 제공, 계약서·청구서 발송, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산을 목적으로 개인정보를 처리합니다.</h6>
                    <br/>
                    <h6>4. 마케팅 및 광고에의 활용</h6>
                    <br/>
                    <h6>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</h6>
                    <br/>
                    <br/>
                    <h6><strong>제2조(개인정보의 처리 및 보유 기간)</strong></h6>
                    <br/>
                    <h6>① 나미에스엔티는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</h6>
                    <br/>
                    <h6>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</h6>
                    <br/>
                    <h6>1.&lt;홈페이지 회원가입 및 관리&gt;</h6>
                    <br/>
                    <h6>&lt;홈페이지 회원가입 및 관리&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터&lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</h6>
                    <br/>
                    <h6>보유근거 : 정보주체의 동의</h6>
                    <br/>
                    <h6>관련법령 : 1)계약 또는 청약철회 등에 관한 기록 : 5년</h6>
                    <h6>2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년</h6>
                    <h6>3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</h6>
                    <br/>
                    <h6>예외사유 : 다만, 관련법령에 의하여 보존 필요성이 있는 경우 또는 이용자로부터 사전동의를 받은 경우에는 보유 및 이용기간을 경과하여 보존할 수 있습니다.</h6>
                    <br/>
                    <h6>2.&lt;민원사무 처리&gt;</h6>
                    <br/>
                    <h6>&lt;민원사무 처리&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</h6>
                    <br/>
                    <h6>보유근거 : 정보주체의 동의</h6>
                    <br/>
                    <h6>관련법령 : 1)계약 또는 청약철회 등에 관한 기록 : 5년</h6>
                    <h6>2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년</h6>
                    <h6>3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</h6>
                    <br/>
                    <h6>3.&lt;재화 또는 서비스 제공&gt;</h6>
                    <br/>
                    <h6>&lt;재화 또는 서비스 제공&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터&lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</h6>
                    <br/>
                    <h6>보유근거 : 정보주체의 동의</h6>
                    <br/>
                    <h6>관련법령 : 1)계약 또는 청약철회 등에 관한 기록 : 5년</h6>
                    <h6>2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년</h6>
                    <h6>3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</h6>
                    <br/>
                    <h6>4.&lt;마케팅 및 광고에의 활용&gt;</h6>
                    <br/>
                    <h6>&lt;마케팅 및 광고에의 활용&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</h6>
                    <br/>
                    <h6>보유근거 : 정보주체의 동의</h6>
                    <br/>
                    <h6>관련법령 : 표시/광고에 관한 기록 : 6개월</h6>
                    <br/>
                    <h6><strong>제3조(처리하는 개인정보의 항목)</strong></h6>
                    <br/>
                    <h6>① 나미에스엔티는 다음의 개인정보 항목을 처리하고 있습니다.</h6>
                    <br/>
                    <h6>1&lt; 홈페이지 회원가입 및 관리 &gt;</h6>
                    <br/>
                    <h6>필수항목 : 이름, 로그인ID, 비밀번호, 비밀번호 질문과 답, 자택주소, 휴대전화번호, 이메일, 결제기록, 접속 IP 정보, 서비스 이용 기록</h6>
                    <h6>선택항목 : 회사명, 부서, 직책, 회사전화번호, 쿠키, 접속 로그</h6>
                    <br/>
                    <h6>2&lt; 민원사무 처리 &gt;</h6>
                    <br/>
                    <h6>필수항목 : 이름, 로그인ID, 휴대전화번호, 이메일</h6>
                    <h6>선택항목 : 회사명, 부서, 직책, 회사전화번호, 결제기록, 접속 IP 정보, 쿠키, 접속 로그, 서비스 이용 기록</h6>
                    <br/>
                    <h6>3&lt; 재화 또는 서비스 제공 &gt;</h6>
                    <br/>
                    <h6>필수항목 : 이름, 로그인ID, 휴대전화번호, 이메일, 은행계좌정보, 신용카드정보, 결제기록, 접속 IP 정보, 쿠키, 접속 로그, 서비스 이용 기록</h6>
                    <br/>
                    <h6>4&lt; 마케팅 및 광고에의 활용 &gt;</h6>
                    <br/>
                    <h6>필수항목 : 생년월일, 성별, 직업, 쿠키, 접속 로그, 서비스 이용 기록</h6>
                    <br/>
                    <h6><strong>제4조(개인정보의 파기절차 및 파기방법)</strong></h6>
                    <br/>
                    <h6>① 나미에스엔티는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</h6>
                    <h6>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</h6>
                    <br/>
                    <h6>1. 법령 근거 :</h6>
                    <br/>
                    <h6>2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜</h6>
                    <br/>
                    <h6>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</h6>
                    <br/>
                    <h6>1. 파기절차</h6>
                    <br/>
                    <h6>나미에스엔티는 파기 사유가 발생한 개인정보를 선정하고, 나미에스엔티의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</h6>
                    <br/>
                    <h6>2. 파기방법</h6>
                    <br/>
                    <h6>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</h6>
                    <h6>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다</h6>
                    <br/>
                    <h6><strong>제5조(미이용자의 개인정보 파기 등에 관한 조치)</strong></h6>
                    <br/>
                    <h6>① 개인정보처리자는 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체없이 파기합니다.</h6>
                    <h6>② 개인정보처리자는 휴먼전환 30일 전까지 휴면예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.</h6>
                    <h6>③ 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.</h6>
                    <br/>
                    <h6><strong>제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</strong></h6>
                    <br/>
                    <h6>① 정보주체는 나미에스엔티에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</h6>
                    <h6>② 제1항에 따른 권리 행사는나미에스엔티에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 나미에스엔티는 이에 대해 지체 없이 조치하겠습니다.</h6>
                    <h6>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</h6>
                    <h6>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</h6>
                    <h6>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</h6>
                    <h6>⑥ 나미에스엔티는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</h6>
                    <br/>
                    <h6><strong>제7조(개인정보의 안전성 확보조치에 관한 사항)</strong></h6>
                    <br/>
                    <h6>나미에스엔티는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</h6>
                    <br/>
                    <h6>1. 개인정보 취급 직원의 최소화 및 교육</h6>
                    <br/>
                    <h6>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</h6>
                    <br/>
                    <h6>2. 개인정보에 대한 접근 제한</h6>
                    <br/>
                    <h6>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</h6>
                    <br/>
                    <h6>3. 접속기록의 보관 및 위변조 방지</h6>
                    <br/>
                    <h6>개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.</h6>
                    <br/>
                    <h6>또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</h6>
                    <br/>
                    <h6>4. 개인정보의 암호화</h6>
                    <br/>
                    <h6>이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</h6>
                    <br/>
                    <h6>5. 해킹 등에 대비한 기술적 대책</h6>
                    <br/>
                    <h6>&lt;나미에스엔티&gt;('NAMISNT AI')는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</h6>
                    <br/>
                    <h6>6. 비인가자에 대한 출입 통제</h6>
                    <br/>
                    <h6>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</h6>
                    <br/>
                    <h6><strong>제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</strong></h6>
                    <br/>
                    <h6>① 나미에스엔티는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</h6>
                    <h6>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</h6>
                    <h6>가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</h6>
                    <h6>나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</h6>
                    <h6>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</h6>
                    <br/>
                    <h6><strong>제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)</strong></h6>
                    <br/>
                    <h6>① 나미에스엔티는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택을 제공하기 위하여 행태정보를 수집·이용하고 있습니다.</h6>
                    <h6>② 나미에스엔티는 다음과 같이 행태정보를 수집합니다.</h6>
                    <h6>③ 나미에스엔티는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.</h6>
                    <br/>
                    <h6>‣ 스마트폰의 광고식별자 차단/허용</h6>
                    <br/>
                    <h6>(1) (안드로이드) ① 설정 → ② 개인정보보호 → ③ 광고 → ③ 광고 ID 재설정 또는 광고ID 삭제</h6>
                    <h6>(2) (아이폰) ① 설정 → ② 개인정보보호 → ③ 추적 → ④ 앱이 추적을 요청하도록 허용 끔</h6>
                    <h6>※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.</h6>
                    <h6>④ 정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.</h6>
                    <h6>‣ 웹브라우저를 통한 맞춤형 광고 차단/허용</h6>
                    <h6>(1) 인터넷 익스플로러(Windows 10용 Internet Explorer 11)</h6>
                    <h6>- Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택</h6>
                    <h6>- 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택</h6>
                    <h6>(2) Microsoft Edge</h6>
                    <h6>- Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.</h6>
                    <h6>- 설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택합니다.</h6>
                    <h6>- ‘InPrivate를 검색할 때 항상 ""엄격"" 추적 방지 사용’ 여부를 선택합니다.</h6>
                    <h6>- 아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.</h6>
                    <h6>(3) 크롬 브라우저</h6>
                    <h6>- Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.</h6>
                    <h6>- 설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.</h6>
                    <h6>- 쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다.</h6>
                    <br/>
                    <h6>52 | 개인정보 처리방침 작성지침 일반</h6>
                    <br/>
                    <h6>⑧ 정보주체는 아래의 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.</h6>
                    <br/>
                    <h6>‣ 개인정보 보호 담당부서</h6>
                    <br/>
                    <h6>부서명 : 기술개발팀</h6>
                    <h6>담당자 : 김노산</h6>
                    <h6>연락처 : 010-6839-6171, ns416@namisnt.com, 02-473-0401</h6>
                    <br/>
                    <h6><strong>제10조(추가적인 이용·제공 판단기준)</strong></h6>
                    <br/>
                    <h6>나미에스엔티는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다. 이에 따라 나미에스엔티가 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.</h6>
                    <br/>
                    <h6>▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부</h6>
                    <h6>▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부</h6>
                    <h6>▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부</h6>
                    <h6>▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</h6>
                    <h6>※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함</h6>
                    <br/>
                    <h6><strong>제11조(가명정보를 처리하는 경우 가명정보 처리에 관한 사항)</strong></h6>
                    <br/>
                    <h6>나미에스엔티는 다음과 같은 목적으로 가명정보를 처리하고 있습니다.</h6>
                    <br/>
                    <h6>▶ 가명정보의 처리 목적</h6>
                    <br/>
                    <h6>- 실명 등 개인정보의 보호</h6>
                    <br/>
                    <h6>▶ 가명정보의 처리 및 보유기간</h6>
                    <br/>
                    <h6>- 회원 탈퇴 시까지</h6>
                    <br/>
                    <h6>▶ 가명처리하는 개인정보의 항목</h6>
                    <br/>
                    <h6>- 성명</h6>
                    <br/>
                    <h6>▶ 법 제28조의4(가명정보에 대한 안전조치 의무 등)에 따른 가명정보의 안전성 확보조치에 관한 사항</h6>
                    <br/>
                    <h6>- 관리적 조치 : 정기적 직원 교육</h6>
                    <br/>
                    <h6>- 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의</h6>
                    <h6>암호화, 보안프로그램 설치</h6>
                    <br/>
                    <h6>- 물리적 조치 : 전산실, 자료보관실 등의 접근통제</h6>
                    <br/>
                    <h6><strong>제12조 (개인정보 보호책임자에 관한 사항)</strong></h6>
                    <br/>
                    <h6>① 나미에스엔티는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</h6>
                    <br/>
                    <h6>▶ 개인정보 보호책임자</h6>
                    <br/>
                    <h6>성명 :김노산</h6>
                    <h6>직책 :기술개발팀장/CTO</h6>
                    <h6>직급 :팀장</h6>
                    <h6>연락처 :010-6839-6171, ns416@namisnt.com, 02-473-0401</h6>
                    <br/>
                    <h6>※ 개인정보 보호 담당부서로 연결됩니다.</h6>
                    <br/>
                    <h6>▶ 개인정보 보호 담당부서</h6>
                    <br/>
                    <h6>부서명 :기술개발팀</h6>
                    <h6>담당자 :김노산</h6>
                    <h6>연락처 :010-6839-6171, ns416@namisnt.com, 02-473-0401</h6>
                    <br/>
                    <h6>② 정보주체께서는 나미에스엔티의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 나미에스엔티는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</h6>
                    <br/>
                    <h6><strong>제13조(개인정보의 열람청구를 접수·처리하는 부서)</strong></h6>
                    <br/>
                    <h6>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.</h6>
                    <br/>
                    <h6>나미에스엔티는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</h6>
                    <br/>
                    <h6>▶ 개인정보 열람청구 접수·처리 부서</h6>
                    <br/>
                    <h6>부서명 : 고객서비스팀</h6>
                    <h6>담당자 : 이상석</h6>
                    <h6>연락처 : 010-4892-4363, ssp0929@namisnt.com, 02-473-0401</h6>
                    <br/>
                    <h6><strong>제14조(정보주체의 권익침해에 대한 구제방법)</strong></h6>
                    <br/>
                    <h6>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</h6>
                    <br/>
                    <h6>1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</h6>
                    <h6>2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</h6>
                    <h6>3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</h6>
                    <h6>4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</h6>
                    <br/>
                    <h6>「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</h6>
                    <br/>
                    <h6>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</h6>
                    <br/>
                    <h6><strong>제15조(개인정보 처리방침 변경)</strong></h6>
                    <br/>
                    <h6>① 이 개인정보처리방침은 2023년 7월 1일부터 적용됩니다.</h6>
                </div>
    );

}
export default Privacy_body;
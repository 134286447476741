import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import Banner from "../../common/banner";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

function Register_intro(){

    const checkSNSRegister = ()=>{
        axios.get('/rest/checkSNSRegister').then(response => {
        }).catch(
            error=>{
                alert("SNS 계정확인에 실패하였습니다. 잠시후 다시 시도해주세요");
                console.log(error);
            }
        );
    }

    useEffect(()=>{checkSNSRegister();}, [])
    return(
        <>
            <div className="banner-slider">
                <Banner />
            </div>
            <section className="section-content text-center" style={{paddingBottom:"3rem"}}>
                <Container>
                    <strong className="h5 fw-bold">회원가입</strong>
                    <div className="h6 m-4">가입유형을 선택해주세요</div>
                </Container>
                <Container>
                    <Row>
                        <Col lg={6} className="text-lg-end mt-3">
                            <Link to={{pathname: "/register/goRegister"}} state={{isBusiness:false}}>
                                <Button className="fs-5 fw-bold" style={{height:"200px", width:"200px", backgroundColor:"#80bbd5", border:"0"}}>
                                    만 14세 이상<br/>개인회원
                                </Button>
                            </Link>
                        </Col>                        
                        <Col lg={6} className="text-lg-start mt-3">
                            <Link to={{pathname: "/register/goRegister"}} state={{isBusiness:true}}>
                                <Button className="fs-5 fw-bold" style={{height:"200px", width:"200px", backgroundColor:"#0097dd", border:"0"}}>
                                   공공/기업 회원
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Container className="text-center">
                    <button type='button' className='mt-3' style={{width:"fit-content", background:"none", border:"none"}} onClick={()=>{window.open("/rest/socialLogin?social=naver", "_blank", 'width=850px,height=850px,location=no,status=no,scrollbars=yes')}}><img style={{height:"45px"}} src={process.env.PUBLIC_URL + '/assets/img/naver_login.png'}/></button><br/>
                    <button type='button' className='mt-3' style={{width:"fit-content", background:"none", border:"none"}} onClick={()=>{window.open("/rest/socialLogin?social=google", "_blank", 'width=850px,height=850px,location=no,status=no,scrollbars=yes')}}><img style={{height:"45px"}} src={process.env.PUBLIC_URL + '/assets/img/google_login.png'}/></button>
                    </Container>
                </Container>
            </section>
        </>
    );

}

export default Register_intro;
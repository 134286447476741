import React, {Component} from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Banner({data, handleRen}){
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="d-none d-sm-block">
        <Slider {...settings}>
          <div>
            <img className="banner-img" src={process.env.PUBLIC_URL + "/assets/img/banner1.png"} />
          </div>
          <div>
            <img className="banner-img" src={process.env.PUBLIC_URL + "/assets/img/banner2.png"} />
          </div>
          <div>
            <img className="banner-img" src={process.env.PUBLIC_URL + "/assets/img/banner3.png"} />
          </div>
        </Slider>
      </div>
    );
}

export default Banner;
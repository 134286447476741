import React from "react";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { useLocation, useNavigate, Link} from "react-router-dom";
import Loading from "../../../common/Loading.js";
import PageSelector from "../../../common/page_selector.js";
import { useState, useEffect } from "react";
import Status_view from "../modules/status_view.js";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip, FloatingLabel } from "react-bootstrap";
import Modal_Password_alert from "../../../common/modal_password.js";
import Modal_alert from "../../../common/modal.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ListSearch from "../../../common/listSearch.js";

function Qna(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title] = useState("서비스 목록 조회");

    const [qna_type, setQnaType] = useState([{type:"spec", text:"사양문의"}, {type:"as", text:"A/S관련 문의"}, {type:"etc", text:"기타 문의"}]);
    const [values, setValues] = useState({uid:0, qna_type:qna_type[0].type, msg:""});


    const [loading, setLoading] = useState(false);
    const [register_alert, setRegisterAlert] = useState(false);
    const [register_complete, setRegisterComplete] = useState(false);
    const [register_failed, setRegisterFailed] = useState(false);

    const [secret_pw, setSecretPw] = useState("");
    const [pw_show, setPwShow] = useState(false);

    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");

    const handleChangeQnaType = e => {
      setValues({...values, qna_type:e.target.value});
    }
    const handleChange = e => {
        setValues({...values, [e.target.name] : e.target.value});
    }
    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/sso/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
            setMyinfoPW("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const registerQna = ()=>{
        setLoading(true);
        axios.post('/rest/registerQna',values).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setRegisterAlert(false);
                if(response.data.result == 'success'){ 
                    setRegisterComplete(true);
                }else{
                    setRegisterFailed(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
    );

    useEffect(()=>{
        if(location.state != null){
            //requestAuthInfo();
            //requestList(search_kind,query);
        }else{
            //navigate("/");
        }
    }, []);
    useEffect(()=>{
        if(props != null && props != undefined && props.uid != null && props.uid != undefined)
            setValues({...values, uid:Number(props.uid)});
    }, [props.uid]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <section className="section-content" style={{paddingBottom:"0"}}>
            <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
            <Breadcrumb className='mt-3 breadcrumb-dark'>
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <BreadcrumbItem active>1:1 문의</BreadcrumbItem>
            </Breadcrumb>
        </section>
        <section className="sections-white" style={{ paddingBottom:"0", marginBottom:"0"}}>
            <div className='section-content'>
                <h5><strong>1:1 문의</strong></h5>
                    <div className="pb-5">
                    {loading == true ? <Loading/> : ""}
                    <div className="py-3 px-0 px-lg-2" style={{borderTop:"3px solid #585858", borderBottom:"1px solid #D8D8D8"}}>
                        <div className="h5">
                            <Form.Group>
                                <Form.Label>문의 유형</Form.Label>
                                <Form.Select name="category_select" value={values.qna_type} onChange={(e)=>{handleChangeQnaType(e);}} aria-label="문의유형 선택">
                                    {qna_type.map(function(a, idx){
                                        return (<option key={idx} value={a.type}>{a.text}</option>);
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="p-0 p-lg-3 mb-3">
                        <FloatingLabel controlId="floating-qna" label="문의내용">
                            <Form.Control
                                as="textarea"
                                placeholder="문의내용을 남겨주세요."
                                style={{ height: '100px' }}
                                name="msg"
                                value={values.msg}
                                onChange={(e)=>{handleChange(e);}}
                            />
                        </FloatingLabel>
                        <div className="py-3">
                            <Button className="float-end"  onClick={()=>{setRegisterAlert(true);}}>등록</Button>
                        </div>
                    </div>
                    <div className="py-3"   style={{borderTop:"1px solid #D8D8D8"}} >
                        <Button className="float-end" variant="light" onClick={()=>{navigate('/mypage/qnaList')}}>목록</Button>
                    </div>
                </div>
            </div>
        </section>
        <Modal_alert show={register_alert} handleClose={()=>{setRegisterAlert(false); }}  handleSubmit={registerQna} modal_title="갱신여부확인" content="1:1 문의를 등록하시겠습니까?" secondary="아니오" primary_btn="네"/>
        <Modal_alert show={register_complete} handleClose={()=>{setRegisterComplete(false); navigate("/mypage/qnalist");}} modal_title="등록완료" content="등록이 왼료되었습니다." primary_btn="확인"/>
        <Modal_alert show={register_failed} handleClose={()=>{setRegisterFailed(false); }} modal_title="등록실패" content="등록에 실패하였습니다." primary_btn="확인"/>        

        <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
        <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); setMyinfoPW("");}} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>


        </>

    );

}

export default Qna;
import React from "react";
import { useState } from "react";
import Loading from "../../common/Loading";
import Banner from "../../common/banner";
import { Breadcrumb, Button, Container, Form, InputGroup, Row } from "react-bootstrap";
import Modal_alert from "../../common/modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Modal_Password_Reset_alert from "../../common/modal_password_reset";

function FindPW(){
    const navigate = useNavigate();
    const [values, setValues] = useState({name:"",email:"", id:"" });
    const [emailauth, setEmailAuth] = useState(false);
    const [emailauth_cnt, setEmailAuthCount] = useState(300);
    const [emailauthNumber, setEmailAuthNumber] = useState("");

    const [pw, setPW] = useState("");
    const [pw_cfm, setPWCFM] = useState("");
    const [resetPWshow, setResetPWShow] = useState(false);
    const [resetPWComplete, setResetPWComplete] = useState(false);
    const [resetPWFailed, setResetPWFailed] = useState(false);

    const [name_check, setNameCheck] = useState(true);
    const [name_valid, setNameValid] = useState(true);
    const [id_check, setIDcheck] = useState(true);
    const [id_valid, setIDValid] = useState(false);
    const [email_check, setEmailcheck] = useState(true);
    const [emailauth_check, setEmailAuthcheck] = useState(true);
    const [email_valid, setEmailValid] = useState(false);
    const [emailauth_valid, setEmailAuthValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleChange = e => {
        console.log(e.target.name+" : "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
    }
    const handleNameChange = e => {
        if(e.target.value == "" || e.target.value.length >5){
            setNameCheck(false);
            setNameValid(false);
        }else{
            setNameCheck(true);
            setNameValid(true);
        }
        handleChange(e);
    }
    const handleIDChange = e => {
        setIDValid(false);
        if(e.target.value == "" || e.target.value.length <5){
            setIDcheck(false);
        }else{
            setIDcheck(true);
        }
        handleChange(e);
    }
    const handleEmailChange = e => {
        setEmailAuth(false);
        setEmailValid(false);
        setEmailAuthcheck(false);
        setEmailAuthValid(false);
        handleChange(e);
    }
    const updatePW = ()=>{
        setLoading(true);
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
        if(pw == "" || pw_cfm =="" || pw != pw_cfm || !passwordRegex.test(pw) ){
            setResetPWFailed(true);
            setLoading(false);
            return;
        }
        axios.post('/sso/rest/updatePW',{
            pw : pw,
            pw_cfm : pw_cfm
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
                return 0;
            }else{
                if(response.data.result == 'success'){
                    setResetPWComplete(true);
                }else if(response.data.result == 'same_pw'){
                    alert("이전과 같은 비밀번호는 설정하실 수 없습니다.");
                }else{
                    setResetPWFailed(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                setResetPWFailed(true);
                return 0;
            }
        );
    }
    const emailAuth = (email_addr)=>{
        setLoading(true);
        axios.post('/sso/rest/findPW',values).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
                return 0;
            }else{
                if(response.data.result == 'success'){
                    setEmailAuth(true);
                    setEmailAuthCount(300);
                    setEmailcheck(true);
                }else if(response.data.result == 'email_failed'){
                    alert("인증메일 전송에 실패하였습니다. 잠시후 다시 시도해주시거나 관리자에게 문의해주세요.");
                }else{
                    alert("일치하는 정보가 없거나 비정상적인 접근입니다.")
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return 0;
            }
        );
    }
    const checkAuthCode = (code)=>{
        setLoading(true);
        axios.post('/sso/rest/checkAuthCode',
        { 
            authcode:code
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("요청 오류가 발생하였습니다.");
            }else{
                if(response.data.result == 'success'){
                    setEmailValid(true);
                    setEmailAuthValid(true);
                    setEmailAuthcheck(true);
                }else{
                    setEmailAuthValid(false);
                    setEmailAuthcheck(false);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                console.log(error);
                return false;
            }
        );
    }              

    const sendEmail = ()=>{
        //TODO : requesting send sms to server
        setEmailValid(false);
        setEmailAuthValid(false);
        if(values.email == "")
            return;
        emailAuth(values.email);
    }

    useEffect(()=>{
        const cnt = setInterval(()=>{
            setEmailAuthCount(setEmailAuth => setEmailAuth -1);
        }, 1000);
        if(emailauth_cnt === 0 || emailauth_valid ==true){
            clearInterval(cnt);
            setEmailAuth(false);
        }
        return ()=>clearInterval(cnt);
    }, [emailauth_cnt]);

    useEffect(()=>{
        if (values.name.length > 0 && values.id.length > 0 && emailauthNumber.length > 0 && emailauth_check)
            setValidated(true);
        else
            setValidated(false);

    }, [values, email_valid, emailauth_valid, emailauth_check]);

    useEffect(()=>{
        console.log(validated);
    }, [validated]);

    return(
        <>
            {loading == true ? <Loading/> : ""}
            <div className="banner-slider">
                <Banner />
            </div>
            <section className="section-content" style={{paddingBottom:"0"}}>                <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        비밀번호 찾기
                    </Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <section className="sections-white pt-5">
                <Container className=" text-center">
                    <strong className="h5 fw-bold">비밀번호 찾기</strong>
                    <div className="h6 m-4">가입정보를 입력해주세요</div>
                </Container>
                <Form noValidate className="mw-100 m-auto" style={{width:"400px"}}>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>아이디</Form.Label>
                            <InputGroup>
                                <Form.Control isValid={id_valid} isInvalid={!id_check} required type="text" name="id" value={values.id} onChange={handleIDChange} placeholder="아이디를 입력해주세요."/>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>이름</Form.Label>
                            <Form.Control  required type="text" name="name" value={values.name} onChange={handleNameChange} placeholder="이름을 입력해주세요."/>
                            <Form.Control.Feedback type="invalid">
                                이름을 올바르게 입력해주세요.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>이메일주소</Form.Label>
                            <InputGroup>
                                <Form.Control isValid={email_valid} isInvalid={!email_check} required type="email" name="email" value={values.email} onChange={handleEmailChange} placeholder="이메일주소를 입력해주세요."/>
                                <Button variant="light" onClick={(e)=>{if(emailauth == false)sendEmail()}}>메일인증</Button>
                                <Form.Control.Feedback type="invalid">
                                    이메일이 중복되거나 올바르지 않습니다.
                                </Form.Control.Feedback>
                            </InputGroup>
                                {emailauth==true ?
                                    <Form.Group  className="mt-3">
                                        <InputGroup className="d-inline-block">
                                            <Form.Label>인증번호입력</Form.Label>
                                            <span className="float-end">{parseInt(emailauth_cnt/60)}:{String(emailauth_cnt%60).padStart(2, '0')}</span>
                                        </InputGroup>
                                        <InputGroup>
                                            <Form.Control isValid={emailauth_valid} isInvalid={!emailauth_check} required type="text" name="emailauth" value={emailauthNumber} onChange={(e)=>{setEmailAuthNumber(e.target.value)}} placeholder="인증번호를 입력해주세요."/>
                                            <Button variant="light" onClick={(e)=>{sendEmail()}}>재발송</Button>
                                            <Button variant="light" onClick={(e)=>{checkAuthCode(emailauthNumber)}}>인증확인</Button>
                                        </InputGroup>
                                    </Form.Group>
                                    :
                                    ""
                                }                            
                        </Form.Group>
                    </Row>
                    <Container className="mt-5 px-0">
                        <Button disabled={!validated} onClick={()=>{setResetPWShow(true)}} className="w-100">비밀번호 재설정</Button>
                    </Container>
                </Form>          
            </section>
            <Modal_alert show={resetPWComplete} handleClose={()=>{navigate("/")}} modal_title="비밀번호 재설정 완료" content="비밀번호 재설정이 완료되었습니다 :)" primary_btn="확인"/>
            <Modal_Password_Reset_alert show={resetPWshow} handleClose={()=>{setResetPWShow(false); setPW(""); setPWCFM("")}}  modal_title="비밀번호 재설정" content="비밀번호를 재설정해주세요." pw={pw} pw_cfm={pw_cfm} setSecretPw={setPW} setSecretPwCfm={setPWCFM} secondary="닫기" primary_btn="재설정" handleSubmit={()=>{updatePW()}} />
            <Modal_alert show={resetPWFailed} handleClose={()=>{setResetPWFailed(false)}} modal_title="비밀번호 재설정 실패" content="비밀번호 재설정에 실패하였습니다. 접속방법 혹은 네트워크 상태를 확인해주세요." primary_btn="확인"/>

        </>
    );
}

export default FindPW;
import React from "react";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { useLocation, useNavigate, Link} from "react-router-dom";
import Loading from "../../../common/Loading.js";
import ListSearch from "../../../common/listSearch.js";
import PageSelector from "../../../common/page_selector.js";
import { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, Button } from "react-bootstrap";
import Modal_Password_alert from "../../../common/modal_password.js";
import Modal_alert from "../../../common/modal.js";
import Status_view from "../modules/status_view.js";
function QnaList(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title] = useState("1:1 문의현황 조회");
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const [qna_type, setQnaType] = useState([{type:"spec", text:"사양문의"}, {type:"as", text:"A/S관련 문의"}, {type:"etc", text:"기타 문의"}]);
    const [qna_status, setQnaStatus] = useState([{status:"ready", text:"접수"}, {status:"process", text:"처리중"}, {status:"finished", text:"처리완료"}]);
    const [searchFilter, setSearchFilter] = useState([{val:"qna_type", name:"문의유형"},{val:"status", name:"진행상태"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");

    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");

    const PageHandler = (page)=>{
        setPage(page);
        //requestList(search_kind, query,page, location.state.service_uid);
    }
    const getQnaStatusIndex = (status)=>{
        let res_idx = -1;
        qna_status.map(function(item, idx){
            if(item.status == status){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }
    const getQnaTypeIndex = (type)=>{
        let res_idx = -1;
        qna_type.map(function(item, idx){
            if(item.type == type){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }

    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/sso/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
            setMyinfoPW("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const requestList = (q_type, q) =>{
        setLoading(true);
        if(q != ''){
            if(q_type == 'qna_type'){
                qna_type.map(function(item, idx){
                    if(item.text == q){
                        q = item.type;
                        return;
                    }
                });
            }else if(q_type == 'status'){
                qna_status.map(function(item, idx){
                    if(item.text == q){
                        q = item.status;
                        return;
                    }
                });
            }
        }
        axios.get('/rest/service/getQnaList', 
            {params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setListData(list => response.data.list);
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    useEffect(()=>{}, [search_kind, query]);

    //
    useEffect(() => {
            requestList(search_kind,query);
      },[page]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <section className="section-content" style={{paddingBottom:"0"}}>
            <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
            <Breadcrumb className='mt-3 breadcrumb-dark'>
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <BreadcrumbItem active>1:1 문의현황 조회</BreadcrumbItem>
            </Breadcrumb>
        </section>
        <div className={`card-shadow content sections-white`}  style={{marginTop:"1rem"}} >
            <div className="d-flex justify-content-between">
                <span className="fs-5"><strong>{title}</strong></span>
                <Button variant="outline-secondary" className=" me-lg-0 me-3"  onClick={()=>{navigate("/mypage/qna")}}>1:1 문의 등록</Button>
            </div>
            <ListSearch goSearch={requestList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
            <div>
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div style={{overflowX:'Auto'}}>
                <Table striped bordered hover size="sm" style={{minWidth:'1300px'}} >
                    <thead>
                        <tr>
                            <th>순서</th>
                            <th>문의유형</th>
                            <th>진행상태</th>
                            <th>문의내용</th>
                            <th>등록일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map(function(a, idx){
                            return (
                                <tr key={idx} onClick={(e)=>{navigate("/mypage/qnaView/"+a.uid)}}>
                                    <td>{idx+1}</td>
                                    <td>{qna_type != undefined ? qna_type[getQnaTypeIndex(a.qna_type)].text : ""}</td>
                                    <td>{qna_status != undefined ? qna_status[getQnaStatusIndex(a.status)].text : ""}</td>
                                    <td>{a.msg}</td>
                                    <td>{a.created_date}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
            <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); setMyinfoPW("");}} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>
        </>

    );

}

export default QnaList;
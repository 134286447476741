import React from "react";
import { Form, Row, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Modal_Demo_select(props){
    const navigate = useNavigate();
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        준비중입니다.. 조금만 기다려 주세요!
      </Tooltip>
    );
    return (
        <>
            <Modal
              show={props.show}
              onHide={props.handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>체험하실 API를 선택해주세요</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="mx-2">
                  <Card>
                    <Card.Body>
                      <Card.Title>이미지 배경제거 AI</Card.Title>
                      <Card.Text>신경망 기반의 인공지능이 이미지의 배경을 제거합니다.</Card.Text>
                      <Button variant="outline-secondary" onClick={()=>{navigate("/demo/bgrm")}}>체험하기</Button>
                    </Card.Body>
                  </Card>
                </Row>
                <Row  className="mt-3 mx-2">
                  <Card>
                    <Card.Body>
                      <Card.Title>자연어 처리 AI</Card.Title>
                      <Card.Text>두 단어의 유사도를 판단하는 인공지능 API입니다.</Card.Text>
                      <Button variant="outline-secondary" onClick={()=>{navigate("/demo/nlp")}} >체험하기</Button>
                    </Card.Body>
                  </Card>
                </Row>
                <Row  className="mt-3 mx-2">
                  <Card>
                    <Card.Body>
                      <Card.Title>음성합성 AI</Card.Title>
                      <Card.Text>문자를 목소리음원으로 만들어주는 인공지능 API입니다.</Card.Text>
                      <Button variant="outline-secondary" onClick={()=>{navigate("/demo/tts")}} >체험하기</Button>
                    </Card.Body>
                  </Card>
                </Row>
                <Row className="mt-3 mx-2">
                  <Card>
                    <Card.Body>
                      <Card.Title>OCR AI</Card.Title>
                      <Card.Text>인공지능이 이미지에 포함된 글자를 인식하여 텍스트로 변환해줍니다.</Card.Text>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <Button variant="outline-secondary">체험하기</Button>
                      </OverlayTrigger>
                    </Card.Body>
                  </Card>
                </Row>
              </Modal.Body>
            </Modal>
        </>
      );

}

export default Modal_Demo_select;
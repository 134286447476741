import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Dcenter(){
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [data, setData] = useState({model:[], products:[]});

    const handleChange = e => {
        setQuery(e.target.value);
    }
    const concatCategory = (list)=>{
        let str = "";
        for(var i=0; i<list.length; i++){
            str += list[i].category_name;
            if(i+1 < list.length)
                str += " > ";
        }
        return str;
    }

    const requestList = () =>{
        axios.get('/rest/searchProduct', 
            {params:
            {
                page: 0,
                page_limit : 5,
                query : query
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                //let totalSize = response.data.count;
                //let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setData(list => response.data.data);
                //setTotalSize(totalSize);
                //setTotalPage(totalPage_);
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
	
    useEffect(()=>{
        if(query.length > 0)
            requestList();
    }, [query])
    return(
    <>
        <section className="section-content mb-4 subcribe-price-section">
            <div className="policy-header mb-4">
                <h4><strong>다운로드센터</strong></h4>
                <h6></h6>
            </div>
            <div className="policy-body">
                <div className="h3 mb-3 fw-bold text-center">찾으시는 제품의 모델명 혹은 제품시리얼을 입력해주세요.</div>
                <Form.Group className='mb-3'>
   			            <Form.Control type="text" className=" w-100 w-md-60 m-auto" name="query" placeholder="모델명 혹은 제품시리얼 입력" value={query} onChange={handleChange}></Form.Control>
	            </Form.Group>
                { query.length == 0 || (data.model.length == 0 && data.products.length == 0)? 
                    ""
                    :
                    <>
                        <Container>
                            <div className='fs-4 fw-bold'>모델</div>
                            <div className='p-3'>
                                {data.model.map(function(a,i){     
                                    return(                           
                                        <Row key={i} className='border-bottom py-2'  style={{cursor:"pointer"}} onClick={(e)=>{navigate("/cs/modelinfo/"+a.uid)}}>
                                            <Col xs={6} lg={2}>
                                                <img className="w-100 pe-3" src={"/rest/downloadFile?filename="+a.img_loc}/>
                                            </Col>
                                            <Col>
                                                <Row className='fw-bold'>
                                                    {a.model_name}
                                                </Row>
                                                <Row className='text-secondary'>
                                                    {concatCategory(a.categories)}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>
                        </Container>
                        <Container>
                            <div className='fs-4 fw-bold'>제품</div>
                            <div className='p-3'>
                                {data.products.map(function(a,i){                               
                                    return ( 
                                        <Row idx={i} className='border-bottom py-2' style={{cursor:"pointer"}} onClick={(e)=>{navigate("/cs/modelinfo/"+a.uid)}}>
                                            <Col xs={6} lg={2}>
                                                <img className="w-100" src={"/rest/downloadFile?filename="+a.img_loc}/>
                                            </Col>
                                            <Col>
                                                {a.model_name}
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>
                        </Container>
                    </>
                }
            </div>
        </section>
    </>
    );

}

export default Dcenter;
import React from "react";
import { useState, useEffect } from "react";
import {Row, Col, Container, ListGroup, Card, Table, Navbar, Nav, Button, Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import Loading from "../../../common/Loading";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Status_view from "../modules/status_view";
import Modal_Password_alert from "../../../common/modal_password";
import Modal_alert from "../../../common/modal";
function Qna_view(props){
    const navigate = useNavigate();
    let params = useParams();
    const [qna_type, setQnaType] = useState([{type:"spec", text:"사양문의"}, {type:"as", text:"A/S관련 문의"}, {type:"etc", text:"기타 문의"}]);
    const [qna_status, setQnaStatus] = useState([{status:"ready", text:"접수"}, {status:"process", text:"처리중"}, {status:"finished", text:"처리완료"}]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({uid:0, qna_type:'spec', qna_status:'ready', msg:'', created_date:"", answers:[]});
    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");
    const getQnaStatusIndex = (status)=>{
        let res_idx = -1;
        qna_status.map(function(item, idx){
            if(item.status == status){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }
    const getQnaTypeIndex = (type)=>{
        let res_idx = -1;
        qna_type.map(function(item, idx){
            if(item.type == type){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }
    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/sso/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
            setMyinfoPW("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const requestData = () =>{
        setLoading(true);
        axios.get('/rest/service/getQnaInfo', 
            {params:
            {
                uid:params.uid
            }}).then(response => {
            if(response.data.logged === false || response.data == '' || response.data.result != 'success'){
                alert("잘못된 접근입니다.");
                navigate("/");
            }else{
                setData(response.data.data);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    useEffect(()=>{
        if(params.uid != undefined && params.uid != null){
            requestData();
        }else{
            alert("잘못된 접근입니다.");
            window.history.back();
        }

    }, []);
    
    return(
        <>
            {loading == true ? <Loading/> : ""}
            <section className="section-content" style={{paddingBottom:"0"}}>
                <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
                <Breadcrumb className='mt-3 breadcrumb-dark'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item href="/mypage/dashboard">
                        마이페이지
                    </Breadcrumb.Item>
                    <BreadcrumbItem active>1:1 문의현황 조회</BreadcrumbItem>
                </Breadcrumb>
            </section>
            <section className="sections-white mt-0 p-0 p-lg-4">
                <div className="section-content">
                    <div className="pb-5">
                        <div className="py-3 px-0 px-lg-2" style={{borderTop:"3px solid #585858", borderBottom:"1px solid #D8D8D8"}}>
                            <div>
                                <span className="border border-primary rounded p-3 py-2 me-3 bg-light text-secondary fw-bold">{qna_type != undefined ? qna_type[getQnaTypeIndex(data.qna_type)].text : ""}</span>
                                <span className="border border-secondary rounded p-3 py-2 me-3 bg-light text-secondary fw-bold">{qna_status != undefined ? qna_status[getQnaStatusIndex(data.qna_status)].text : ""}</span>
                                <div className="d-lg-inline d-flex">
                                    <span className="float-lg-end mt-3 mt-lg-0">등록일자 : {data.created_date}</span>
                                </div>
                            </div>
                        </div>
                        <div className="p-3 mb-5"  style={{borderBottom:"1px solid #D8D8D8"}}>
                            {data.msg}
                        </div>
                        {  data.answers.length > 0 ? 
                            <>
                            <div className="fs-6 fw-bold mb-3">총 {data.answers.length}개의 답변이 있습니다.</div>
                                {data.answers.map(function(a,i){
                                    return(
                                        <Card body key={i}>
                                            <Container>
                                            <div className="py-1 px-0 px-lg-2" style={{ borderBottom:"1px solid #D8D8D8"}}>
                                                <div>
                                                    <div className="">
                                                        <div className="text-end">등록일자 : {data.created_date}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-3">
                                                {a.msg}
                                            </div>
                                            </Container>
                                        </Card>
                                    );
                                })}
                            </>
                            :
                            ""
                        }
                        <div className="py-3">
                            <Button className="float-end" variant="light" onClick={()=>{navigate('/mypage/qnaList')}}>목록</Button>
                        </div>
                    </div>
                </div>
            </section>
            <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
            <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); setMyinfoPW("");}} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>
        </>
    );
}

export default Qna_view;
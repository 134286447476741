import React from "react";
import axios from "axios";
import { useLocation, useNavigate, Link} from "react-router-dom";
import Loading from "../../../common/Loading.js";
import ListSearch from "../../../common/listSearch.js";
import PageSelector from "../../../common/page_selector.js";
import { useState, useEffect } from "react";
import Status_view from "./modules/status_view.js";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row } from "react-bootstrap";
import Modal_Password_alert from "../../../common/modal_password.js";
import Modal_alert from "../../../common/modal.js";
import Modal_Coupon_alert from "../../../common/modal_coupon.js";

function Orderlist_user(){
    const navigate = useNavigate();
    const [title] = useState("주문목록");
    const [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const [serviceType, setServiceType]= useState([]);
    const [searchFilter, setSearchFilter] = useState([{val:"plan", name:"플랜"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");

    const [myinfo_pw_show, setMyinfoPWShow] = useState(false);
    const [myinfo_fail_show, setMyinfoFailShow] = useState(false);
    const [myinfo_pw, setMyinfoPW] = useState("");

    const [step] = useState({10: "주문완료", 15:"결제대기",20:"결제완료", 25:"크레딧지급대기", 30:"크레딧지급완료", 80:"결제취소", 90:"주문취소"});


    const PageHandler = (page)=>{
        setPage(page);
        //requestList(search_kind, query,page);
    }
    
    const getServiceType = () =>{
        axios.get('/rest/getServiceType').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setServiceType(()=>{return response.data});
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );

    }
    const getServiceIndex = (uid)=>{
        let res_idx = -1;
        serviceType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }

    const pw_handleSubmit = ()=>{
        setLoading(true);
        axios.post('/rest/myinfo_pw_check',{
                pw : myinfo_pw
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setMyinfoPWShow(false);
                if(response.data.result == 'success'){ 
                    navigate("/mypage/modifyInfo", {state:{key:response.data.key}});
                }else{
                    setMyinfoFailShow(true);
                }
            }
            setLoading(false);
            setMyinfoPW("");
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const requestList = (q_type, q) =>{
        setLoading(true);
        getServiceType();
        if(q_type == 'service_uid' && q.length > 0){

            serviceType.map(function(a, idx){
                if(a.service_name == q){
                    q = Number(a.uid);
                    return;
                }
            });
        }else if(q_type == "service_uid"){
            q_type = "";
        }
        axios.get('/rest/getOrderList', 
            {params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/mypage");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setOrderList(list => response.data.list);
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/mypage");
                console.log(error);
            }
        );
    }
    useEffect(()=>{}, [search_kind, query]);

    //
    useEffect(() => {
        requestList(search_kind,query,);
      },[page]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <section className="section-content" style={{paddingBottom:"0"}}>
            <Status_view setLoading={setLoading} setMyinfoShow={setMyinfoPWShow} modifyInfo={pw_handleSubmit}/>
            <Breadcrumb className='mt-3'>
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item href="/mypage/dashboard">
                    마이페이지
                </Breadcrumb.Item>
                <BreadcrumbItem active>주문목록</BreadcrumbItem>
            </Breadcrumb>
        </section>
        <div className={`card-shadow content sections-white`}  style={{marginTop:"1rem"}} >
            <div>
                <h5><strong>{title}</strong></h5>
            </div>
            <Row className="px-3">
                <ListSearch goSearch={requestList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
            </Row>
            <div>
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div style={{overflowX:'Auto'}}>
                {
                        orderList.length > 0 ?
                        orderList.map(function(a, idx){
                                return(
                                    <Card key={idx} className='border border-white'>
                                        <Card.Body>
                                            <Row className='px-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Link to={{pathname: "/mypage/order"}} state={{order_id : a.order_id}}>
                                                            <Row>
                                                                <Col lg={10}>
                                                                    <Row>
                                                                        <h6><strong>{a.order_id}</strong></h6>
                                                                        <span className='ms-2'>{a.plan_name}</span>
                                                                        <span className='ms-2'>{a.order_date}</span>
                                                                    </Row>
                                                                </Col>
                                                                <Col className='text-end'>
                                                                        <span className='rounded bg-primary text-light p-2' style={{lineHeight:"5rem"}}>{step[a.step]}</span>
                                                                </Col>
                                                            </Row>
                                                        </Link>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                );
                            })
                        :
                        <>
                            <h6 className='text-center pb-4 m-0'>주문이 없습니다.</h6>
                        </>
                    }
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        <Modal_Password_alert show={myinfo_pw_show} pw={myinfo_pw} setSecretPw={setMyinfoPW} handleClose={()=>{setMyinfoPWShow()}} handleSubmit={()=>{pw_handleSubmit()}} modal_title="비밀번호 입력" content="회원정보 수정을 위해 비밀번호를 입력해주세요." secondary="닫기" primary_btn="확인" />
            <Modal_alert show={myinfo_fail_show} handleClose={()=>{setMyinfoFailShow(false); setMyinfoPW("");}} modal_title="비밀번호 불일치" content="비밀번호가 일치하지 않습니다." primary_btn="확인"/>
        </>

    );

}

export default Orderlist_user;
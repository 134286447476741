import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";


function Status_view(prop){
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState([]);
    const [sns_member, setSnsMember] = useState(false);
    const [genuineInfo, setGeniuneInfo] = useState([]);
    const requestUserInfo = () =>{
        prop.setLoading(true);
        axios.get('/sso/rest/getUserSummary').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setUserInfo(response.data.data);
            }
            prop.setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const requestGenuineInfo = () =>{
        axios.get('/rest/service/getGenuineList', {params:{
            page:0,
            page_limit:5,
            q_type:"",
            query:""
        }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setGeniuneInfo(response.data);
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
    );
    useEffect(()=>{
        requestUserInfo();
        requestGenuineInfo();
    }, [])
    useEffect(()=>{
        if(userInfo.sns_type != undefined){
            setSnsMember(true);
        }
    }, [userInfo]);

    return(
        <Card>
            <Card.Body>
                <Row>
                    <Col lg={4}>
                        <Row>
                            <Col>
                                <h5><strong>{userInfo.nickname}님</strong></h5>
                            </Col>
                            <Col className="text-end">
                                {prop.mypagebtn != false ?
                                    <Button variant="light" className="me-3" onClick={()=>{if(sns_member== true){prop.modifyInfo();}else{prop.setMyinfoShow(true);}}}>회원정보수정</Button>
                                    :
                                    ""
                                }
                                
                            </Col>
                        </Row>
                        <Row>
                            <span>{userInfo.phonenumber}</span>
                            <span>{userInfo.email}</span>
                        </Row>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            <h5>보유제품</h5>
                            <h4>{genuineInfo.count}개</h4>
                        </Row>
                    </Col>
                    <Col lg={2} className="pe-3">
                        <Row>
                            <Col className="text-end">
                                <div className="mb-2 d-lg-block d-inline">
                                    <Button variant="outline-secondary" className=" me-lg-0 me-3"  onClick={()=>{navigate("/mypage/qnaList")}}>1:1 문의</Button>
                                </div>
                                <div className=" d-lg-block d-inline">
                                    <Button variant="outline-secondary" className=" me-lg-0 me-3 d-none"  onClick={()=>{navigate("/mypage/couponList")}}>A/S접수</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )

}

export default Status_view;
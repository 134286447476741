import React, {useState} from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import  styles from "../../css/admin/common-admin.module.css"


function Admin_Sidebar(props){
	const [name, setName] = useState('');
	const navigate = useNavigate;
	const check_logged = async() => {
		const response = await axios.get("/rest/admin/getUserInfo");
		setName(response.name);
	};
    return (
        <div className={`${styles.sidebar} ${props.toggle_ ? "" : styles.toggled}`} id={styles.sidebar}>
        	<nav className="navbar navbar-dark bg-white">
                <div className={`container-fluid ${styles["container-fluid"]} table`}>
        	        <div id={styles["logo-container"]} className="col-6">
        	    			<img alt="LOGO" src={process.env.PUBLIC_URL + '/assets/img/logo.png'} id="logo"/>
        	        </div>
                    <button className={`navbar-toggler ${props.toggle_ ? "" : "d-none"}`} type="button" onClick={() =>{props.handleSidebarToggle();}}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
        	        <div className={styles["account-info"]}>
        	            <span>{props.UserName}</span>
        	    	    <button className={`${styles["logout"]} btn btn-sm p-0`} onClick={props.Logout_func}>
        	    	        <strong>LOGOUT</strong>
        	    	    </button>
        	        </div>
                </div>
            </nav>
        	<div className={styles["sidebar-item"]}>
        		<div className={styles["sidebar-item-list"]}>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>대시보드
        						</a>
        					</div>
        				</li>
        			</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>회원정보
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/UserList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>회원목록조회/수정
        							</Link>
        						</div>
        					</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<a href="">
        								<i className=" mr-2"></i>회원추가
        							</a>
        						</div>
        					</li>
							<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<a href="">
        								<i className=" mr-2"></i>사업자정보조회
        							</a>
        						</div>
        					</li>
        				</ul>
        			</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>AS관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/ASList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>AS접수 조회/수정
        							</Link>
        						</div>
        					</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<a href="">
        								<i className=" mr-2"></i>AS등록
        							</a>
        						</div>
        					</li>
        				</ul>
        			</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>게시글관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/ArticleList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>게시글 조회/수정
        							</Link>
        						</div>
        					</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<Link to="/admin/registerArticle" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>게시글 등록
        							</Link>
        						</div>
        					</li>
        				</ul>
        			</ul>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>모델관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/ModelList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>모델 조회/수정
        							</Link>
        						</div>
        					</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<Link to="/admin/registerModel" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>모델등록
        							</Link>
        						</div>
        					</li>
        				</ul>
        			</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>제품관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/ProductList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>제품 조회/수정
        							</Link>
        						</div>
        					</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/registerProduct" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>제품등록
        							</Link>
        						</div>
        					</li>
        				</ul>
						<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/GenuineList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>정품등록 조회/수정
        							</Link>
        						</div>
        					</li>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<a href="">
        								<i className=" mr-2"></i>정품등록
        							</a>
        						</div>
        					</li>
        				</ul>
        			</ul>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>유통사관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
                    	            <Link to="/admin/VendorList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>유통사 조회/수정
        							</Link> 
        						</div>
        					</li>
        						<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<Link to="/admin/registerVendor" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>유통사 등록
        							</Link>
        						</div>
        					</li>
        				</ul>
        			</ul>
        			<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>SW관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
                    	            <Link to="/admin/SoftwareCategoryList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>SW카테고리 조회/수정
        							</Link> 
        						</div>
        					</li>
        						<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/registerPayplan" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>SW카테고리 등록
        							</Link>
        						</div>
        					</li>
        				</ul>
						<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
                    	            <Link to="/admin/SoftwareOSList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>SW운영체제 조회/수정
        							</Link> 
        						</div>
        					</li>
        						<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/registerPayplan" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>SW운영체제 등록
        							</Link>
        						</div>
        					</li>
        				</ul>
					</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>모델카테고리관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
                    	            <Link to="/admin/ModelCategoryList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>모델카테고리 조회/수정
        							</Link> 
        						</div>
        					</li>
        						<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/registerPayplan" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>모델카테고리 등록
        							</Link>
        						</div>
        					</li>
        				</ul>
					</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>부품관리
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
                    	            <Link to="/admin/EquipmentList" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>부품조회/수정
        							</Link> 
        						</div>
        					</li>
        						<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
									<Link to="/admin/RegisterEquipment" onClick={() =>{props.handleSidebarToggle();}}>
        								<i className=" mr-2"></i>부품 등록
        							</Link>
        						</div>
        					</li>
        				</ul>
					</ul>
					<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]}`}>
        				<li className={`nav-link ${styles["nav-link"]} active`}>
        					<div>
        						<a href="">
        							<i className=" mr-2"></i>문의/답변
        						</a>
        					</div>
        				</li>
        				<ul className={`nav list-group ${styles["nav"]} ${styles["list-group"]} ${styles["sub-1"]}`}>
        					<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<Link to="/admin/QnaList">
        								<i className=" mr-2"></i>문의 현황 조회
        							</Link> 
        						</div>
        					</li>
        						<li className={`nav-link ${styles["nav-link"]} active`}>
        						<div>
        							<a href="">
        								<i className=" mr-2"></i>FAQ 조회/수정
        							</a>
        						</div>
        					</li>
        				</ul>
        			</ul>
        		</div>
        	</div>
        </div>
    );

}

export default Admin_Sidebar;
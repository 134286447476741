import React from "react";
import "../css/common.css";

function Loading(){
    return (
        <div className="loading_bg">
            <div className="loading_text">처리중..</div>
            <img src={process.env.PUBLIC_URL + "/assets/loading.gif"} alt="처리중.." width="5%"/>
        </div>
    );
}

export default Loading;
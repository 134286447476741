import React from "react";
import { useState } from "react";
import Loading from "../../common/Loading";
import Banner from "../../common/banner";
import { Breadcrumb, Button, Container, Form, InputGroup, Row } from "react-bootstrap";
import Modal_alert from "../../common/modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function FindID(){
    const navigate = useNavigate();
    const [id_not_found_show, setIDNotFoundShow] = useState(false);
    const [IDFound_show, setIDFoundShow] = useState(false);
    const [values, setValues] = useState({name:"",email:"" });
    const [IDFound, setIDFound] = useState("");
    
    const [loading, setLoading] = useState(false);

    const [name_check, setNameCheck] = useState(true);
    const [name_valid, setNameValid] = useState(true);
    const [email_valid, setEmailValid] = useState(false);

    const [email_check, setEmailcheck] = useState(true);

    const [validated, setValidated] = useState(false);

    const handleNameChange = e => {
        if(e.target.value == "" || e.target.value.length >20){
            setNameCheck(false);
            setNameValid(false);
        }else{
            setNameCheck(true);
            setNameValid(true);
        }
        handleChange(e);
    }
    const handleEmailChange = e => {
        if(e.target.value == "" || !e.target.value.includes("@")){
            setEmailcheck(false);
            setEmailValid(false);
        }else{
            setEmailcheck(true);
            setEmailValid(true);
        }
        handleChange(e);
    }

    const handleChange = e => {
        //console.log(e.target.name+" : "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
    }

    const requestFindID = ()=>{
        setLoading(true);
        axios.get('/sso/rest/findID',{params:values}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == "success"){
                    setIDFound(()=>{return "ID는 "+response.data.id+"입니다."})
                    setIDFoundShow(true);
                }else{
                    setIDNotFoundShow(true);
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                alert("중복확인에 실패하였습니다. 잠시후 다시 시도해주세요");
                console.log(error);
            }
        );
    }

    useEffect(()=>{
        if (values.name.length > 0 && name_check && name_valid && values.email.length > 0 && email_check && email_valid)
            setValidated(true);
        else
            setValidated(false);

    }, [values]);

    useEffect(()=>{
        console.log(validated);
    }, [validated]);
    return(
        <>
            {loading == true ? <Loading/> : ""}
            <div className="banner-slider">
                <Banner />
            </div>
            <section className="section-content" style={{paddingBottom:"0"}}>                
            <Breadcrumb className='mt-3'>
                    <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        아이디찾기
                    </Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <section className="sections-white pt-5">
                <Container className=" text-center">
                    <strong className="h5 fw-bold">아이디찾기</strong>
                    <div className="h6 m-4">가입정보를 입력해주세요</div>
                </Container>
                <Form noValidate className="mw-100 m-auto" style={{width:"400px"}}>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>이름</Form.Label>
                            <Form.Control isValid={name_valid} isInvalid={!name_check}  required type="text" name="name" value={values.name} onChange={handleNameChange} placeholder="이름을 입력해주세요."/>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group>
                            <Form.Label>이메일주소</Form.Label>
                            <InputGroup>
                                <Form.Control isValid={email_valid} isInvalid={!email_check} required type="email" name="email" value={values.email} onChange={handleEmailChange} placeholder="이메일주소를 입력해주세요."/>
                            </InputGroup>         
                        </Form.Group>
                    </Row>
                    <Container className="mt-5 px-0">
                        <Button  disabled={!validated} onClick={()=>{requestFindID()}} className="w-100">아이디찾기</Button>
                    </Container>
                </Form>          
            </section>
            <Modal_alert show={IDFound_show} handleClose={()=>{setIDFoundShow(false)}} modal_title="아이디 찾기" content={IDFound} primary_btn="확인"/>
            <Modal_alert show={id_not_found_show} handleClose={()=>{setIDNotFoundShow(false);}} modal_title="아이디찾기 실패" content="일치하는 회원정보가 없습니다. 입력정보를 확인해주세요." primary_btn="확인"/>
        </>
    );
}

export default FindID;
import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

function Footer(){
    const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <footer className="bg-black text-center py-5">
            <div className="container px-5 text-start">
                <div className="text-white-50 small">
                    <img className="mb-4" style={{maxWidth:"200px"}} src={process.env.PUBLIC_URL + '/assets/img/logo_footer.png'} />
                    <div className="" style={{fontSize:"0.8rem"}}>
                        <div className=""><strong>나미에스엔티</strong></div>
                        <div className="">대표 : 김노산, 이상석</div>
                        <div className="">사업자등록번호 : 762-10-01066</div>
                        <div className="">통신판매업신고번호 : 2020-서울강동-1406</div>
                        <div className="">개인정보책임자 : 김노산</div>
                        <div className="">고객지원 : 1588-7397 혹은 카카오톡 채널 '나미에스엔티'</div>
                        <div className="">customer@namisnt.com</div>
                        <div className="mb-2">서울특별시 강동구 천호대로 1073, B226호</div>
                    </div>

                    <div className="mb-2">&copy; NAMISNT 2024. All Rights Reserved.</div>
                    <Link className="text-white-50" to="/policy/agreement" onClick={scrollToTop}>이용약관</Link>
                    <span className="mx-1">&middot;</span>
                    <Link className="text-white-50" to="/policy/privacy" onClick={scrollToTop}>개인정보처리방침</Link>
                    <span className="mx-1">&middot;</span>
                    <a href="#!" className="text-white-50">FAQ</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
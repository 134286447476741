import React from "react";
import { Breadcrumb } from "react-bootstrap";
import Docs_Greeting from "./docs_greeting";

function Docs_Intro(props){
    
    return(
        <>
            <div className="banner position-relative">
                <img style={{opacity:"0.3", maxHeight:"initial", overflow:"hidden"}} className="w-100" src="/assets/img/intro.jpg"/>
                <div className="position-absolute m-auto text-white w-100 banner-text">
                    <h1 className="text-center">고객님과 함께 고민합니다.</h1>
                </div>
                <section className="px-lg-5 px-lg-0 px-3 position-absolute" style={{paddingBottom:"0", bottom:"0"}}>
                    <Breadcrumb className='mt-3 px-5'>
                        <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {props.page == 'greeting' ? '인사말' : ""}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </section>
            </div>
            
            <div className="pt-0">
                <section className="sections-white mt-0 p-4">
                    {
                        props.page == "greeting" ?
                        <Docs_Greeting/> :
                        ""
                    }
                </section>
            </div>
        </>
    );
}

export default Docs_Intro;
import React from "react";

function Agreement_body(){
    return (
        <div className="policy-body">
            <h5><strong>가. 일반조건</strong></h5><br/><br/>
            <h6><strong>1. 목적</strong></h6>
            <h6>나미에스엔티 AI서비스 이용약관(이하 ‘본 약관’이라 합니다)은 나미에스엔티(이하 '회사'라 합니다)와 이용자 간에 회사가 제공하는 API 서비스의 제공 및 이용과 관련한 회사와 이용자의 권리, 의무 및 책임에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.</h6>
            <br/>
            <h6><strong>2. 용어의 정의 </strong></h6>
            <h6>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</h6>
            <br/>
            <h6>2.1. ‘API’란 Application Programming Interface의 약자로서 이용자가 본인의 애플리케이션 등에서 회사가 제공하는 기술을 사용하기 위한 인터페이스를 의미하며, ‘API서비스’란 회사가 그와 같은 API를 이용자에게 제공하는 것을 지칭합니다.</h6>
            <br/>
            <h6>2.2. ‘이용자’란 나미에스엔티 AI 서비스(https://ai.namisnt.com) 의 회원으로서 본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 API서비스를 이용하는 자를 말합니다.</h6>
            <br/>
            <h6>2.3 ‘이용신청자’란 회사에 API서비스를 이용 신청한 고객을 말합니다.</h6>
            <br/>
            <h6>2.3. ‘애플리케이션 등’이라 함은 이용자가 API서비스를 적용시키고자 하는 이용자의 애플리케이션, 프로그램 또는 웹사이트 등을 의미합니다.</h6>
            <br/>
            <h6>2.4. ‘서비스 웹사이트’라 함은 회사의 API서비스 제공 웹페이지(https://ai.namisnt.com)를 말합니다.</h6>
            <br/>
            <h6>2.5. ‘클라이언트 아이디’라 함은 API를 사용하고자 하는 어플리케이션 등이 API서비스의 이용 승낙을 받은 이용자의 어플리케이션 등인 것을 식별할 수 있도록 회사가 이용자에게 할당하는 고유한 인증 값을 의미합니다.</h6>
            <br/>
            <h6>2.6. ‘플랜’이라 함은 API서비스 이용에 대한 기본적인 결제단위를 의미합니다.</h6>
            <br/>
            <h6><strong>3. API 이용계약의 체결</strong></h6>
            <h6>3.1. 이용계약은 이용자의 이용신청에 대한 회사의 이용승낙 후 성립됩니다. 회사는 이용신청자가 본 약관의 내용을 신청단계에서 반드시 거치도록 공지 또는 게재하며, 이용자의 동의를 받아서 가입처리를 합니다.</h6>
            <br/>
            <h6>3.2. 이용자는 다음 각 호의 서류를 직접 또는 우편이나 팩스 및 온라인 등 회사가 인정하는 방식으로 회사에 제출하여야 합니다.</h6>
            <h6>① 서비스이용계약서</h6>
            <h6>② 요금납입책임자의 사업자등록증사본, 주민등록증사본(세금계산서 발행 필요 시／법인고객가입 확인 시)</h6>
            <h6>③ 기타 예외 사항에 대하여 회사가 필요하여 서비스 이용신청자에게 요청하는 서류</h6>
            <br/>
            <h6>3.3. 회사는 서비스 이용신청자에 대하여 업무 수행에 있어 또는 기술적으로 지장이 없는 경우에는 접수순서에 따라 서비스 이용을 승낙합니다.</h6>
            <br/>
            <h6>3.4. 회사는 이용신청을 승낙한 때에는 다음 각 호의 사항을 서비스 이용신청자에게 전화, 이메일 등의 방법으로 통지합니다. 회사는 이용신청을 승낙한 후 3일 이내에 유·무선으로 통지해야 하며 서비스이용자의 실수로 인한 이메일 계정 불명확 및 타 기간서비스업체의 시스템 불안으로 인하여 통지가 안되었을 경우 책임을 지지 않으며, 서비스 이용자는 회사에 전화, 이메일 등의 방법으로 문의하여야 합니다.</h6>
            <h6>① 요금 등에 관한 사항</h6>
            <h6>② 이용자의 권익보호 및 의무 등에 관한 사항</h6>
            <h6>③ 기타 회사가 필요하다고 인정하는 사항</h6>
            <br/>
            <h6>3.5. 회사는 다음 각 호에 대해서는 승낙하지 아니합니다.</h6>
            <h6>① 비 실명으로 서비스를 신청하는 경우</h6>
            <h6>② 타인 명의를 사용하여 서비스를 신청한 경우</h6>
            <h6>③ 신청서의 내용을 허위로 기재한 경우</h6>
            <h6>④ 타 이용자의 서비스 운영에 지장이 있을 것으로 판단되는 경우</h6>
            <h6>⑤ 회사의 서비스 이용요금을 체납하고 있는 경우</h6>
            <br/>
            <h6>3.6. 회사는 다음 각 호의 경우에는 승낙을 유보할 수 있습니다.</h6>
            <h6>① 천재지변으로 인한 설비의 장애 상태</h6>
            <h6>② 클라이언트 아이디를 생성함에 있어 설비 등의 신설, 개조 또는 수리가 기술상 현저히 곤란한 때</h6>
            <h6>③ 클라이언트 아이디를 생성하는 것이 지극히 곤란하여 회사의 업무수행에 지장이 있다고 인정될 때</h6>
            <h6>④ 서비스를 제공하기 위하여 필요한 설비가 없거나 기 설치된 설비에 여유가 없을 때</h6>
            <h6>⑤ 기타 부득이한 회사 사정으로 이용 승낙이 곤란한 경우</h6>
            <h6>⑥ 이용신청자가 관계법령에서 규정하는 미성년자일 경우</h6>
            <br/>
            <h6>3.7. 회사는 이용신청절차 완료 이후 본 조 제1항 각 호에 따른 사유가 발견된 경우 이용승낙을 철회할 수 있습니다.</h6>
            <br/>
            <h6><strong>4. 약관의 개정</strong></h6>
            <h6>4.1. 회사는 약관규제법 등 관련 법령에 위배되지 않는 범위 안에서 약관을 개정할 수 있으며, 약관 개정 시 적용일자로부터 7일 이전부터 약관의 개정내용 및 적용일자를 서비스 웹사이트를 통해 공지합니다. 다만, 약관이 이용자에게 불리하게 개정되는 경우에는 적용일자로부터 30일 이전부터 공지하고 이용자에게 이메일 등의 수단으로 개별적으로 통지합니다.</h6>
            <br/>
            <h6>5. API서비스의 종류 및 내용</h6>
            <h6>API서비스의 종류 및 내용은 서비스 웹사이트에서 정한 바에 따릅니다.</h6>
            <h6>     https://ai.namisnt.com/subscribe</h6>
            <br/>
            <h6><strong>6. API서비스의 제공의 조건</strong></h6>
            <h6>6.1. 회사는 API서비스를 제공함에 있어서 API서비스의 범위, 이용 가능 시간, 이용 가능 횟수를 지정할 수 있으며, 이와 같은 제공조건은 서비스 웹사이트를 통해 공지되며, 이용자는 그와 같이 정해진 제공조건에 따라서만 API서비스를 이용할 수 있습니다.</h6>
            <br/>
            <h6>6.2. 회사의 API서비스 제공은 별도의 협의가 없는 한, 회사와 이용자의 제휴 관계의 성립을 의미하지 않습니다.</h6>
            <h6><strong>7. 클라이언트 아이디의 발급 </strong></h6>
            <h6>7.1. 이용자는 API 서비스를 이용하기 위해서는 서비스 웹사이트를 통해 클라이언트 아이디를 발급받아야 하며, 이용자는 발급 신청 과정에서 입력해야 하는 정보를 거짓 없이 그리고 정확하게 입력하여야 합니다. 이용자가 입력한 정보가 정확하지 않거나 불충분한 경우 회사는 정보의 수정, 추가적인 정보의 제공을 요청하거나 클라이언트 아이디의 발급을 거부하고, API이용계약 체결을 거절할 수 있습니다.</h6>
            <br/>
            <h6>7.2. 하나의 API서비스에는 하나의 클라이언트 아이디만이 발급될 수 있습니다.</h6>
            <br/>
            <h6>7.3. 회사는 다음과 같은 사실이 확인되는 경우에는 클라이언트 아이디의 발급을 거부하고 API이용계약의 체결을 거절할 수 있습니다.</h6>
            <h6>① 이용자가 입력한 정보 혹은 증빙서류에 허위의 정보가 포함되어 있는 경우</h6>
            <h6>② 이용자가 타인의 ID, 비밀번호, 클라이언트 아이디 등을 도용하려 한 경우</h6>
            <h6>③ 기타 이용자의 귀책사유로 승낙이 불가능하거나 본 약관 및 공지 등을 위반하여 신청하는 경우</h6>
            <br/>
            <h6>7.4. 클라이언트 아이디는 영구적으로 유효하나, 다음과 같은 사유가 있을 경우 해당하지 않습니다.</h6>
            <h6>① 이용자가 API서비스를 해지한 경우</h6>
            <h6>② 회사 혹은 이용자가 어떠한 사유로 인하여 클라이언트 아이디를 변경한 경우</h6>
            <br/>
            <h6><strong>8. 이용자의 권리 및 의무 </strong></h6>
            <h6>8.1. 이용자는 위 API서비스 제공의 조건 등 본 약관에서 허용하는 범위 내에서 API서비스를 사용할 수 있는 제한된 권리를 취득합니다.</h6>
            <br/>
            <h6>8.2. 이용자는 API서비스를 이용함에 있어서 다음과 같은 의무를 부담합니다.</h6>
            <h6>① 이용자는 클라이언트 아이디의 발급 등 이용계약 체결 과정에 있어서 타인의 정보 혹은 허위의 정보를 제출하는 등 회사를 기망하는 행위를 하여서는 안되며, 이용계약 체결 과정에서 고지되는 회사의 정책을 준수하여야 합니다.</h6>
            <h6>② 이용자는 자신이 발급 받은 클라이언트 아이디를 아래와 같이 선량한 관리자의 주의의무로 관리하고 본인만이 사용하여야 합니다.</h6>
            <h6>  이용자는 자신이 발급 받은 클라이언트 아이디를 타인에게 제공, 공개하거나 공유할 수 없습니다.</h6>
            <h6>  이용자는 자신이 발급 받은 클라이언트 아이디가 타인에 의하여 도용당하지 않도록 관리하여야 합니다.</h6>
            <h6>  이용자는 타인이 발급 받은 클라이언트 아이디를 이용해서 API서비스에 접근해서는 안됩니다.</h6>
            <h6>③ 이용자는 아래의 목적으로는 API서비스를 이용할 수 없습니다.</h6>
            <h6>  이용자는 API서비스를 이용함에 있어, API서비스의 결과값 등을 제3자에게 재판매해서는 안됩니다.</h6>
            <br/>
            <h6>8.3. 이용자는 API서비스를 이용함에 있어서 다음과 같은 행위를 하는 것이 금지됩니다.</h6>
            <h6>① API서비스를 피싱 등 불법적인 목적을 위하여 이용하는 행위</h6>
            <h6>② API서비스를 스팸/광고 정보의 작성 및 전송을 위하여 이용하는 행위</h6>
            <h6>③ API서비스를 이용하여 취득한 정보(나미에스엔티 AI 서비스의 컨텐츠 내용 등 일체의 데이터를 포함한다)를 다음의 예시와 같이 본 약관에서 허용한 범위를 넘어서서 무단으로 복제, 저장(캐시 행위 포함), 가공, 배포 등 이용하거나 제3자에게 제공하는 행위</h6>
            <h6>  나미에스엔티 AI 서비스의 정보를 피싱이나 스팸/광고 전송 등을 위해 이용하는 행위</h6>
            <h6>  나미에스엔티 AI 서비스의 정보를 수집하여 별도 데이터베이스로 관리하며 이용하는 행위</h6>
            <h6>  기타 각 API의 제공 취지나 목적에 맞지 않게 API 제공 정보를 저장하거나 이용하는 행위</h6>
            <h6>④ API의 결과화면과 함께 광고를 노출하는 행위</h6>
            <h6>⑤ 데이터를 추출 저장하는 등의 목적으로 동일한 애플리케이션 등이나 동일한 목적으로 서비스 되는 애플리케이션 등에서 다수의 클라이언트 아이디를 사용하여 API를 호출하여 사용하는 행위</h6>
            <h6>⑥ 어플리케이션 등을 통해 API서비스를 다시 제3자에게 제공하는 행위</h6>
            <h6>⑦ API서비스의 제공주체 및 제휴관계 등에 대해서 오인을 받을 수 있는 외관을 형성하는 행위(예를 들면, 이용자를 회사의 임직원 혹은 관계사(관계자)로 사칭하거나 이용자를 API서비스의 제공자로 표시하거나 그와 같은 오인을 할 수   있는 외관을 형성하는 행위 및 이용자와 회사 사이에 제휴 관계가 존재하는 것으로 오인할 수 있는 외관을 형성하는 행위)</h6>
            <h6>⑧ 어플리케이션 등에서 API서비스를 모방하거나, 회사의 브랜드를 모방, 도용하는 행위</h6>
            <h6>⑨ API서비스를 이용하여 나미에스엔티 AI 서비스를 이용하는 자가 의도하지 않는 기능 또는 화면을 추가하는 등 임의로 나미에스엔티 AI 서비스를 변경하는 행위</h6>
            <h6>⑩ 다음과 같은 내용을 포함하는 애플리케이션 등에 API서비스를 적용하는 행위</h6>
            <h6>  정보통신망법 상 불법적인 내용(법 제44조의7 제1항의 각호의 내용)</h6>
            <h6>  청소년보호법 상 청소년유해매체물 혹은 방송통신심의위원회의 인터넷 내용등급기준 2등급 이상의 선정적, 폭력적, 혐오적, 반사회적, 비도덕적, 불법적인 내용</h6>
            <h6>  아동·청소년을 디지털 성범죄의 대상으로 하거나 비인격화하는 내용</h6>
            <h6>  회사 혹은 제3자의 저작권 등 지식재산권을 침해하는 내용</h6>
            <h6>  회사 혹은 제3자의 명예를 훼손하거나 제3자를 모욕하는 내용</h6>
            <h6>  타인의 초상권, 성명권 등 인격권을 침해하는 내용</h6>
            <h6>  개인정보보호법 등에 반하여 타인의 개인정보 또는 사생활을 침해하는 내용</h6>
            <h6>  기타 범죄를 교사하거나 현행법에 위반되는 내용</h6>
            <h6>⑪ 회사의 API서비스를 제공하는 시스템(서버 등)에 자동화 프로그램을 이용하여 접속하거나 접근권한을 넘어서는 접속을 시도하거나 주기적/지속적인 접속을 시도하여 과부하를 발생시키는 등의 방식으로 회사의 정보통신망 및 정보통신시스템에 접근하는 행위</h6>
            <h6>⑫ 애플리케이션 등을 통해 악성코드를 배포하는 행위</h6>
            <h6>⑬ 기타 회사의 업무에 방해가 되는 행위 및 회사의 명예를 손상시키는 행위</h6>
            <br/>
            <h6>8.4. 이용자는 자신의 애플리케이션 등을 이용하는 고객(이하 ‘이용고객’이라 합니다)의 개인정보를 다음과 같이 안전하게 관리 및 보호해야 합니다.</h6>
            <h6>① 이용자는 이용고객의 개인정보를 보호하여야 하며, 애플리케이션 등을 제공함에 있어 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 동법 시행령/시행규칙, 개인정보의 기술적 관리적 보호조치 기준 고시(방송통신위원회 고시), 개인정보보호법 및 동법 시행령/시행규칙, 개인정보의 안전성 확보조치 기준, 기타 관련 법령에 명시된 바에 따라 이용고객의 개인정보를 보호 및 관리하는 등 관계 법령을 준수하여야 합니다.</h6>
            <h6>② 이용자는 이용고객으로부터 동의 받은 이용목적의 범위 내에서만 이용고객의 개인정보를 이용하고, 훼손 · 변조 · 침해하거나 제3자에게 개인정보를 임의로 제공하거나 공개하지 않으며, 이를 위해 개인정보를 취급하는 인력에게 필요한  교육을 시켜야 하며, 개인정보에 접근할 수 있는 인력을 유지해야 합니다.</h6>
            <h6>③ 이용자는 ID 및 Password, 방화벽 등과 같은 접근통제시스템의 설치 및 운영을 통해 접근 권한이 없는 인력이 해당 설비에 접근할 수 없도록 하고, ID 및 Password 등의 공유·누설 금지 및 주기적인 Password 변경과 같은 보안정책을 수립·시행해야 합니다.</h6>
            <h6>④ 이용자는 백신 프로그램을 설치, 운영하여 컴퓨터 바이러스를 방지하고 암호 알고리즘 등의 이용을 통하여 개인정보를 네트워크상에서 안전하게 전송할 수 있도록 보안조치를 취해야 합니다.</h6>
            <h6>⑤ 이용자는 개인정보의 안전성 확보조치 기준 고시 및 개인정보의 기술적, 관리적 보호조치 기준 고시에서 암호화를 요구하는 개인정보에 대해 암호화하여 보관하여야 합니다.</h6>
            <h6>⑥ 이용자는 이용고객의 개인정보의 제공·이용목적이 달성된 경우 지체 없이 파기하여야 합니다.</h6>
            <h6>⑦ 이용자는 나미에스엔티 AI API를 이용하여 서비스를 제공하는 과정에서 이용고객의 개인정보가 회사에 전송되지 않도록 해야 합니다. 단, 서비스 제공과정에서 불가피하게 이용고객의 개인정보가 회사에 제공되어야 하는 경우, 회사의 요청에 따라 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 개인정보보호 관련 법령 준수를 위하여 이용고객에게 개인정보 처리위탁 또는 제3자 제공에 따른 적법한 고지 또는 동의 절차를 거쳐야 하며, 회사가 그와 같은 절차의 이행에 대한 확인을 할 수 있도록 하여야 합니다.</h6>
            <h6>⑧ 이용자는 8.4.의 의무를 위반하여 발생한 모든 법적 책임을 부담하며, 그와 관련하여 회사의 귀책사유가 없었음에도 불구하고 회사를 상대로 법적 클레임이 발생할 경우, 자신의 비용과 노력으로 회사를 면책하고 방어해야 합니다.</h6>
            <br/>
            <h6><strong>9. 회사의 의무</strong></h6>
            <h6>9.1. 회사는 이용자에게 본 약관에 명시된 서비스를 안정적이고 지속적으로 제공해야 합니다.</h6>
            <br/>
            <h6>9.2. 회사는 서비스 운영에 지장이 되는 장애가 발생했을 경우 이를 즉시 수리 또는 복구해야 하며 안정적인 서비스 운영을 위해 최선을 다해야 합니다.</h6>
            <br/>
            <h6>9.3. 회사는 이용자가 정당하게 요구하는 의견이나 불만에 대해서 즉시 또는 회사가 정한 기간과 절차에 따라 처리해야 합니다.</h6>
            <br/>
            <h6>9.4. 회사는 원활한 서비스 신청 및 운영을 위해 이용자의 개인정보를 수집하여 보관하며 본인의 허락 없이는 제3자에게 이를 제공할 수 없습니다. 다만 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구받은 경우나 정보통신윤리위원회의 요청이 있는 경우, 회사의 이용요금을 체납하여 신용정보사업자 또는 신용정보집중기관에 제공하는 경우는 예외로 합니다.</h6>
            <br/>
            <h6>9.5. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.</h6>
            <br/>
            <h6>9.6. 사용자가 서비스를 해지하고자 하는 경우 해당 사용자가 본 약관에 위배되는 사유가 없는 한, 회사는 이를 승낙하여야 하며 신속히 처리해 주어야 합니다.</h6>
            <br/>
            <h6><strong>10. 지식재산권의 귀속 </strong></h6>
            <h6>10.1. 회사가 제공하는 API서비스 및 관련하여 회사가 제작한 프로그램, 디자인, 상표 등의 지식재산권 등 제반 권리는 회사에 귀속되며, API서비스를 이용한 결과 데이터의 저작권 등 제반 권리는 회사 또는 원저작자 등 제3자에게 있습니다.</h6>
            <br/>
            <h6>10.2. 이용자의 API서비스 이용에 대한 회사의 승낙은 API서비스 및 결과 데이터에 대한 본 약관에 따른 제한된 사용권의 부여만을 의미할 뿐, 어떠한 경우에도 (그와 같은 제한된 사용권의 부여를 넘어선) 이용자의 API서비스와 관련된 지식재산권 혹은 관련 권리의 취득 혹은 결과 데이터에 대한 권리의 취득을 의미하지는 않습니다.</h6>
            <br/>
            <h6><strong>11. 서비스의 변경, 중단 및 제한 </strong></h6>
            <h6>11.1. 회사는 회사의 정책 및 운영의 필요상 API 서비스를 변경할 수 있습니다.</h6>
            <br/>
            <h6>11.2. API서비스는 기본적으로 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 다만, 회사는 다음과 같은 경우 API서비스의 전부 혹은 일부를 중단 혹은 이용제한 할 수 있습니다.</h6>
            <h6>① 국가비상사태, 정전 및 그에 준하는 상황이 발생한 경우</h6>
            <h6>② 시스템 정기점검 또는 증설 및 교체, 새로운 API서비스의 적용 등을 위해 필요한 경우</h6>
            <h6>③ API서비스 이용의 폭주 등으로 시스템의 장애가 발생한 경우</h6>
            <h6>④ 기타 회사의 업무상 혹은 기술상 API서비스를 유지하기 어려운 상황이 발생한 경우</h6>
            <h6>11.3. 위 11.1. 및 11.2.에 따라서 API서비스를 변경하거나 중단 혹은 제한할 경우, 회사는 그에 대해서 사전에 서비스 웹사이트를 통해 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사유가 있는 경우에는 사후에 그 사유와 함께 공지합니다. 또한 서비스 웹사이트에 등록된 연락처가 부정확하거나 사용자의 장기 부재로 인해 공지를 받지 못해 발생하는 손실에 대한 책임은 회사가 지지 않습니다.</h6>
            <br/>
            <h6>11.4. 회사는 이용자가 본 약관에서 정한 이용자의 의무(개별 API별 특약조건 포함)를 위반한 것을 확인한 경우 API서비스의 제공을 중단하거나 발급한 클라이언트 아이디의 사용 제한, 정지 혹은 삭제 등 필요한 조치를 취할 수 있으며, 필요할 경우 이용계약을 해지할 수도 있습니다.</h6>
            <br/>
            <h6><strong>12. 회사의 책임의 제한 </strong></h6>
            <h6>12.1. 회사는 이용자가 제공되는 API의 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.</h6>
            <br/>
            <h6>12.2. 회사가 API서비스를 이용하여 제공한 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 이용자의 손해에 대하여는 책임을 지지 않습니다.</h6>
            <br/>
            <h6>12.3. 회사는 이용자 상호 간 및 이용자와 제3자 간에 API서비스를 매개로 발생한 분쟁에 대하여 개입할 의무가 없으며, 해당 분쟁에서의 손해에 대한 배상 책임 또한 지지 않습니다. 만약 이용자와 분쟁 중인 제3자가 회사를 상대로 이의, 또는 소송 등을 제기할 경우 이용자는 자신의 비용과 책임으로 문제를 직접 해결하고 회사를 면책시켜야 하며 회사에 발생한 손해를 배상하여야 합니다.</h6>
            <br/>
            <h6><strong>13. 이용의 제한</strong></h6>
            <h6>13.1. 회사는 이용자의 서비스 이용내용이 다음 각 호에 해당할 경우 서비스 이용을 제한할 수 있습니다.</h6>
            <h6>① 제8조 규정에 의한 이용자의 의무를 위반한 경우</h6>
            <h6>② 타인의 정보 유출, 비밀번호 도용 등의 부정한 행위를 한 경우</h6>
            <h6>③ 관련 법령에 위반되는 행위를 하는 경우</h6>
            <h6>④ 음란하고 선정적인 사진이나 글 등을 게재하거나 공공질서나 미풍양속에 저해되는 행위를 한 경우</h6>
            <h6>⑤ 회사의 승낙 없이 제3자에게 서비스를 제공하는 경우</h6>
            <h6>⑥ 서버에 과도한 요청을 전송하여 과부하를 주는 경우</h6>
            <h6>⑦ 해당 서비스 종류에 명시되어 사전에 공지된 사용범위를 초과하는 경우</h6>
            <h6>⑧ 회사가 제공하는 서비스를 직접 혹은 간접으로 이용하는 장비의 이용에 중대한 지장을 초래한 경우</h6>
            <h6>⑨ 서비스를 범죄에 이용한다고 객관적으로 판단되는 경우</h6>
            <h6>⑩ 이 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위</h6>
            <br/>
            <h6><strong>14. 이용제한 및 해제 절차</strong></h6>
            <h6>14.1. 회사는 제13조(이용의 제한)의 규정에 의하여 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 이메일, 게시판, 서면 또는 전화 등의 방법에 의하여 해당 이용자 또는 대리인에게 24시간 전까지 통지합니다. 다만, 긴급하게 이용을 제한할 필요가 있다고 인정하는 경우에는 그러하지 아니합니다.</h6>
            <br/>
            <h6>14.2. 본조 제1항의 규정에 의하여 이용제한 통지를 받은 이용자 또는 그 대리인은 그 이용제한의 통지에 대하여 이의가 있을 때에는 이의신청을 할 수 있습니다.</h6>
            <br/>
            <h6>14.3. 회사는 본조 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용제한을 일시 연기할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.</h6>
            <br/>
            <h6>14.4. 회사는 이용제한 기간 중에 그 이용제한사유가 해소된 것이 확인된 경우에는 이용제한조치를 즉시 해제합니다.</h6>
            <br/>
            <h6><strong>15. 개인정보의 보호, 로그정보의 보관 및 활용 등 </strong></h6>
            <h6>15.1. 회사는 API서비스를 운영함에 있어 이용자에 대한 각종 고지사항의 전달, 이용실태에 대한 조사 등을 위하여 이용자의 연락처 등 개인정보를 보관합니다.</h6>
            <br/>
            <h6>15.2. 회사는 개인정보 수집 및 이용 동의에 따라 이용자로부터 수집한 정보만을 보관하며, 회사의 개인정보 처리방침(https://ai.namisnt.com/policy/privacy)에 따라 이를 철저히 관리합니다.</h6>
            <br/>
            <h6>15.3. 회사는 API서비스의 제공을 종국적으로 중단하거나 이용자가 개인정보 수집 및 이용 동의를 철회한 경우에는 지체 없이 이용자의 개인정보를 파기합니다.</h6>
            <br/>
            <h6>15.4. 회사는 기타 개인정보보호 법령에 정한 내용을 준수합니다.</h6>
            <br/>
            <h6>15.5. 회사는 API 서비스를 제공함에 따라 발생하는 로그정보(음성인식API로 유입되는 음성정보, 번역API로 유입되는 텍스트, 이미지 인식API로 유입된 이미지 등 이용자가 API로 전송한 정보를 의미합니다)를 최대 3개월간 보관한 후 영구적으로 삭제합니다. 또한 회사는 그와 같이 보관하고 있는 로그정보를 API서비스의 성능 향상을 위해 활용할 수 있습니다.</h6>
            <br/>
            <h6>15.6. 이용자는 회사가 전 항에 따라 로그정보를 적법하게 보관 및 활용할 수 있도록 약관 등을 통해 자신의 이용고객으로부터 이용고객이 이용자의 어플리케이션 등을 이용하는 과정에서 생성되는 정보 중 일부(이용자가 회사의 API 서비스를 이용함에 따라 회사로 제공하는 정보)가 나미에스엔티에 제공, 보관, 활용될 수 있다는 점에 대해서 동의를 얻어야 하며, 회사가 이용자의 이용고객에 대한 동의 획득에 대해서 확인할 수 있도록 하여야 합니다.</h6>
            <br/>
            <h6><strong>16. 위법사항 조사</strong></h6>
            <h6>16.1. 회사는 아래의 각 항과 같이 본 약관에 위배된다고 의심되는 사항들을 조사할 수 있습니다.</h6>
            <h6>① 회사가 이용자의 위법행위 가능성이 상당하다고 인지하는 경우에는 이용자와 관련된 정보 수집 등의 행위를 할 수 있습니다.</h6>
            <h6>② 회사는 만일 위법사항이 발생했다고 판단되면 회사의 독자적인 판단에 의해 그에 따른 책임있는 조치를 취합니다. 이 조치의 내용에는 이용자가 이용 중인 서비스에 대한 일시적 또는 영구적인 접근 차단, 서비스의 제한, 정지 등 모든 조치를 포함합니다.
            <h6>③ 본 약관의 내용을 위반한 이용자는 그에 따른 민ㆍ형사상의 책임을 집니다</h6>
            <br/>
            <h6><strong>17. 이용계약의 해지 </strong></h6>
            <h6>17.1. 회사는 이용자가 약관을 준수하지 않는 경우 API서비스 이용계약을 해지하고 이용자의 API서비스 이용을 중단시킬 수 있습니다.</h6>
            <br/>
            <h6>17.2. 회사는 1년 이상 이용자의 애플리케이션 등에서 API서비스를 이용한 기록이 없을 경우 API 서비스 이용계약을 해지하고 API서비스 제공을 중단할 수 있습니다. 이 경우 회사는 이용계약 해지에 앞서 이용자의 클라이언트 아이디 사용 여부 확인을 위해 회원가입 시 입력한 연락처로 연락을 취하며, 최초 연락일로부터 14일 내 이용자의 응답이 없는  경우에는 이용계약은 해지됩니다. 이용계약 해지 시 회사는 해당 이용자의 개인정보를 즉시 파기합니다. </h6>
            <br/>
            <h6><strong>18. 이용자의 지위승계</strong></h6>
            <h6>18.1. 상속, 합병, 분할, 영업양수 등으로 이용자의 법적 지위의 승계 사유가 발생한 때에는 그 사유가 발생한 날로부터 30일 이내에 사업자등록증사본(개인은 주민등록증사본)과 지위승계를 입증할 수 있는 관련서류를 첨부하여 회사가 지정한 절차에 따라 신청하여야 합니다.</h6>
            <br/>
            <h6><strong>19. 요금의 납입청구</strong></h6>
            <h6>19.1. 이용자가 신용카드로 주문을 한 경우에는 안전한 전자상거래와 결제를 위하여 카드사에서 이용자의 주문정보에 대한 자료를 요청 시 회사는 주문정보를 카드사에 제공할 수 있습니다.</h6>
            <br/>
            <h6>19.2. 이용자는 타인 이름으로 사용료를 입금하는 경우, 이에 대해 사전에 회사에 통보할 의무가 있습니다. 타인 명의 입금에 대한 통지를 하지 않아 생기는 서비스 중단 또는 불이익에 대해서는 이용자가 책임집니다.</h6>
            <br/>
            <h6>19.3. 회사는 요금의 납입책임자가 국가기관, 지방자치단체, 주한외국기관, 재판매업자 등 회사가 인정하는 경우에는 중앙불(상급기관 또는 주된 사업소, 사무소 등에서 일괄하여 지불하는 것을 말합니다)로 일괄하여 납입하게 할 수 있습니다.</h6>
            <br/>
            <h6>19.4. 회사는 미납금을 최근 청구 요금에 합산하여 납입 청구할 수 있습니다</h6>
            <br/>
            <h6><strong>20. 요금의 감면 및 할인</strong></h6>
            <h6></h6>20.1. 회사는 별도로 협의된 이용자 또는 재판매업자 등에 대하여 서비스 요금 중 기본사용료와 추가 이용료를 감면 또는 할인할 수 있으며, 이 경우 적용방법은 회사와 이용자가 합의하여 정합니다.</h6>
            <br/>
            <h6></h6>20.2. 요금감면 및 할인대상자는 자격이 변동된 경우에는 회사에 신고하여야 합니다.
            <br/>
            <h6></h6>20.3. 감면 또는 할인 대상 기준에 부적합한 자가 감면이나 할인을 받은 것으로 판정될 경우에는 감면액 또는 할인액을 환수합니다.
            <br/>
            <h6><strong>21. 통지의 방법 </strong></h6>
            <h6></h6>21.1. 회사는 이용자에게 개별적으로 통지를 해야 하는 경우, 이용자가 등록한 이메일 혹은 휴대전화로 통지합니다.
            <br/>
            <h6></h6>21.2. 이용자는 회사의 통지를 수신할 수 있도록 전자우편주소 및 휴대전화번호 등 회사에 제공한 자신의 정보를 항상 최신으로 유지하여야 하며, 이를 이행하지 않아 이용자에게 발생한 불이익에 대해서 회사는 책임을 부담하지 않습니다.
            <br/>
            <h6><strong>22. 요금의 반환 및 환불</strong></h6>
            <h6>22.1. 회사는 이용자의 이중납부 등 과오납부에 대해서는 이를 반환하고, 회사의 귀책사유로 발생한 경우에는 국내 금융기관 보통예금의 평균금리를 적용하여 계산된 적정이자를 반환하거나 적정이자에 대한 합의가 되지  않을 경우에는  상법상의 법정이율을 부가하여 반환합니다. 또한, 회사의 과소 청구액에 대해서는 다음 주문에서 합산하여 청구합니다.</h6>
            <br/>
            <h6>22.2. 회사는 본조 제1항에 의해 반환하여야 할 요금을 다음에 청구하는 요금에서 상계할 수 있습니다. 이 경우 회사는 그 뜻을 미리 이용자에게 통지합니다.</h6>
            <br/>
            <h6>22.3. 회사는 본 조 제1항 및 본 조 제2항의 규정에 의하여 요금을 반환하여야 할 이용자에게 요금 등의 체납이 있을 경우에는 반환하여야 할 요금에서 이를 우선 공제하고 반환합니다.</h6>
            <br/>
            <h6>22.4. 이용요금을 선납한 이용자가 서비스 이용 중에 중도해지를 하는 경우에 이용요금의 정산은 이용요금을 결제 요금 기준으로 일할 계산한 후, 선납한 이용요금에서 사용금액을 제외한 잔액을 신청일로부터 15일 이내에 환불합니다. 다만,  이용자의 의사 또는 이용자의 귀책사유에 의하여 중도해지를 하는 경우에는 위 잔액의 85%를 정산하여 환불하고,       환불되지 않는 15%는 이용자가 이용자의 귀책사유로 서비스를 해지한 위약금으로 처리할 수 있습니다.</h6>
            <br/>
            <h6>22.5 기 이용자가 서비스 이용계약을 해지할 경우, 회사는 계약 시 할인율을 고려하여 반환합니다. 이 때 별도의 계약이 없는 일반적인 경우 사용료 잔여분의 75%를 환불합니다.</h6>
            <br/>
            <h6><h6><strong>23. 면탈요금의 징수</strong></h6>
            <h6>23.1. 회사는 이용자가 이 약관의 규정을 위배하여 서비스 이용요금을 면탈하는 행위를 한 경우 면탈한 금액의 2배에 해당하는 금액을 청구할 수 있습니다.</h6>
            <br/>
            <h6>23.2. 회사는 본조 제1항의 규정에 의하여 면탈요금을 청구하는 경우에 그 사유를 명시하여 통지합니다.</h6>
            <br/>
            <h6><strong>24. 손해배상 책임의 범위</strong></h6>
            <h6>24.1. 회사는 회사의 명백한 귀책사유로 이용자가 서비스를 이용하지 못하는 경우에는, 이용자가 그 사실을 회사에 통보하여 확인한 때(또는 그 전에 회사가 그 사실을 알았거나 알 수 있게 된 때)로부터 계속 4시간 이상의 서비스 제공  중지에 대해, 서비스 제공 중지시간의 3배를 기준으로 이용중인 플랜의 유효기간을 연장하여 배상합니다. 이 경우 단수가 1시간 미만인 경우에는 1시간으로 합니다.</h6>
            <br/>
            <h6>24.2. 회사는 그 손해가 다음 각 호에 해당하는 경우에는 손해배상을 아니합니다.</h6>
            <h6>① 전시. 사변. 천재지변 또는 이에 준하는 국가비상사태 등 불가항력적인 경우</h6>
            <h6>② 이용자의 고의 또는 과실로 인하여 발생한 경우</h6>
            <h6>③ 전기통신사업법에 의한 회사 외 타 기간통신사업자가 제공하는 전기통신서비스 장애 및 설비 부족으로 인한 경우</h6>
            <h6>④ 전기통신서비스의 특성상 불가피한 사유로 서비스 제공이 불가능한 경우</h6>
            <br/>
            <h6><strong>25. 이용자의 손해배상 청구</strong></h6>
            <h6>25.1. 손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 서면으로 하여야 합니다.</h6>
            <br/>
            <h6>25.2. 본 조 제1항의 손해배상 청구는 그 청구사유를 안 날로부터 3개월 내에 행사하지 아니하면 그 청구권은 소멸합니다. 청구사유가 발생한 날로부터 1년이 경과한 때에도 이와 같습니다.</h6>
            <br/>
            <h6><strong>26. 이용자에 대한 손해배상 청구</strong></h6>
            <h6>26.1. 이용자가 제8조(이용자의 권리 및 의무)에서 규정한 의무사항을 위반하여 회사에 손해를 끼친 경우에 회사는 해당 이용자에 대해 손해배상을 청구할 수 있습니다.</h6>
            <br/>
            <h6>26.2. 회사가 이용자에 대해 손해배상을 청구하는 경우의 절차에 대해서는 제25조(이용자의 손해배상 청구)의 규정을 준용합니다.</h6>
            <br/>
            <h6><strong>27. 양도의 금지 </strong></h6>
            이용자는 본 약관에 의해 체결된 계약 상 권리 및 의무 등 계약 상 지위를 회사의 동의 없이는 제3자에게 양도할 수 없습니다.</h6>
            <br/>
            <h6><strong>28. 준거법 및 관할법원 </strong></h6>
            <h6>본 약관의 준거법은 대한민국의 법으로 하며, API서비스 이용으로 회사와 이용자 간에 발생한 분쟁에 대해 소송이 제기되는 경우 민사소송법에 따른 법원이 관할법원이 됩니다.</h6>
            <br/>
            <br/>
            <h5><strong>나. 개별 API별 특약조건</strong></h5>
            <h6>하기의 특약조건은 명시된 각각의 API서비스에 대하여 추가적으로 적용되며, 일반조건과 배치되는 부분이 있을 경우 특약조건이 우선하여 적용됩니다.</h6>
            <br/>
            <h6><strong>1. 자연어처리 API 서비스</strong></h6>
            <h6>1.1 ‘자연어처리 API 서비스’라 함은 나미에스엔티 AI 서비스의 자연어처리 신경망 모델을 이용하여 단어쌍의 유사도 비교, 특정 단어의 유사어를 제공하는 서비스를 말합니다.</h6>
            <h6>1.2 이용자는 자연어처리 API 서비스를 이용할 때에, 타인의 지적재산권을 침해하지 않도록 유의해야 하며, 이를 지키지 않아 발생한 문제에 대하여 회사는 책임지지 않습니다.</h6>
            <h6>1.3 회사는 이용자가 입력한 문자데이터를 저장할 수 있으며, 이를 연구목적으로 활용할 수 있습니다. 단, 해당 자료에 포함된 개인정보는 제외합니다.</h6>
            <h6>1.4 회사는 이용자가 입력한 문자데이터를 이용자의 동의없이 제3자에게 전송하거나, 공개해서는 안됩니다.</h6>
            <br/>
            <h6><strong>2. 이미지 배경제거 API 서비스</strong></h6>
            <h6>2.1 이미지 배경제거 API 서비스’라 함은 나미에스엔티 AI 서비스의 이미지 배경제거 신경망 모델을 이용하여 입력받은 이미지의 배경을 제거하는 서비스를 말합니다.</h6>
            <h6>2.2 이용자는 이미지 배경제거 API 서비스를 이용할 때에, 타인의 지적재산권을 침해하지 않도록 유의해야 하며, 이를   지키지 않아 발생한 문제에 대하여 회사는 책임지지 않습니다.</h6>
            <h6>2.3 회사는 이용자가 입력한 이미지를 저장할 수 있으며, 이를 연구목적으로 활용할 수 있습니다. 단, 해당 자료에 포함된 개인정보는 제외합니다.</h6>
            <h6>2.4 회사는 이용자가 입력한 이미지데이터를 이용자의 동의없이 제3자에게 전송하거나, 공개해서는 안됩니다.</h6>
            <br/>
            <h6><strong>3. 광학 문자 인식(Optical Character Recognition) API 서비스</strong></h6>
            <h6>3.1 광학문자인식 API 서비스라 함은 나미에스엔티 AI 서비스의 광학문자인식(OCR) 신경망 모델을 이용하여 입력받은  이미지에 포함된 글자내용을 문자데이터로 변환하는 서비스를 말합니다.</h6>
            <h6>3.2 이용자는 광학문자인식 API 서비스를 이용할 때에, 타인의 지적재산권을 침해하지 않도록 유의해야 하며, 이를 지키지 않아 발생한 문제에 대하여 회사는 책임지지 않습니다.</h6>
            <h6>3.3 회사는 이용자가 입력한 문자데이터를 저장할 수 있으며, 이를 연구목적으로 활용할 수 있습니다. 단, 해당 자료에   포함된 개인정보는 제외합니다.</h6>
            <h6>3.4 회사는 이용자가 입력한 이미지데이터를 이용자의 동의없이 제3자에게 전송하거나, 공개해서는 안됩니다.</h6>
            <br/>
            <h6>&lt;부칙&gt; (시행일) 본 약관은 2023년 6월 7일부터 적용됩니다.</h6>
        </div>
    );

}
export default Agreement_body;
import React, {useState} from "react";

function Admin_header(props){
    
    return (
        <nav className="navbar navbar-dark bg-dark">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" onClick={() =>{props.handleSidebarToggle();}}>
                <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    );
}

export default Admin_header;
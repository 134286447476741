import React from "react";
import '../../../css/subcribe_price.css';
import SubcribePrice_Calc from "./subcribe_price_calc";
import { Breadcrumb, Button, Col, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SubcribePrice_Calc_BGRM from "./subcribe_price_calc_bgrm";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loading from "../../../common/Loading";
import { useEffect } from "react";
import SubcribePrice_Calc_TTS from "./subcribe_price_calc_tts";


function SubcribePrice(){
    const navigate = useNavigate();
    const props = useOutletContext();   
    const [loading, setLoading] = useState(false);
    const [viewPlan, setViewPlan] = React.useState(0);
    
    const [payplans, setPayplans] = useState([]);
    const createOrder = ()=>{
        setLoading(true);
        axios.post('/rest/createOrder',{
                payplans : payplans,
            }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                if(response.data.result == 'success'){ 
                    //TODO : 주문처리
                    navigate("/order");
                }else{
                    if(response.data.msg=='로그인이 필요합니다.'){
                        props.setLoginShow(true);
                        setLoading(false);
                        return;
                    }
                    //TODO : 카트 추가 실패 메시지
                    alert("주문처리에 실패하였습니다. 고객센터에 문의해주세요.");
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    
    
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          준비중입니다.. 조금만 기다려 주세요!
        </Tooltip>
      );

    useEffect(()=>{if(payplans.length > 0){ createOrder();}}, [payplans]);

    return(
        <>
            {loading == true ? <Loading/> : ""}
            <Breadcrumb className='section-content'>
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    구독/플랜
                </Breadcrumb.Item>
            </Breadcrumb>
            <section className="section-content mb-4 subcribe-price-section">
                <div className="text-center">
                    <a href="https://smartstore.naver.com/namisnt/category/46c00c90cb87460da7c6204f50762d92" target="_blank">
                        <img className="w-md-50 w-100 my-3" src={process.env.PUBLIC_URL + '/assets/img/smartstore_coupon.png'} />
                    </a>
                </div>
                <Row>
                    <Col lg={3} className="px-1">
                        <div className="subcribe-price text-center">
                            <strong>배경제거 AI</strong>
                            <ListGroup variant="flush">
                                <ListGroup.Item>딥러닝을 이용한 높은 정확도</ListGroup.Item>
                                <ListGroup.Item>많은 이미지를 동시에 처리가능</ListGroup.Item>
                                <ListGroup.Item>월등히 저렴한 가격</ListGroup.Item>
                                <ListGroup.Item>부드러운 모서리 패딩 처리</ListGroup.Item>
                            </ListGroup>
                            <Button variant="outline-primary" className="w-100" onClick={e => setViewPlan(2)}>구독플랜 알아보기</Button>
                        </div>
                    </Col>
                    <Col lg={3} className="px-1">
                        <div className="subcribe-price text-center">
                            <strong>자연어처리 AI</strong>
                            <ListGroup variant="flush">
                                <ListGroup.Item>AI 개발에 필요한 효과적인 모델 제공</ListGroup.Item>
                                <ListGroup.Item>기본 라이브러리보다 빠른 초고속연산</ListGroup.Item>
                                <ListGroup.Item>대량처리 가능</ListGroup.Item>
                                <ListGroup.Item>풍부한 어휘</ListGroup.Item>
                            </ListGroup>
                            <Button variant="outline-primary" className="w-100" onClick={e => setViewPlan(1)}>구독플랜 알아보기</Button>
                        </div>
                    </Col>                    
                    <Col lg={3} className="px-1">
                        <div className="subcribe-price text-center">
                            <strong>음성합성 AI</strong>
                            <ListGroup variant="flush">
                                <ListGroup.Item>가격대비 준수한 음성품질</ListGroup.Item>
                                <ListGroup.Item>인공지능 기반 음성합성</ListGroup.Item>
                                <ListGroup.Item>글자를 목소리로 변환</ListGroup.Item>
                                <ListGroup.Item>월등히 저렴한 가격</ListGroup.Item>
                            </ListGroup>
                            <Button variant="outline-primary" className="w-100" onClick={e => setViewPlan(3)}>구독플랜 알아보기</Button>
                        </div>
                    </Col>
                    <Col lg={3} className="px-1">
                        <div className="subcribe-price text-center">
                            <strong>OCR AI</strong>
                            <ListGroup variant="flush">
                                <ListGroup.Item>빠른 시일 내에 서비스 예정입니다.</ListGroup.Item>
                                <ListGroup.Item>신경망 기반 인공지능</ListGroup.Item>
                                <ListGroup.Item>이미지를 글자로 변환</ListGroup.Item>
                                <ListGroup.Item>월등히 저렴한 가격</ListGroup.Item>
                            </ListGroup>
                            <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <Button variant="outline-primary" className="w-100" /*onClick={e => setViewPlan(3)}*/>구독플랜 알아보기</Button>
                            </OverlayTrigger>
                        </div>
                    </Col>
                </Row>
                <Row className=" mt-4"  style={{"border":0}}>
                    {viewPlan== 1 &&
                    <SubcribePrice_Calc payplans={payplans} setPayplans={setPayplans} createOrder={createOrder} /> }
                    {viewPlan== 2 &&
                    <SubcribePrice_Calc_BGRM  payplans={payplans} setPayplans={setPayplans} createOrder={createOrder}/> }
                    {viewPlan== 3 &&
                    <SubcribePrice_Calc_TTS  payplans={payplans} setPayplans={setPayplans} createOrder={createOrder}/> }
                    {viewPlan== 4 &&
                    'VIEW{3}' }
                </Row>
            </section>
        </>
    );
}

export default SubcribePrice;
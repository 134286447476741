import React from "react";


function Cost(){
    return(
    <>
        <section className="section-content mb-4 subcribe-price-section">
            <div className="policy-header mb-4">
                <h4><strong>점검·수리비 안내</strong></h4>
                <h6></h6>
            </div>
            <div className="policy-body text-center">
                <img className="w-100 w-md-60 m-auto" src="https://file.namisnt.com/docu/PC%EC%A0%90%EA%B2%80%EA%B3%B5%EC%9E%84230206.png"/>
            </div>
        </section>
    </>
    );

}

export default Cost;
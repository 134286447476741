import React, {useState, useEffect} from "react";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading.js";
import ListSearch from "../../listSearch.js";
import PageSelector from "../../page_selector.js";
import { ResponsiveLine } from '@nivo/line'

function VisitorLog(){
    const navigate = useNavigate();
    const [title, setTitle] = useState("접속자 로그/통계 조회");
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [weekstatistics, setWeekStatistics] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const [serviceType, setServiceType]= useState([]);
    const [searchFilter, setSearchFilter] = useState([{val:"user_agent", name:"접속기기"},{val:"user_ip", name:"접속IP"},{val:"referer", name:"Referer"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [query, setQuery]= useState("");

    const PageHandler = (page)=>{
        setPage(()=>{return page});
        //requestList(search_kind, query);
    }
    

    const requestList = (q_type, q) =>{
        setLoading(true);

        axios.get('/rest/admin/getVisitorList', 
            {params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                let week_sta = [{"id":"방문자수", "color":"hsl(66, 70%, 50%)","data":[]}];
                setListData(() => {return response.data.list});
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
                for(var i=0; i< response.data.week_statistics.length; i++){
                    week_sta[0]['data'].push({"x":response.data.week_statistics[i].date, "y":response.data.week_statistics[i].cnt});
                }
                setWeekStatistics(()=>{return week_sta});
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }

    useEffect(()=>{}, [search_kind, query]);
    //
    useEffect(() => {
        requestList(search_kind,query);
      },[page]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <div className={`content card-shadow`}>
            <div>
                <h5><strong>{title}</strong></h5>
            </div>
            <div style={{ width: 'auto', height: '400px', margin: '0 auto' }}> 
            <ResponsiveLine
                data={weekstatistics}
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '날짜',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'count',
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
            />
            </div>
            <ListSearch goSearch={requestList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
            <div>
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div style={{overflowX:'Auto'}}>
                <Table striped bordered hover size="sm" style={{minWidth:'1300px'}} >
                    <thead>
                        <tr>
                            <th>순서</th>
                            <th>접속IP</th>
                            <th>접속기기</th>
                            <th>Referer</th>
                            <th>접속일시</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map(function(a, idx){
                            return (
                                <tr key={idx}>
                                    <td>{idx+1}</td>
                                    <td>{a.user_ip}</td>
                                    <td><span  style={{overflow:"hidden", maxWidth:"500px", textOverflow:"ellipsis", wordBreak:"break-all", whiteSpace:"nowrap", display:"block"}}>{a.user_agent}</span></td>
                                    <td>{a.referer}</td>
                                    <td>{a.regist_date}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        </>
    );

}

export default VisitorLog;
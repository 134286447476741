import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Card, Container, FloatingLabel, InputGroup, ListGroup } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Modal_alert from "../../modal.js";

function RegisterQna(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("1:1문의 수정");
    const [loading, setLoading] = useState(false);
    const [qna_type, setQnaType] = useState([{type:"spec", text:"사양문의"}, {type:"as", text:"A/S관련 문의"}, {type:"etc", text:"기타 문의"}]);
    const [qna_status, setQnaStatus] = useState([{status:"ready", text:"접수"}, {status:"process", text:"처리중"}, {status:"finished", text:"처리완료"}]);
    
    const [data, setData] = useState({uid:0, member_uid:0, member_name:"", qna_type:'spec', qna_status:'ready', msg:'', created_date:"", answers:[]});

    const [answerData, setAnswerData] = useState({uid:0, qna_uid:0, msg:""});

    const [listData, setListData] = useState([]);
    const [btnText, setBtntext] = useState("수정");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [regist_show, setRegistShow] = useState(false);
    const [regist_complete_show, setRegistCompleteShow] = useState(false);
    const [regist_failed_show, setRegistFailedShow] = useState(false);
    const [update_show, setUpdateShow] = useState(false);
    const [update_complete_show, setUpdateCompleteShow] = useState(false);
    const [update_failed_show, setUpdateFailedShow] = useState(false);
    const [delete_show, setDeleteShow] = useState(false);
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);
    const [delete_failed_show, setDeleteFailedShow] = useState(false);
    const [validated, setValidated] = useState(false);


    const handleChange = e => {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        })
      }
      const handleAnswerChange = e => {
        setAnswerData({
          ...answerData,
          [e.target.name]: e.target.value,
        })
      }
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/QnaList");
    };
    const handleShow = () => setShow(true);
    const getQnaStatusIndex = (status)=>{
        let res_idx = -1;
        qna_status.map(function(item, idx){
            if(item.status == status){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }
    const getQnaTypeIndex = (type)=>{
        let res_idx = -1;
        qna_type.map(function(item, idx){
            if(item.type == type){
                res_idx  = idx;
                return;
            }
        });
        return res_idx;
    }


    const getQnaInfo = () =>{
        if((location.state != null ? location.state.uid : 0) <= 0)
            return;
        setLoading(()=>{return true});
        axios.get('/rest/admin/getQnaInfo',
        { params : {uid : (location.state != null ? location.state.uid : 0)}}).then(response =>{
            setData(response.data.data);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }

    const regist = () =>{
        setLoading(true);
        axios.post('/rest/admin/registerQnaAnswer',answerData
        ).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("문의 답변 등록 성공");
                    setModalContent("문의 답변 등록에 성공하였습니다.");
                    setRegistCompleteShow(true);
                    setAnswerData({uid:0, qna_uid:data.uid, msg:""});
                }else{
                    setModalTitle("문의 답변 등록 실패");
                    setModalContent("문의 답변 등록에 실패하였습니다.");
                    setRegistFailedShow(true);
                }
                //handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const update = () =>{
        setLoading(true);
        axios.post('/rest/admin/updateQna',
        { 
            uid:location.state.uid,
            qna_type:data.qna_type,
            qna_status:data.qna_status
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("1:1 문의 수정 성공");
                    setModalContent("1:1 문의 수정에 성공하였습니다.");
                    setUpdateCompleteShow(true);
                }else{
                    setModalTitle("1:1 문의 수정 실패");
                    setModalContent("1:1 문의 수정에 실패하였습니다.");
                    setUpdateFailedShow(true);
                }
                //handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }

    const delete_answer = (answer_uid) =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deleteQnaAnswer',
        { params : {uid : answer_uid}}).then(response =>{
            if(response.data.result == 'success'){
                setDeleteCompleteShow(true);
                getQnaInfo();
            }else{
                setDeleteFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    useEffect(() => {
        if(data != undefined && data != null)
            setAnswerData({...answerData, qna_uid:data.uid});
      },[data]);
      useEffect(() => {
      },[data.answers]);
    useEffect(() => {
        getQnaInfo();
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>문의자명</Form.Label>
                                    <Form.Control required type="text" name="name" defaultValue={data.member_name} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>문의유형</Form.Label>
                                    <Form.Select name="qna_type" value={data.qna_type} onChange={handleChange} aria-label="문의유형 선택">
                                        {qna_type.map(function(item, idx){
                                            return (<option key={idx} value={item.type}>{item.text}</option>);
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>진행상태</Form.Label>
                                    <Form.Select name="qna_status" value={data.qna_status} onChange={handleChange} aria-label="진행상태 선택">
                                        {qna_status.map(function(item, idx){
                                            return (<option key={idx} value={item.status}>{item.text}</option>);
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label>문의내용</Form.Label>
                                    <div>
                                        {data.msg}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div style={{overflowX:'Auto'}}>
                            <Form.Label>답변 목록</Form.Label>
                                <Card body className="mb-3">
                                    <Container>
                                        <FloatingLabel controlId="floating-qna" label="답변내용">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="답변내용을 남겨주세요."
                                                style={{ height: '100px' }}
                                                name="msg"
                                                value={answerData.msg}
                                                onChange={(e)=>{handleAnswerChange(e);}}
                                            />
                                        </FloatingLabel>
                                        <div className="py-3">
                                            <Button className="float-end"  onClick={()=>{setRegistShow(true);}}>등록</Button>
                                        </div>
                                    </Container>
                                </Card>
                                {    data.answers.map(function(a,i){
                                        return(
                                            <Card body key={i}>
                                                <Container>
                                                    {a.msg}
                                                    <Container className="text-end">
                                                        {a.created_date}
                                                        <Button>수정</Button>
                                                        <Button variant="danger" onClick={(e)=>{delete_answer(a.uid);}}>삭제</Button>
                                                    </Container>
                                                    
                                                </Container>
                                            </Card>
                                        );
                                    })
                                }
                        </div>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            <Col className="text-end">
                                {location.state != null && location.state.uid > 0 ? 
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setUpdateShow(true)}}>문의내용수정</Button>
                                    :
                                    ""
                                }
                                
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={regist_show} handleClose={()=>{setRegistShow(false)}} handleSubmit={()=>{setRegistShow(false);regist();}} modal_title="" content={`답변을 등록하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={regist_complete_show} handleClose={()=>{setRegistCompleteShow(false); getQnaInfo();}} modal_title="답변등록완료" content="답변이 등록되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={regist_failed_show} handleClose={()=>{setRegistFailedShow(false);}} modal_title="답변등록실패" content="답변등록에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
                <Modal_alert show={update_show} handleClose={()=>{setUpdateShow(false)}} handleSubmit={()=>{setUpdateShow(false); update()}} modal_title="" content={`문의내용을 수정하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={update_complete_show} handleClose={()=>{setUpdateCompleteShow(false); navigate("/admin/QnaList")}} modal_title="문의내용수정완료" content="문의내용이 수정되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={update_failed_show} handleClose={()=>{setUpdateFailedShow(false);}} modal_title="문의내용수정실패" content="문의내용 수정에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={delete_complete_show} handleClose={()=>{setDeleteCompleteShow(false);}} handleSubmit={()=>{setDeleteCompleteShow(false);}} modal_title="답변삭제성공" content="답변삭제에 성공하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={delete_failed_show} handleClose={()=>{setDeleteFailedShow(false);}} modal_title="답변삭제실패" content="답변삭제에 실패하였습니다." primary_btn={primary_btn} />
            </div>
        </>
        );

}

export default RegisterQna
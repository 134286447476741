import React, {useState, useEffect} from "react";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading.js";
import ListSearch from "../../listSearch.js";
import PageSelector from "../../page_selector.js";


function ArticleList(){
    const navigate = useNavigate();
    const [title, setTitle] = useState("게시글 조회/수정");
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [page, setPage]  = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const [searchFilter, setSearchFilter] = useState([{val:"title", name:"제목"}]);
    const [search_kind, setSearchKind] = useState(searchFilter[0].val);
    const [equipmentType, setEquipmentType]= useState([]);
    const [query, setQuery]= useState("");

    const [board_info, setBoardInfo] = useState([{uid: 4, board_code:"news", board_name:"최신소식"}, {uid: 5, board_code:"promotion", board_name:"홍보자료"}, {uid: 6, board_code:"articles", board_name:"관련기사"}])


    const PageHandler = (page)=>{
        setPage(page);
        //requestList(search_kind, query,page);
    }
    const getBoardTypeIndex = (uid)=>{
        let res_idx = -1;
        board_info.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        console.log("idx : "+res_idx);
        return res_idx;
    }
    const requestList = (q_type, q) =>{
        setLoading(true);
        axios.get('/rest/admin/getBoardArticleList', 
            {params:
            {
                page : page-1,
                page_limit : pagelimit,
                q_type : q_type,
                query : q,
                board_code:null

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                let totalSize = response.data.count;
                let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setListData(list => response.data.list);
                setTotalSize(totalSize);
                setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(()=>{}, [search_kind, query]);
    //
    useEffect(() => {
        requestList(search_kind, query);
      },[page]);

    return(
        <>
        {loading == true ? <Loading/> : ""}
        <div className={`content card-shadow`}>
            <div>
                <h5><strong>{title}</strong></h5>
            </div>
            <ListSearch cnt={totalSize} goSearch={requestList}  search_kind={search_kind} setSearchKind={setSearchKind} query={query} setQuery={setQuery} filter={searchFilter}/>
            <div>
                <span>총 <strong>{totalSize}개</strong>의 결과가 있습니다.</span>
            </div>
            <div style={{overflowX:'Auto'}}>
                <Table striped bordered hover size="sm" style={{minWidth:'1300px'}} >
                    <thead>
                        <tr>
                            <th>순서</th>
                            <th>게시판</th>
                            <th>제목</th>
                            <th>등록일자</th>
                            <th>세부정보</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map(function(a, idx){
                            return (
                                <tr key={idx}>
                                    <td>{idx+1}</td>
                                    <td>{board_info.length > 0 && a.article_info.board_uid != null ? board_info[getBoardTypeIndex(a.article_info.board_uid)].board_name : ""}</td>
                                    <td>{a.article_info.title}{a.article_info.is_notice ? " (공지)" : ""}</td>
                                    <td>{a.article_info.created_date}</td>
                                    <td><button type="button" onClick={()=>{navigate('/admin/registerArticle', {state: {uid:a.article_info.uid}})}} className="btn btn-primary">조회</button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <PageSelector curPage={page} totalPage={totalPage} page_limit={pagelimit} PageHandler_={PageHandler}/>
        </div>
        </>
    );

}

export default ArticleList;
import React from "react"
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
//import '../../css/bootstrap_5.1.3.css'
import  styles from '../../css/admin/style.module.css'
import  login_styles from'../../css/admin/login_page.module.css'

import axios from "axios"
import { useState } from "react"
import Modal_alert from "../../common/modal";

function Login(){
    const navigate = useNavigate();
    const [data_, setData] = useState({id:"", pw:""});
    const [modal_title, setModalTitle] = useState("로그인 실패");
    const [modal_content, setModalContent] = useState("로그인에 실패하였습니다. 입력하신 계정정보를 확인하십시오.");
    const [secondary, setSecondary] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleInputIdPw = e => {
        const  {name, value} = e.target;
        setData({...data_, [name]:value});
    }
	const Login_func = (e) =>{
		e.preventDefault();
		axios.post('/rest/admin/login',
        {
            id: data_.id,
            pw: data_.pw
        }).then(response => {
            if(response.data.result == 'success'){
                navigate("/admin/dashboard");
            }else{
                handleShow(true);
            }
        });
        
	};
    return (
        <div id = {`${login_styles.content} m-auto`}>
			<div id={login_styles.login_section} className=" border-top border-bottom bg-light shadow">
				<form onSubmit={Login_func} name="loginform">
						<div className="title middle text-center"><img  className="w-md-50" src={process.env.PUBLIC_URL + '/assets/img/logo_footer.png'} alt="로고"></img></div>
					<div className="title middle">로그인</div>
					<div>
						<div className="form-group">
   					 <label htmlFor="id_input">아이디</label>
    				<input type="text" className="form-control" name="id" id="id_input" aria-describedby="emailHelp" placeholder="아이디 입력" value={data_.id} onChange={handleInputIdPw}></input>
 					 </div>
 				 <div className="form-group">
					 	<label htmlFor="pw_input">비밀번호</label>
  		  <input type="password" className="form-control" name="pw" id="pw_input" placeholder="비밀번호"value={data_.pw} onChange={handleInputIdPw}></input>
  				</div>
							</div>
  				<div className="form-check login">
  				  <input type="checkbox" className="form-check-input" id="saveID"></input>
    				<label className="form-check-label" htmlFor="saveID">아이디 저장</label>
 				 </div>
 				 <div className="loginbt mt-4 container px-0">
 				 	<div className="row">
 				 		<div className="col-md-4">
  							<button type="submit" id="loginbt" className="btn btn-primary w-md-auto w-100" data-bs-toggle="modal" data-bs-target="#failedModal" >로그인</button>
  						</div>
  						<div className="col-8 mx-md-0 mx-auto">
	  						<label className="form-check-label float-end login_middle px-0" htmlFor="findID"><a href="findpw.do"  className="text-secondary">비밀번호찾기</a></label>
	  						<label className="form-check-label float-md-end mr-md-2 mr-0 px-0 login_middle" htmlFor="findID"><a href="findid.do"  className="text-secondary">아이디찾기</a></label>
 						</div>
  					</div>
  				</div>
				</form>
		</div>
        <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
	</div>
    );


}

export default Login;
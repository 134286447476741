import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loading from "../../../../common/Loading";
import axios from "axios";

function Receipt_Form(){

    let params = useParams();
    const [loading, setLoading] = useState(false);
    const [method] = useState({cash: "현금/무통장", creditcard:"신용/체크카드", coupon:"쿠폰결제", virtual_account:"가상계좌"});
    const [receipt_info, setReceiptInfo] = useState({orderitem:[], payments:[]});

    const requestReceiptInfo = () =>{
        setLoading(true);
        axios.get('/rest/getReceiptInfo',
            {params:{
                order_id : params.order_id
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                alert("영수증정보를 가져오는데 실패했습니다.");
                //window.close();
            }else{
                setReceiptInfo(response.data.data);
            }
            setLoading(false);
        }).catch(
            error=>{
                alert("영수증정보를 가져오는데 실패했습니다.");
                //window.close();
                console.log(error);
            }
        );
    }

    useEffect(()=>{
        requestReceiptInfo();
    }, []);

    return(
        <>
            {loading == true ? <Loading/> : ""}
            <div className="bg-white p-3" style={{width:"700px", height:"989px"}}>
                <Row className="text-center mt-3 mb-5">
                    <h3 className="fw-bold">거래명세서(영수증)</h3>
                </Row>
                <Row style={{fontSize:"0.75rem"}}>
                    <Col className="p-0" style={{width:"292px"}}>
                        <Table  bordered size="sm">
                            <tbody>
                                <tr>
                                    <th>주문번호</th>
                                    <td>{receipt_info.order_id}</td>
                                    <th>주문일자</th>
                                    <td>{receipt_info.ordered_date}</td>
                                </tr>
                                <tr>
                                    <th>주문자명</th>
                                    <td colSpan={3}>{receipt_info.orderer_name}</td>
                                </tr>
                                <tr>
                                    <th>결제방법</th>
                                    <td>{method[receipt_info.payment_method]}</td>
                                    <th>입금자명</th>
                                    <td>{receipt_info.depositor}</td>
                                </tr>
                                <tr>
                                    <th>합계금액</th>
                                    <td colSpan={3}>{receipt_info.total_fee}원</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col className="pe-0"  style={{width:"400px"}}>
                        <Table bordered size="sm">
                            <tbody>
                                <tr>
                                    <th rowSpan={4} className="text-center" style={{writingMode:"vertical-lr"}}>공 급 자</th>
                                    <th style={{width:"100px"}}>사업자번호</th>
                                    <td style={{width:"120px"}}>762-10-01066</td>
                                    <th colSpan={2}>고객센터 1588-7397</th>
                                </tr>
                                <tr>
                                    <th>상호</th>
                                    <td>나미에스엔티</td>
                                    <th style={{width:"60px"}}>대표자</th>
                                    <td>김노산,이상석</td>
                                </tr>
                                <tr>
                                    <th>소재지</th>
                                    <td colSpan={3}>서울특별시 강동구 천호대로 1073, B226호</td>
                                </tr>
                                <tr>
                                    <th>업태</th>
                                    <td>서비스</td>
                                    <th>종목</th>
                                    <td className="px-0" style={{width:"150px", fontSize:"0.5rem"}}>소프트웨어개발및공급</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row style={{fontSize:"0.75rem"}}>
                    <h6 className="mt-3 p-0"><strong>주문내역</strong></h6>
                    <Table striped hover size="sm" >
                        <thead>
                            <tr>
                                <th>플랜이름</th>
                                <th>서비스유형</th>
                                <th>지급 크레딧</th>
                                <th>주문수량</th>
                                <th>주문금액</th>
                            </tr>
                        </thead>
                        <tbody>
                        {receipt_info.orderitem.map(function(item, idx){
                            return(<tr key={idx} className="border-bottom lh-lg">
                                <td>
                                    {item.plan_name}
                                </td>
                                <td>
                                    {item.service_name}
                                </td>
                                <td>
                                    {item.credit_size}개
                                </td>
                                <td className="col-lg-1">
                                        <strong>{item.ea}</strong>
                                </td>
                                <td>
                                    <strong>{item.fee}원</strong>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </Table>
                </Row>
                <Row className="mt-3"  style={{fontSize:"0.75rem"}}>
                    <h6 className="mt-3 p-0"><strong>결제내역</strong></h6>
                    <Table striped hover size="sm">
                        <thead>
                            <tr>
                                <th>결제/승인번호</th>
                                <th>결제수단</th>
                                <th>결제금액</th>
                                <th>은행/카드사</th>
                                <th>결제일시</th>
                            </tr>
                        </thead>
                        <tbody>
                        {receipt_info.payments.map(function(item, idx){
                            return(<tr key={idx} className="border-bottom lh-lg">
                                <td>
                                    {item.payment_code}
                                </td>
                                <td>
                                    {method[item.method]}
                                </td>
                                <td>
                                    <strong>{item.payment_amount}원</strong>
                                </td>
                                <td>
                                    {item.bank}
                                </td>
                                <td>
                                    {item.created_date}
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </>
    );

}

export default Receipt_Form;
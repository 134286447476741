import React from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function SubcribePrice_Calc(props){
    const navigate = useNavigate();
    return (
        <div className="subcribe-price-box pt-3 text-center" >
            <strong className="h3 text-center fw-bold">자연어처리 AI</strong>
            <Row className="text-center">
                <Col lg={4} className="px-1">
                        <div className="subcribe-price" style={{"height":"901px"}}>
                            <strong>체험플랜</strong>
                            <ListGroup variant="flush">
                                <ListGroup.Item>하루 최대 5번 웹 데모 사용가능</ListGroup.Item>
                                <ListGroup.Item>1회 처리 단어쌍 개수 제한</ListGroup.Item>
                                <ListGroup.Item>API 미지원</ListGroup.Item>
                                <ListGroup.Item>기술지원 없음</ListGroup.Item>
                                <ListGroup.Item>서비스 안정성 보장 없음</ListGroup.Item>
                                <Button variant="outline-primary" className="w-100 mt-4" onClick={()=>{navigate("/demo/nlp")}} >체험하기</Button>
                                <img style={{padding:"5rem", marginTop:"7rem"}} src={process.env.PUBLIC_URL + '/assets/img/LOGO_1618_gray.png'}/>
                            </ListGroup>
                        </div>
                </Col>
                <Col lg={4} className="px-1">
                        <div className="subcribe-price h-100">
                            <strong>구독플랜</strong>
                            <ListGroup variant="flush">
                                <ListGroup.Item>정해진 한도 내 무제한 사용가능</ListGroup.Item>
                                <ListGroup.Item>어휘 제한 없음</ListGroup.Item>
                                <ListGroup.Item>API 지원</ListGroup.Item>
                                <ListGroup.Item>기술지원 및 도움말 제공</ListGroup.Item>
                                <ListGroup.Item>안정적인 서비스</ListGroup.Item>
                                <ListGroup.Item style={{lineHeight:"30rem"}}><strong>구독서비스 준비중입니다 :)</strong></ListGroup.Item>
                                {/*
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>하루 10크레딧 <h5>10,000원/월</h5></Col>
                                        <Col lg={4}><Button variant="outline-primary" size="sm" className="mt-4">구독하기</Button></Col>
                                    </Row>
                                    </ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>하루 50크레딧 <h5>45,000원/월</h5></Col>
                                        <Col lg={4}><Button variant="outline-primary" size="sm" className="mt-4">구독하기</Button></Col>
                                    </Row>
                                    </ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>하루 60크레딧 <h5>50,000원/월</h5></Col>
                                        <Col lg={4}><Button variant="outline-primary" size="sm" className="mt-4">구독하기</Button></Col>
                                    </Row>
                                </ListGroup.Item>
                                */}   
                            </ListGroup>
                            
                        </div>
                </Col>
                <Col lg={4} className="px-1">
                        <div className="subcribe-price h-100">
                            <strong>프리페이드(선불)플랜</strong>
                            <ListGroup variant="flush">
                                <ListGroup.Item>정해진 한도 내 무제한 사용가능</ListGroup.Item>
                                <ListGroup.Item>어휘 제한 없음</ListGroup.Item>
                                <ListGroup.Item>API 지원</ListGroup.Item>
                                <ListGroup.Item>기술지원 및 도움말 제공</ListGroup.Item>
                                <ListGroup.Item>안정적인 서비스</ListGroup.Item>
                                <ListGroup.Item>필요한 만큼만 구매가능</ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>
                                            <span>5000 크레딧</span>
                                            <h5 className="text-secondary"><del>11,000원</del></h5>
                                            <h5 className="text-danger fw-bold"><span className="fs-6">오픈특가</span> 4,400원</h5>
                                        </Col>
                                        <Col lg={4}><Button variant="outline-primary" onClick={()=>{props.setPayplans(()=>{return [{plan_uid:25, ea:1}]});}} size="sm" className="mt-lg-4">주문하기</Button></Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>
                                            <span>10000 크레딧</span>
                                            <h5 className="text-secondary"><del>20,900원</del></h5>
                                            <h5 className="text-danger fw-bold"><span className="fs-6">오픈특가</span> 8,360원</h5>
                                        </Col>
                                        <Col lg={4}><Button variant="outline-primary" onClick={()=>{props.setPayplans(()=>{return [{plan_uid:26, ea:1}]});}} size="sm" className="mt-lg-4">주문하기</Button></Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>
                                            <span>25000 크레딧</span>
                                            <h5 className="text-secondary"><del>49,500원</del></h5>
                                            <h5 className="text-danger fw-bold"><span className="fs-6">오픈특가</span> 19,800원</h5>
                                        </Col>
                                        <Col lg={4}><Button variant="outline-primary" onClick={()=>{props.setPayplans(()=>{return [{plan_uid:27, ea:1}]});}} size="sm" className="mt-lg-4">주문하기</Button></Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>
                                            <span>50000 크레딧</span>
                                            <h5 className="text-secondary"><del>99,000원</del></h5>
                                            <h5 className="text-danger fw-bold"><span className="fs-6">오픈특가</span> 39,600원</h5>
                                        </Col>
                                        <Col lg={4}><Button variant="outline-primary" onClick={()=>{props.setPayplans(()=>{return [{plan_uid:28, ea:1}]});}} size="sm" className="mt-lg-4">주문하기</Button></Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>
                                            <span>100000 크레딧</span>
                                            <h5 className="text-secondary"><del>192,500원</del></h5>
                                            <h5 className="text-danger fw-bold"><span className="fs-6">오픈특가</span> 77,000원</h5>
                                        </Col>
                                        <Col lg={4}><Button variant="outline-primary" onClick={()=>{props.setPayplans(()=>{return [{plan_uid:29, ea:1}]});}} size="sm" className="mt-lg-4">주문하기</Button></Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="subcribe">
                                    <Row>
                                        <Col lg={8}>
                                            <span>250000 크레딧</span>
                                            <h5 className="text-secondary"><del>467,500원</del></h5>
                                            <h5 className="text-danger fw-bold"><span className="fs-6">오픈특가</span> 187,000원</h5>
                                        </Col>
                                        <Col lg={4}><Button variant="outline-primary" onClick={()=>{props.setPayplans(()=>{return [{plan_uid:30, ea:1}]});}} size="sm" className="mt-lg-4">주문하기</Button></Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                            
                        </div>
                </Col>
            </Row>
        </div>


    );
}

export default SubcribePrice_Calc;
import React from "react";
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";

function Modal_Password_alert(props){

    return (
        <>
            <Modal
              show={props.show}
              onHide={props.handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{props.modal_title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {props.content}
                <Form.Group className="mt-3">
                  <Form.Control  type="password" defaultValue={props.pw} onChange={(e)=>{props.setSecretPw(e.target.value)}} placeholder="비밀번호 입력"/>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
              {props.secondary && <Button variant="secondary" onClick={props.handleClose}>
                  닫기
                </Button>}
                <Button variant="primary" onClick={!(props.secondary && props.handleSubmit) ? props.handleClose : props.handleSubmit} >{props.primary_btn}</Button>
              </Modal.Footer>
            </Modal>
        </>
      );

}

export default Modal_Password_alert;
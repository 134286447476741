import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, FloatingLabel, InputGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Modal_alert from "../../modal.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

function RegisterArticle(){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);

    const [btnText, setBtntext] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [delete_show, setDeleteShow] = useState(false);
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const [board_info, setBoardInfo] = useState([{board_code:"news", board_name:"최신소식"}, {board_code:"promotion", board_name:"홍보자료"}, {board_code:"articles", board_name:"관련기사"}])
    const [content, setContent] = useState("");
    const[values, setValues] = useState({article_info:{uid : (location.state != null && location.state.uid != undefined ? location.state.uid : 0), board_code:board_info[0].board_code, title:"", content:"", attachments:[]}, writer_info:{nickname:"", id:"", level:0}});
    const [attachments, setAttachments] = useState([]);
    const [attachment, setAttachment] = useState({});
    const [sw_upload, setSWUpload] = useState(null);
    const onLoadFile = (e)=>{
        setSWUpload(e.target.files);
    }
    const handleChange = e => {
        console.log(e.target.name+": "+e.target.value);
        let copy = {...values.article_info, [e.target.name]:e.target.value};
        setValues({
          ...values,
          article_info : copy,
        })
      }
    const changeContent = (data)=>{
        
        setContent(data);
    }
    const addAttachments = (fileloc, filename)=>{
        setAttachment({uid:0, fileloc:fileloc, filename:filename});
    }
    const handlePublic = () =>{
        setValues({
            ...values,
            ispublic :  !(values.ispublic),
        })
    };

    const handleSubmit_delete = (event) => {
        event.preventDefault();
        setDeleteShow(false);
        deleteArticle();
      };

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }else{
        regist();
      }

      setValidated(true);
      
    };
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/ArticleList");
    };
    const handleShow = () => setShow(true);
    const deleteArticle = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/service/deleteArticle',
        { params : {art_uid : location.state.uid}}).then(response =>{
            setDeleteCompleteShow(true);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const deleteAttachments = (uid, filename) =>{
        setLoading(()=>{return true});
        axios.get('/rest/deleteAttachments',
        { params : {att_uid : uid, filename:filename}}).then(response =>{
            if(response.data.result == "success")
                setAttachments(attachments.filter(att=>att.uid != uid));
            else
                alert("첨부파일 삭제실패");
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const getArticleInfo = () =>{
        if(values.article_info.board_code == null || values.article_info.uid == 0)
            return;
        setLoading(true);
        axios.get('/rest/getBoardArticle', 
            {params:
            {
                uid:values.article_info.uid

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result != 'success'){
                    alert("게시글정보 로드 실패");
                    window.history.back();
                }else{
                    setValues(response.data.data);
                    if(response.data.data.article_info.attachments != undefined)
                         setAttachments(response.data.data.article_info.attachments)
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const setArticleMode = () =>{
        if(values.article_info.board_code == null)
            return;
        setLoading(true);
        axios.get('/rest/setArticleUpdateMode', 
            {params:
            {
                att_uid : values.article_info.uid,
                board_code : values.article_info.board_code

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result != 'success'){
                    alert("게시글모드 변경 실패");
                    window.history.back();
                }else{
                    getArticleInfo();
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const regist_attachment = () =>{
        setLoading(true);
        const formData = new FormData();
        if(sw_upload != null)
            formData.append("file", sw_upload[0])
        axios.post('/rest/uploadAttachment',
        formData).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    addAttachments(response.data.fileloc, sw_upload[0].name);
                }else{
                   
                }
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const regist = () =>{
        setLoading(true);
        var link = values.article_info.uid > 0 ? '/rest/updateArticle' : '/rest/writeArticle';
        axios.post(link,
        values.article_info).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    if(values.article_info.uid > 0){
                        setModalTitle("게시글 수정 성공");
                        setModalContent("게시글수정에 성공하였습니다.");
                    }else{
                        setModalTitle("게시글 등록 성공");
                        setModalContent("게시글등록에 성공하였습니다.");
                    }
                    setRegisterResult(true);
                }else{
                    if(values.article_info.uid > 0){
                        setModalTitle("게시글 수정 실패");
                        setModalContent("게시글수정에 실패하였습니다.");
                    }else{
                        setModalTitle("게시글 등록 실패");
                        setModalContent("게시글등록에 실패하였습니다.");
                    }
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
        const imgLink = "/rest/downloadImage?filename="
        const customUploadAdapter = (loader) => { // (2)
            return {
                upload(){
                    return new Promise ((resolve, reject) => {
                        const data = new FormData();
                         loader.file.then( (file) => {
                                data.append("file", file);
    
                                axios.post('/rest/uploadAttachment', data)
                                    .then((res) => {
                                        console.log(attachments);
                                        addAttachments(res.data.fileloc, file.name);
                                        resolve({
                                            default: `${imgLink}${res.data.fileloc}`
                                        });
                                    })
                                    .catch((err)=>reject(err));
                            })
                    })
                }
            }
        }
    
    function uploadPlugin (editor){ // (3)
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        }
    }
    useEffect(() => {}, []);
    useEffect(() => {if(attachment.fileloc != undefined)setAttachments([...attachments, attachment]);}, [attachment]);
    useEffect(() => {
        let copy = {...values.article_info, content:content};
        setValues({
          ...values,
          article_info : copy,
        })
    }, [content]);
    useEffect(() => {setArticleMode();}, [values.board_code]);
    useEffect(() => {
        if(location.state != null && location.state.uid > 0){
            setTitle(()=>{
                return "게시글 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
        }else{
            setTitle(()=>{
                return "게시글 작성";
            })
            setBtntext(()=>{
                return "등록";
            })
            setValues({article_info:{uid : (location.state != null && location.state.uid != undefined ? location.state.uid : 0), board_code:board_info[0].board_code, title:"", content:""}, writer_info:{nickname:"", id:"", level:0}});
        }
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Container>
                        <Row>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>게시판선택</Form.Label>
                                    {
                                        location.state != null && location.state.uid != undefined && location.state.uid > 0 ? 
                                            <Form.Select name="board_code" value={values.article_info.board_code} onChange={handleChange} aria-label="게시판 선택" disabled>
                                                {board_info.map(function(item, idx){
                                                    return (<option key={idx} value={item.board_code}>{item.board_name}</option>);
                                                })}
                                            </Form.Select>
                                        :
                                            <Form.Select name="board_code" value={values.article_info.board_code} onChange={handleChange} aria-label="게시판 선택">
                                                {board_info.map(function(item, idx){
                                                    return (<option key={idx} value={item.board_code}>{item.board_name}</option>);
                                                })}
                                            </Form.Select>
                                    }
                                </Form.Group>
                            </Col>
                            <Col lg={9}>
                                <Form.Group>
                                    <Form.Label>제목</Form.Label>
                                    <Form.Control required type="text" name="title" value={values.article_info.title} onChange={handleChange} placeholder="제목 입력"/>
                                    <Form.Control.Feedback type="invalid">
                                        제목을 입력해주세요.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <div className="document-editor__toolbar"></div>
                                <CKEditor
                                    editor={ DecoupledEditor }
                                    config={{
                                        extraPlugins:[uploadPlugin,]
                                    }}
                                    data={values.article_info.content}
                                    onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!', editor );
                                        const toolbarContainer = document.querySelector( '.document-editor__toolbar' );
                                        toolbarContainer.appendChild( editor.ui.view.toolbar.element );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        console.log( { event, editor, data } );
                                        changeContent(data);
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className="fs-6 fw-bold">첨부파일</div>
                            <InputGroup className="mb-3">
                                <Form.Control type="file" id="file_upload" onChange={onLoadFile}/>
                                <Button variant="outline-secondary" onClick={(e)=>{regist_attachment();}}>파일첨부</Button>
                            </InputGroup>
                            {attachments.length > 0 ?
                                (attachments.map(function(a,i){
                                    return(
                                        <Row key={i}>
                                            <div>
                                                
                                                <span className="me-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-3 bi bi-floppy-fill" viewBox="0 0 16 16">
                                                        <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z"/>
                                                        <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z"/>
                                                    </svg>
                                                    <Link to={"/rest/downloadImage?filename="+a.fileloc} target="_blank">{a.filename}</Link>
                                                </span>
                                                <Button size="sm" variant="danger" onClick={(e)=>{deleteAttachments(a.uid, a.fileloc)}} class="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                    </svg>
                                                </Button>
                                            </div>
                                        </Row>
                                    );
                                }))
                                :
                                <div className="text-center">첨부파일이 없습니다.</div>
                            }
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            
                            <Col sm={1}>
                                <Button type="button" className={`w-100 btn btn-secondary ${location.state != null && location.state.uid > 0 ? "" : "d-none"}`} onClick={()=>{setDeleteShow(true)}}>삭제</Button>
                            </Col>
                            <Col sm={1}>
                                <Button type="submit" className="w-100">{btnText}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                {location.state != null && location.state.uid > 0 ? 
                <><Modal_alert show={delete_show} handleClose={()=>{setDeleteShow(false)}} handleSubmit={handleSubmit_delete} modal_title="" content="정말 게시물을 삭제하시겠습니까?" secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={delete_complete_show} handleClose={()=>{setDeleteCompleteShow(false); navigate("/admin/ArticleList"); }} modal_title="삭제완료" content="게시글이 삭제되었습니다." primary_btn={primary_btn}/></>
                :
                ""
                }
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
            </div>
        </>
        );

}

export default RegisterArticle;
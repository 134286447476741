import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, InputGroup, ListGroup } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Modal_alert from "../../modal.js";

function RegisterEquipment(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("부품등록");
    const [loading, setLoading] = useState(false);
    const [equipmentType, setEquipmentType]= useState([]);
    const [swType, setSwType]= useState({drivers:"드라이버", utils:"유틸"});

    const [sw_upload, setSWUpload] = useState(null);
    

    const [osList, setOSList] = useState([]);
    const [cur_os, setCurOS] = useState(0);
    const [listData, setListData] = useState([]);
    const [swCategory, setSWCategory] = useState([]);

    const [sw_input, setSWInput] = useState({uid:0, equipment_uid:(location.state != null ? location.state.uid : 0), category_uid:(swCategory.length > 0 ? swCategory[0].uid : 0), os_uid:(osList.length > 0 ? osList[0].uid : 1)});

    const [btnText, setBtntext] = useState("수정");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [regist_show, setRegistShow] = useState(false);
    const [regist_complete_show, setRegistCompleteShow] = useState(false);
    const [regist_failed_show, setRegistFailedShow] = useState(false);
    const [update_show, setUpdateShow] = useState(false);
    const [update_complete_show, setUpdateCompleteShow] = useState(false);
    const [update_failed_show, setUpdateFailedShow] = useState(false);
    const [delete_show, setDeleteShow] = useState(false);
    const [delete_complete_show, setDeleteCompleteShow] = useState(false);
    const [delete_failed_show, setDeleteFailedShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const[values, setValues] = useState({ name:"", type_uid:0});

    const handleChange = e => {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }
    const handleChangeOS = e => {
        setCurOS(e.target.value);
      }
    const handleChangeCategory = e => {
      setSWInput({...sw_input, category_uid : Number(e.target.value)});
    }
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/equipmentList");
    };
    const handleSWUpdate = (e)=>{
        if(sw_input.uid == 0)
            setSWInput({...sw_input, uid:Number(e.target.getAttribute("uid"))});
        else
            regist_sw();
    }
    const handleShow = () => setShow(true);
    const getEquipmentTypeIndex = (uid)=>{
        if(equipmentType.length == 0)
            getEquipmentType();
        let res_idx = -1;
        equipmentType.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        //console.log("idx : "+res_idx);
        return res_idx;
    }
    const getOSIndex = (uid)=>{
        if(osList.length == 0)
            getOSList();
        let res_idx = -1;
        osList.map(function(item, idx){
            if(item.uid == uid){
                res_idx  = idx;
                return;
            }
        });
        //console.log("idx : "+res_idx);
        return res_idx;
    }
    const onLoadFile = (e)=>{
        setSWUpload(e.target.files);
    }
    const getEquipmentType = () =>{
        axios.get('/rest/admin/getEquipmentTypeList').then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setEquipmentType(()=>{return response.data.list});
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const getSWCategory = () =>{
        setLoading(true);
        axios.get('/rest/admin/getSoftwareCategory', 
            {params:
            {
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                //let totalSize = response.data.count;
                //let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setSWCategory(list => response.data.list);
                //setTotalSize(totalSize);
                //setTotalPage(totalPage_);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const getOSList = () =>{
        axios.get('/rest/admin/getSoftwareOS',
        { params : {model_uid : 0}}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setOSList(()=>{return response.data.list});
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const getSWInfo = () =>{
        if((location.state != null ? location.state.uid : 0) <= 0)
            return;
        getOSList();
        setLoading(()=>{return true});
        axios.get('/rest/admin/getSWList',
        { params : {equipment_uid : (location.state != null ? location.state.uid : 0), os_uid:sw_input.os_uid}}).then(response =>{
            setListData(response.data.list);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const getEquipmentInfo = () =>{
        if((location.state != null ? location.state.uid : 0) <= 0)
            return;
        getEquipmentType();
        setLoading(()=>{return true});
        axios.get('/rest/admin/getEquipmentInfo',
        { params : {uid : (location.state != null ? location.state.uid : 0)}}).then(response =>{
            setValues(response.data.data);
            getSWInfo();
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }

    const regist = () =>{
        setLoading(true);
        getEquipmentType();
        axios.post('/rest/admin/registerEquipment',
        { 
            name:values.name,
            type_uid:Number(values.type_uid)
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("부품 등록 성공");
                    setModalContent("부품등록에 성공하였습니다.");
                    setRegistCompleteShow(true);
                }else{
                    setModalTitle("부품 등록 실패");
                    setModalContent("부품등록에 실패하였습니다.");
                    setRegistFailedShow(true);
                }
                //handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const update = () =>{
        setLoading(true);
        getEquipmentType();
        axios.post('/rest/admin/updateEquipment',
        { 
            uid:location.state.uid,
            name:values.name,
            type_uid:Number(values.type_uid)
        }).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    setModalTitle("부품 수정 성공");
                    setModalContent("부품수정에 성공하였습니다.");
                    setUpdateCompleteShow(true);
                }else{
                    setModalTitle("부품 수정 실패");
                    setModalContent("부품수정에 실패하였습니다.");
                    setUpdateFailedShow(true);
                }
                //handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }

    const delete_equipment = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deleteEquipment',
        { params : {uid : location.state.uid}}).then(response =>{
            if(response.data.result == 'success'){
                setDeleteCompleteShow(true);
            }else{
                setDeleteFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const regist_sw = () =>{
        setLoading(true);
        let data = {
            uid: sw_input.uid,
            equipment_uid:Number(sw_input.equipment_uid),
            category_uid:Number(sw_input.category_uid),
            os_uid:Number(sw_input.os_uid)
        };
        console.log(data);
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);
        if(sw_upload != null)
            formData.append("file", sw_upload[0])
        axios.post('/rest/admin/registerSW',formData).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    alert("SW 등록 성공");
                    setSWInput({uid:0, equipment_uid:(location.state != null ? location.state.uid : 0), category_uid:(swCategory.length > 0 ? swCategory[0].uid : 0), os_uid:(osList.length > 0 ? osList[0].uid : 0)});
                    getSWInfo();
                }else{
                    alert("SW 등록 실패");
                }
                //handleShow(true);
            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const delete_SW = (e) =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deleteSW',
        { params : {uid : e.target.getAttribute("uid")}}).then(response =>{
            if(response.data.result == 'success'){
                alert("SW삭제완료");
                getSWInfo();
            }else{
                alert("SW삭제실패");
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    useEffect(()=>{getEquipmentType(); getSWCategory();}, []);
    useEffect(()=>{if(swCategory.length > 0) setSWInput({...sw_input, category_uid:swCategory[0].uid})}, [swCategory]);
    useEffect(()=>{if(osList.length > 0) setSWInput({...sw_input, os_uid:osList[cur_os].uid})}, [cur_os, osList]);
    useEffect(()=>{getSWInfo()}, [sw_input.os_uid]);
    useEffect(() => {}, [values, equipmentType]);
    useEffect(() => {
        getEquipmentInfo();
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={5}>
                                <Form.Group>
                                    <Form.Label>부품명</Form.Label>
                                    <Form.Control required type="text" name="name" value={values.name} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <Form.Label>부품타입</Form.Label>
                                    <Form.Select name="type_uid" value={values.type_uid} onChange={handleChange} aria-label="부품타입 선택">
                                        {equipmentType.map(function(item, idx){
                                            return (<option key={idx} value={item.uid}>{item.type_name}</option>);
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Row>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>OS</Form.Label>
                                    <Form.Select name="os_select" value={sw_input.os_uid} onChange={handleChangeOS} aria-label="OS 선택">
                                        {osList.map(function(item, idx){
                                            return (<option key={idx} value={item.uid}>{item.os_name}</option>);
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div style={{overflowX:'Auto'}}>
                            <Form.Label>SW 목록</Form.Label>
                            <Table striped bordered hover size="sm" style={{minWidth:'1200px'}} >
                                <thead>
                                    <tr>
                                        <th>순서</th>
                                        <th>파일명</th>
                                        <th>카테고리</th>
                                        <th>등록일자</th>
                                        <th>수정</th>
                                        <th>삭제</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sw_input.uid == 0 ? 
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <Form.Select name="category_select" value={sw_input.category_uid} onChange={handleChangeCategory} aria-label="카테고리 선택">
                                                {swCategory.map(function(item, idx){
                                                    return (<option key={idx} value={item.uid}>{item.category_name}({item.software_type != null ? swType[item.software_type] : ""})</option>);
                                                })}
                                                </Form.Select>
                                            </td>
                                            <td><Form.Control type="file" id="sw_file" onChange={onLoadFile}/></td>
                                            <td><button type="button" onClick={(e)=>{regist_sw();}} className="btn btn-primary">등록</button></td>
                                            <td></td>
                                        </tr>
                                            :
                                            ""
                                    }
                                    {listData.map(function(a, idx){
                                        return (
                                            sw_input.uid == a.uid ? 
                                            <tr key={idx}>
                                                <td>{idx+1}</td>
                                                    <td></td>
                                                    <td>
                                                        <Form.Select name="category_select" value={a.category_uid} onChange={handleChangeCategory} aria-label="카테고리 선택">
                                                        {swCategory.map(function(item, idx){
                                                            return (<option key={idx} value={item.uid}>{item.category_name}({item.software_type != null ? swType[item.software_type] : ""})</option>);
                                                        })}
                                                        </Form.Select>
                                                    </td>
                                                    <td><Form.Control type="file" id="sw_file" onChange={onLoadFile}/></td>
                                                    <td><button type="button" uid={a.uid} onClick={(e)=>{handleSWUpdate(e);}} className="btn btn-primary">수정</button></td>
                                                    <td><button type="button" uid={a.uid} onClick={(e)=>{delete_SW(e);}} className="btn btn-danger">삭제</button></td>
                                                </tr>
                                            :
                                            <tr key={idx}>
                                                <td>{idx+1}</td>
                                                <td>{a.file_name}</td>
                                                <td>{a.category_name}({a.type_uid != null ? swType[a.type_uid] : ""})</td>
                                                <td>{a.created_date}</td>
                                                <td><button type="button" uid={a.uid} onClick={(e)=>{handleSWUpdate(e);}} className="btn btn-primary">수정</button></td>
                                                <td><button type="button" uid={a.uid} onClick={(e)=>{delete_SW(e);}} className="btn btn-danger">삭제</button></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            {location.state != null && location.state.uid > 0 ? 
                                <>
                                    <Col>
                                        <Button  className="text-start" type="button" variant="danger"  style={{marginTop:"2rem"}} onClick={()=>{setDeleteShow(true)}}>부품 삭제</Button>
                                    </Col>
                                </>
                                :
                                ""
                            }
                            <Col className="text-end">
                                {location.state != null && location.state.uid > 0 ? 
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setUpdateShow(true)}}>부품정보수정</Button>
                                    :
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setRegistShow(true)}}>부품정보등록</Button>
                                }
                                
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={regist_show} handleClose={()=>{setRegistShow(false)}} handleSubmit={regist} modal_title="" content={`부품을 등록하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={regist_complete_show} handleClose={()=>{setRegistCompleteShow(false); navigate("/admin/equipmentList")}} modal_title="부품등록완료" content="부품이 등록되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={regist_failed_show} handleClose={()=>{setRegistFailedShow(false);}} modal_title="부품등록실패" content="부품등록에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
                <Modal_alert show={update_show} handleClose={()=>{setUpdateShow(false)}} handleSubmit={()=>{setUpdateShow(false); update()}} modal_title="" content={`부품정보를 수정하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={update_complete_show} handleClose={()=>{setUpdateCompleteShow(false); navigate("/admin/equipmentList")}} modal_title="부품정보수정완료" content="부품정보가 수정되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={update_failed_show} handleClose={()=>{setUpdateFailedShow(false);}} modal_title="부품정보수정실패" content="부품정보 수정에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={delete_show} handleClose={()=>{setDeleteShow(false);}} handleSubmit={delete_equipment} modal_title="부품삭제경고" content="부품을 삭제하시겠습니까?"  secondary={true} primary_btn={primary_btn} />
                <Modal_alert show={delete_complete_show} handleClose={()=>{setDeleteCompleteShow(false);}} handleSubmit={()=>{setDeleteCompleteShow(false); navigate('/admin/equipmentList');}} modal_title="부품삭제성공" content="부품삭제에 성공하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={delete_failed_show} handleClose={()=>{setDeleteFailedShow(false);}} modal_title="부품삭제실패" content="부품삭제에 실패하였습니다." primary_btn={primary_btn} />
            </div>
        </>
        );

}

export default RegisterEquipment
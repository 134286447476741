import React, {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Loading.js";


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, ListGroup } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import Modal_alert from "../../modal.js";


function RegisterModel(props){
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState("모델 상세조회/수정");
    const [loading, setLoading] = useState(false);
    const [serviceType, setServiceType]= useState([]);
    const [img_upload, setImgUpload] = useState(null);
    const [btnText, setBtntext] = useState("수정");
    
    const [modal_title, setModalTitle] = useState("");
    const [modal_content, setModalContent] = useState("");
    const [secondary, setSecondary] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);
    const [primary_btn, setPrimary_btn] = useState("확인");
    const [show, setShow] = useState(false);
    const [model_delete_show, setModelDeleteShow] = useState(false);
    const [model_deleted_show, setModelDeletedShow] = useState(false);
    const [model_delete_failed_show, setModelDeleteFailedShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const [regist_show, setRegistShow] = useState(false);
    const [regist_complete_show, setRegistCompleteShow] = useState(false);
    const [regist_failed_show, setRegistFailedShow] = useState(false);
    const [update_show, setUpdateShow] = useState(false);
    const [update_complete_show, setUpdateCompleteShow] = useState(false);
    const [update_failed_show, setUpdateFailedShow] = useState(false);

    const [categorySearch, setCategorysearch] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryListShow, setCategoryListShow] = useState(false);

    const [partsSearch, setPartssearch] = useState("");
    const [partsList, setPartsList] = useState([]);
    const [selectedParts, setSelectedParts] = useState([]);
    const [partsListShow, setPartsListShow] = useState(false);

    const[values, setValues] = useState({uid:0, model_name:"", category_uid:0, img_loc:"", ispublic:false, created_date:"", equipments:[], category:[]});


    const handleChange = e => {
        console.log(e.target.name+": "+e.target.value);
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        })
      }
    const onLoadFile = (e)=>{
        setImgUpload(e.target.files);
    }
    const handleClose= () => {
        if(registerResult == false)
            setShow(false);
        else   
            navigate("/admin/ModelList");
    };
    const handleShow = () => setShow(true);
    const handlePartsSearch = e => {
        setPartssearch(e.target.value);
    }
    const handleCategorySearch = e => {
        setCategorysearch(e.target.value);
    }
    const deleteParts = (uid, e) =>{
        console.log("deletepayplan "+uid);
        setSelectedParts(selectedParts.filter(item => item.uid != uid));
   }
   const partsSelectHandler = (e) => {
        setSelectedParts([
            ...selectedParts,
            {part_uid : Number(e.target.getAttribute("uid")), equipment_name: e.target.getAttribute("equipment_name"),type_name: e.target.getAttribute("type_name")},
        ])
        setPartssearch("");
        setPartsListShow(false);
    }
    const handlePublic = () =>{
        setValues({
            ...values,
            ispublic :  !(values.ispublic),
        })
    };
    const categorySelectHandler = (e) => {
        console.log(selectedCategory);
        setSelectedCategory([{uid : Number(e.target.getAttribute("uid")), category_name: e.target.getAttribute("category_name")},
        ])
        setCategorysearch("");
        setCategoryListShow(false);
    }
    const getModelInfo = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/getModelInfo',
        { params : {uid : location.state.uid}}).then(response =>{
            setValues(response.data.data);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const searchParts = (q) =>{
        axios.get('/rest/admin/getEquipmentList', 
            {params:
            {
                page : 0,
                page_limit : 10,
                q_type: 'name',
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setPartsList(()=>{return response.data.list});           
                
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const searchModelCategory = (q) =>{
        axios.get('/rest/admin/getModelCategoryList', 
            {params:
            {
                page : 0,
                page_limit : 10,
                query : q

            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                setCategoryList(()=>{return response.data.list});           
                
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    const deleteModel = () =>{
        setLoading(()=>{return true});
        axios.get('/rest/admin/deleteModel',
        { params : {uid : location.state.uid}}).then(response =>{
            if(response.data.result == 'success'){
                setModelDeleteShow(false);
                setModelDeletedShow(true);
            }else{
                setModelDeleteFailedShow(true);
            }
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
        setLoading(()=>{return false});
    }
    const regist = () =>{
        setLoading(true);
        let data = {
            uid: values.uid,
            model_name: values.model_name,
            category_uid: Number(selectedCategory[0].uid),
            parts: selectedParts,
            ispublic : values.ispublic
        };
        console.log(data);
        let json_data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("body", json_data);
        if(img_upload != null)
            formData.append("file", img_upload[0])
        axios.post('/rest/admin/registerModel',formData).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/admin/login");
            }else{
                if(response.data.result == 'success'){
                    if(data.uid > 0){
                        setModalTitle("모델 수정 성공");
                        setModalContent("모델수정에 성공하였습니다.");
                    }else{
                        setModalTitle("모델 등록 성공");
                        setModalContent("모델등록에 성공하였습니다.");
                    }
                    setRegisterResult(true);
                }else{
                    if(data.uid > 0){
                        setModalTitle("모델 수정 실패");
                        setModalContent("모델수정에 실패하였습니다.");
                    }else{
                        setModalTitle("모델 등록 실패");
                        setModalContent("모델등록에 실패하였습니다.");
                    }
                    setRegisterResult(false);
                }
                handleShow(true);

            }
            setLoading(false);
        }).catch(
            error=>{
                navigate("/admin/login");
                console.log(error);
            }
        );
    }
    useEffect(() => {
        if(categorySearch.length > 0)
            searchModelCategory(categorySearch);
        else
            setCategoryListShow(false);
    }, [categorySearch]);
    useEffect(() => {
        if(categoryList.length > 0){
            setCategoryListShow(true);
        }else{
            setCategoryListShow(false);
        }

    }, [categoryList]);
    useEffect(() => {
        if(partsList.length > 0){
            setPartsListShow(true);
        }else{
            setPartsListShow(false);
        }

    }, [partsList]);
    useEffect(() => {
        if(partsSearch.length > 0)
            searchParts(partsSearch);
        else
            setPartsListShow(false);
    }, [partsSearch]);
    useEffect(() => {
        if(values.category != undefined && values.category.length >0)
            setSelectedCategory(values.category);
        if(values.equipments != undefined && values.equipments.length >0)
            setSelectedParts(values.equipments);
        }, [values]);
    useEffect(()=>{}, [categoryList, partsList]);
    useEffect(() => {
        if(location.state != null && location.state.mode == 'mod'){
            setTitle(()=>{
                return "모델 수정";
            })
            setBtntext(()=>{
                return "수정";
            })
            getModelInfo();
        }else{
            setTitle(()=>{
                return "모델 신규 등록";
            })
            setBtntext(()=>{
                return "등록";
            })
            setValues({uid:0, model_name:"", category_uid:0, img_loc:"", ispublic:false, created_date:"", equipments:[]});
        }
      },[location.state]);
    return (
        <>
            {loading == true ? <Loading/> : ""}
            <div className={`content card-shadow`}>
                <div className="mb-3">
                    <h5><strong>{title}</strong></h5>
                </div>
                <Form noValidate validated={validated} onSubmit={(e)=>{e.preventDefault()}}>
                    <Container>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>모델명</Form.Label>
                                    <Form.Control required type="text" name="model_name" defaultValue={values.model_name} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>모델 카테고리 검색</Form.Label>
                                    <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none"}} value={categorySearch} onChange={handleCategorySearch} placeholder="카테고리 검색" />
                                    <ListGroup className={`${categoryListShow ? "" : "d-none"} position-absolute`}>
                                        {categoryList.map(function(item, idx){
                                            return(<ListGroup.Item action key={idx} onClick={categorySelectHandler} uid={item.uid} category_name={item.category_name}>{item.category_name}</ListGroup.Item>)
                                        })}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>모델 카테고리</Form.Label>
                                    <Form.Control required type="text" name="category_name" defaultValue={selectedCategory.length > 0 ? selectedCategory[0].category_name : ""} readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>제품이미지등록</Form.Label>
                                    <Form.Control type="file" accept="image/jpg, image/jpeg, image/png" id="img_file" onChange={onLoadFile}/>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>공개여부</Form.Label>
                                    <Form.Check name="ispublic" type="switch" checked={values.ispublic} onChange={handlePublic} label="공개"></Form.Check>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group>
                                    <Form.Label>등록일시</Form.Label>
                                    <Form.Control  type="text" defaultValue={values.created_date} placeholder="등록되면 표시됩니다." readOnly/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={5}>
                                {values.uid == 0 ? 
                                    <Form.Group>
                                        <Form.Label>부품검색</Form.Label>
                                        <Form.Control type="text" style={{backgroundImage:"None", border:"1px solid #ced4da", boxShadow:"none", paddingRight:0}} value={partsSearch} onChange={handlePartsSearch} placeholder="부품 검색" />
                                        <ListGroup className={`${partsListShow ? "" : "d-none"} position-absolute`}>
                                            {partsList.map(function(item, idx){
                                                return(<ListGroup.Item action key={idx} onClick={partsSelectHandler} uid={item.uid} equipment_name={item.name} type_uid={item.type_uid}>{item.name}</ListGroup.Item>)
                                            })}
                                        </ListGroup>
                                    </Form.Group>
                                    :
                                    ""
                                }
                            </Col>
                            <Col lg={7}>
                                <Form.Group>
                                        <Form.Label>부품목록</Form.Label>
                                    <Container className="border p-3">
                                        {selectedParts.map(function(item, idx){
                                            return(<Row key={idx} className="border-bottom lh-lg">
                                                <Col lg={5}>
                                                    {item.equipment_name}
                                                    {item.type_name}
                                                </Col>
                                                <Col lg={1}>
                                                    { values.uid == 0 ?
                                                        <Button variant="outline-danger" className="btn btn-sm py-0" onClick={(e)=>{deleteParts(item.part_uid, e)}}>X</Button>
                                                        :
                                                        ""
                                                    }
                                                </Col>
                                            </Row>)
                                        })}
                                    </Container>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-lg-between">
                            {values.uid > 0 ? 
                                <>
                                    <Col className="text-start">
                                        <Button type="button" className={`btn btn-danger`} style={{marginTop:"2rem"}} onClick={()=>{setModelDeleteShow(true)}}>모델삭제</Button>
                                    </Col>
                                </>
                                :
                                ""
                            }
                            <Col className="text-end">
                                {values.uid > 0 ? 
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setUpdateShow(true)}}>모델정보수정</Button>
                                    :
                                    <Button type="button" className={` btn btn-secondary `}  style={{marginTop:"2rem"}} onClick={()=>{setRegistShow(true)}}>모델정보등록</Button>
                                }
                                
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <Modal_alert show={model_delete_show} handleClose={()=>{setModelDeleteShow(false);}} handleSubmit={()=>{deleteModel()}}  modal_title="모델삭제" content="모델을 삭제하시겠습니까?"  secondary={true} primary_btn={primary_btn} />
                <Modal_alert show={model_deleted_show} handleClose={()=>{setModelDeletedShow(false);navigate("/admin/ModelList");}} modal_title="모델삭제완료" content="모델이 삭제되었습니다." primary_btn={primary_btn} />
                <Modal_alert show={model_delete_failed_show} handleClose={()=>{setModelDeleteFailedShow(false);}} modal_title="모델삭제실패" content="모델삭제에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={regist_show} handleClose={()=>{setRegistShow(false)}} handleSubmit={regist} modal_title="" content={`모델을 등록하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={regist_complete_show} handleClose={()=>{setRegistCompleteShow(false); navigate("/admin/equipmentList")}} modal_title="모델등록완료" content="모델이 등록되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={regist_failed_show} handleClose={()=>{setRegistFailedShow(false);}} modal_title="모델등록실패" content="모델등록에 실패하였습니다." primary_btn={primary_btn} />
                <Modal_alert show={show} handleClose={handleClose} modal_title={modal_title} content={modal_content} secondary={secondary} primary_btn={primary_btn}/>
                <Modal_alert show={update_show} handleClose={()=>{setUpdateShow(false)}} handleSubmit={()=>{setUpdateShow(false); regist()}} modal_title="" content={`모델정보를 수정하시겠습니까?`} secondary={true} primary_btn={primary_btn}/>
                <Modal_alert show={update_complete_show} handleClose={()=>{setUpdateCompleteShow(false); navigate("/admin/ModelList")}} modal_title="모델정보수정완료" content="모델정보가 수정되었습니다." primary_btn={primary_btn}/>
                <Modal_alert show={update_failed_show} handleClose={()=>{setUpdateFailedShow(false);}} modal_title="모델정보수정실패" content="모델정보 수정에 실패하였습니다." primary_btn={primary_btn} />
            </div>
        </>
        );

}

export default RegisterModel;
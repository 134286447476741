import React from "react";
import { Form, InputGroup, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { useEffect } from "react";

function Modal_Request_Receipt(props){

  

const handleCashReceiptCode = e => {
  props.setCashReceiptCode(e.target.value)
}
    return (
        <>
            <Modal
              show={props.show}
              onHide={props.handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{props.modal_title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {props.content}
                <Row className="mt-3">
                    <Form.Label>요청 구분</Form.Label>
                    <InputGroup>
                      <Form.Check // prettier-ignore
                        className="me-3"
                        type='radio'
                        name="Receipt_type"
                        label="현금영수증"
                        onChange={()=>{props.setCheckType(0)}}
                      />
                      <Form.Check // prettier-ignore
                        type='radio'
                        name="Receipt_type"
                        label="세금계산서"
                        onChange={()=>{props.setCheckType(1)}}
                      />
                    </InputGroup>
                  </Row>
                {
                  props.receipt_type[props.check_type].type == 'cashReceipt' ?
                <>
                  <Row className="mt-3">
                    <Form.Label>영수증 구분</Form.Label>
                    <InputGroup>
                      <Form.Check // prettier-ignore
                        className="me-3"
                        type='radio'
                        name="cashReceipt_type"
                        label="개인소득공제"
                        onChange={()=>{props.setCashReceiptType(false)}}
                      />
                      <Form.Check // prettier-ignore
                        type='radio'
                        name="cashReceipt_type"
                        label="사업자지출증빙"
                        onChange={()=>{props.setCashReceiptType(true)}}
                      />
                    </InputGroup>
                  </Row>
                  <Row className="mt-3">
                    <Form.Group>
                        <Form.Label>{`${props.cashReceiptType ? "사업자등록번호" : "휴대전화번호"} 입력.`}</Form.Label>
                        <Form.Control required type="text" name="cashreceiptcode" value={props.cashReceiptCode} onChange={handleCashReceiptCode} placeholder={`${props.cashReceiptType ? "사업자등록번호" : "휴대전화번호"}를 입력해주세요.`}/>
                    </Form.Group>
                  </Row>
                </>
                :
                  props.receipt_type[props.check_type].type == 'tax' ?
                <>
                  <Row className="mt-3">
                    <span>세금계산서 발행의 경우, 아래 이메일로 사업자등록증 사본, 담당자 이메일, 담당자 연락처를 기재하여 보내주세요.</span><br/>
                    <span>work@namisnt.com</span>
                  </Row>
                </>
                :
                ""
                }
              </Modal.Body>
              <Modal.Footer>
              {props.secondary && <Button variant="secondary" onClick={()=>{props.setCashReceiptCode(""); props.setCashReceiptType(0); props.setCheckType(0); props.handleClose();}}>
                  닫기
                </Button>}
                <Button variant="primary" onClick={!(props.secondary && props.handleSubmit) ? props.handleClose : ()=>{props.setCashReceiptCode(""); props.setCashReceiptType(0); props.setCheckType(0); props.handleClose();props.handleSubmit();}} >{props.primary_btn}</Button>
              </Modal.Footer>
            </Modal>
        </>
      );

}

export default Modal_Request_Receipt;
import React from "react";

import Header from "./header.js"
import Header_index from "./header_index.js"
import Footer from "./footer.js"

import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import LoginModal from "./Login_modal.js";
import SearchModal from "./Search_modal.js";
function MainLayout(){
    const location = useLocation();
    const navigate = useNavigate();
    const [loginShow, setLoginShow] = useState(false);
    const [searchShow, setSearchShow] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [topBannerShow, setTopBannerShow] = useState(false);

    
    const getUserInfo = () =>{
        axios.get('/sso/rest/getUserInfo',{headers:{'Referer_pass':document.referrer}}).then(response => {
            if(response.data.logged === false || response.data == ''){
                setIsLogged(false);
            }else{
                setIsLogged(true);
                setUserInfo(response.data);
            }
        }).catch(
            error=>{
                //navigate("/");
                //console.log(error);
            }
        );
    }
    const Logout_func = () =>{
        axios.get('/sso/rest/logout').then(response => {
            if(response.data.result == 'success'){
                setIsLogged(false);
                setUserInfo({});
                navigate("/");
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    
    useEffect(()=>{getUserInfo()}, [isLogged])
    return (
        <>
            {location.pathname=='/index' ? 
            <>
                <div id="video-banner" style={{width:"100%", background:"black", maxHeight:"550px", overflow:"hidden"}}>
                    <div  className="position-absolute w-100 text-center text-white banner-video-fg">
                        <div>
                            <span className="font-large mb-3 d-block">Creative, Amazing!</span>
                            <span className="h1 mb-3 d-block">미래로 나아가는 종합IT기업</span>
                            <span className="h1 d-block">나미에스엔티입니다.</span>
                        </div>
                    </div>
                    <video muted autoPlay loop className="banner-video-bg" >
                        <source src="/assets/buildings.mp4" type="video/mp4"/>
                    </video>
                </div>
                <Header_index setTopBannerShow={setTopBannerShow} setSearchShow={setSearchShow} searchShow={searchShow} topBannerShow={topBannerShow} isLogged={isLogged} loginShow={loginShow} setLoginShow={setLoginShow} userInfo={userInfo} getUserInfo={getUserInfo} Logout_func={Logout_func}/>
            </>
            :
            <Header setTopBannerShow={setTopBannerShow} setSearchShow={setSearchShow} searchShow={searchShow} topBannerShow={topBannerShow} isLogged={isLogged} loginShow={loginShow} setLoginShow={setLoginShow} userInfo={userInfo} getUserInfo={getUserInfo} Logout_func={Logout_func}/>
            }
            
            <div  className={topBannerShow == true ? "top-section-banner" : location.pathname=='/index' ? "" : "top-section"} >
                <Outlet context={{isLogged:isLogged, loginShow:loginShow, setLoginShow:setLoginShow, userInfo:userInfo}}/>
            </div>
            <Footer/>
            <LoginModal show={loginShow} setLoginShow={setLoginShow} setIsLogged={setIsLogged} handleClose={()=>{setLoginShow(false)}}/>
            <SearchModal show={searchShow} setSearchShow={setSearchShow} handleClose={()=>{setSearchShow(false)}}/>
        </>
    );
}

export default MainLayout;
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import {Row, Col, Container, ListGroup, Card, Table, Navbar, Nav } from "react-bootstrap";

function Docs_Greeting(){

    const [mobileExpanded, setMobileExpanded] = useState(false);

    const userauth = useRef(null);
    const singleimage = useRef(null);
    const batchimage = useRef(null);
    const batchimagelog = useRef(null);
    const getapicredit =useRef(null);
    const refreshtoken = useRef(null);
    const responselist = useRef(null);

    const userauthClick = ()=>{userauth.current?.scrollIntoView({behavior: 'smooth', block:'center'})};
    const singleimageClick = ()=>{singleimage.current?.scrollIntoView({behavior: 'smooth'})};
    const batchimageClick = ()=>{batchimage.current?.scrollIntoView({behavior: 'smooth', block:'center'})};
    const batchimagelogClick = ()=>{batchimagelog.current?.scrollIntoView({behavior: 'smooth'})};
    const getapicreditClick = ()=>{getapicredit.current?.scrollIntoView({behavior: 'smooth'})};


    return(
        <>
        <Navbar expanded={mobileExpanded} expand="lg" bg="light" fixed="bottom" className="shadow-sm d-lg-none" id="mainNav">
                <Container className="px-5">
                    <Navbar.Toggle type="button" className="w-100" onClick={()=>{setMobileExpanded(!mobileExpanded)}} aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Quick Menu
                    </Navbar.Toggle>
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className=" ms-auto me-4 my-3 my-lg-0">
                            <Nav.Item className="fw-bold">회사소개</Nav.Item>
                            <Nav.Item onClick={()=>{userauthClick(); setMobileExpanded(false)}}>인사말</Nav.Item>
                            <Nav.Item onClick={()=>{singleimageClick(); setMobileExpanded(false)}}>회사연혁</Nav.Item>
                            <Nav.Item onClick={()=>{batchimageClick(); setMobileExpanded(false)}}>사업분야</Nav.Item>
                            <Nav.Item onClick={()=>{batchimagelogClick(); setMobileExpanded(false)}}>CI</Nav.Item>
                            <Nav.Item onClick={()=>{getapicreditClick(); setMobileExpanded(false)}}>회사소개서</Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        <div className="px-lg-5 mx-lg-5">
            
            <Row>
                <Col lg={2} className="d-lg-block d-none">
                    <ListGroup variant="flush" style={{position:"sticky", top:"70px"}}>
                        <ListGroup.Item className="fw-bold fs-6">회사소개</ListGroup.Item>
                        <ListGroup.Item action onClick={userauthClick} className="ps-4 fs-6">인사말</ListGroup.Item>
                        <ListGroup.Item action onClick={singleimageClick} className="ps-4 fs-6">회사연혁</ListGroup.Item>
                        <ListGroup.Item action onClick={batchimageClick} className="ps-4 fs-6">사업분야</ListGroup.Item>
                        <ListGroup.Item action onClick={batchimagelogClick} className="ps-4 fs-6">CI</ListGroup.Item>
                        <ListGroup.Item action onClick={getapicreditClick} className="ps-4 fs-6">회사소개서</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col lg={10} className="ps-lg-4 leftmenu-content">
                    <Row >
                        <div className="fw-bold fs-3 p-0 text-center" style={{wordBreak:"keep-all"}}>나미에스엔티 홈페이지의 방문을 환영합니다.</div>
                    </Row>
                    <Row style={{paddingTop:"4rem"}}>
                        <Row className="m-auto text-center">
                            <Col lg={12}>
                                <p>안녕하십니까?</p>
                                <p>나미에스엔티는 2018년에 설립한 이후 발전을 거듭하여,</p>
                                <p>현재는 하드웨어와 소프트웨어를 망라하는 종합 IT 기업으로 발돋움하였습니다.</p>
                                <p>그 동안 축적된 기술과 인프라를 바탕으로 고객분들께 언제나 놀라운 경험을 드릴 수</p>
                                <p ref={userauth}>있도록 끊임없이 연구하고 노력하겠습니다.</p><br/>
                                <p>가장 쉽게 만나보실 수 있는 PC 제품을 필두로,</p>
                                <p>나미에스엔티는 다양한 형태로 고객분들께 다가가기 위해 노력하고 있습니다.</p>
                                <p>SW 솔루션 개발사업의 매출은 지속적으로 증가하고 있으며,</p>
                                <p>다른 분야로의 사업 확장도 적극적으로 추진하고 있습니다.</p>
                                <p>단순히 제품을 공급하거나 개발하는 것에 그치지 않고</p>
                                <p>보유하고 있는 기술과 자본을 바탕으로 나미에스엔티와 함께하시는</p>
                                <p>고객 분들께 놀라움을 드리기 위해 최선을 다하겠습니다.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Container className="fw-bold text-end">
                                <span className="mb-3">공동대표 김노산, 이상석</span><br/>
                                <img style={{width:"80px"}} className="ms-3" src="/assets/img/k_sign.png"/>
                                <img style={{width:"80px"}} src="/assets/img/l_sign.png"/>
                            </Container>
                        </Row>
                    </Row>
                    <Row ref={singleimage} style={{paddingTop:"2rem"}}>
                        <div className="fs-3 mb-4 mt-5 fw-bold text-center">회사연혁</div>
                        <Row className="m-auto">
                            <Col lg={6}>
                                <Container>
                                    <div className="fw-bold h4" style={{color:"#2E2E2E"}}>2018</div>
                                    <Container className="ms-3 pb-4" style={{borderBottom:"2px solid #848484"}}>
                                        <div>
                                            <span className="me-3 fw-bold">4월</span>
                                            <span className="">나미에스엔티 창업</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">7월</span>
                                            <span className="">통신판매업 신고</span>
                                        </div>
                                        <div>
                                            <span className="me-3"></span>
                                            <span className="ms-4">자동음향기기 설계 및 시제품 개발</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">9월</span>
                                            <span className="">나미스토어 오픈</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-2 fw-bold">11월</span>
                                            <span className="">나미스토어 네이버스마트스토어 오픈</span>
                                        </div>
                                        <div>
                                            <span className="me-3"></span>
                                            <span className="ms-4">나미스토어 인터파크, 옥션, G마켓, 11번가 오픈</span>
                                        </div>
                                        <div>
                                            <span className="me-3"></span>
                                            <span className="ms-4">나미표준PC 최초 런칭</span>
                                        </div>
                                        <div>
                                            <span className="me-3"></span>
                                            <span className="ms-4">나미표준PC 1호 판매</span>
                                        </div>
                                    </Container>
                                    <div className="fw-bold h4 mt-3" style={{color:"#2E2E2E"}}>2019</div>
                                    <Container className="ms-3 pb-4"  style={{borderBottom:"2px solid #848484"}}>
                                        <div>
                                            <span className="me-3 fw-bold">1월</span>
                                            <span className="">사업장 이전(서울특별시 용산구 청파동)</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">3월</span>
                                            <span className="">PC 부품시세 수집 및 분석 데이터베이스 구축</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-2 fw-bold">12월</span>
                                            <span className="">서울대학교 사범대학 연구 참여(인공지능기반 SW 플랫폼 개발)</span>
                                        </div>
                                    </Container>
                                    <div className="fw-bold h4 mt-3" style={{color:"#2E2E2E"}}>2020</div>
                                    <Container className="ms-3 pb-4">
                                        <div>
                                            <span className="me-3 fw-bold">2월</span>
                                            <span className="">예비창업패키지 인공지능 관련 웹서비스 개발 수주</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">7월</span>
                                            <span className="">사업장 이전(서울특별시 강동구 천호동)</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">8월</span>
                                            <span className="">AMD 라이젠 파트너스 자격 획득</span>
                                        </div>
                                    </Container>
                                </Container>
                            </Col>
                            <Col lg={6}>
                                <Container>
                                    <div className="fw-bold h4" style={{color:"#2E2E2E"}}>2021</div>
                                    <Container className="ms-3 pb-4" style={{borderBottom:"2px solid #848484"}}>
                                        <div>
                                            <span className="me-3 fw-bold">1월</span>
                                            <span className="">배재고등학교 프리칼리지 라즈베리파이 프로젝트 수업 및 SW 개발</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">5월</span>
                                            <span className="">나미에스엔티 서버실 확장 이전</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-2 fw-bold">11월</span>
                                            <span className="">애드쇼핑 인공지능 기반 서비스 개발 수주</span>
                                        </div>
                                    </Container>
                                    <div className="fw-bold h4 mt-3" style={{color:"#2E2E2E"}}>2022</div>
                                    <Container className="ms-3 pb-4" style={{borderBottom:"2px solid #848484"}}>
                                        <div>
                                            <span className="me-3 fw-bold">1월</span>
                                            <span className="">배재중학교 전자학운위시스템 1차 개발 수주</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">3월</span>
                                            <span className="">Lenovo Tier2 파트너 인증 획득</span>
                                        </div>
                                        <div>
                                            <span className="me-3"></span>
                                            <span className="ms-4">애드쇼핑 쇼핑몰 고도화 및 개선 작업 수주</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-3 fw-bold">7월</span>
                                            <span className="">애드쇼핑 인공지능 기반 서비스 고도화 개발 수주</span>
                                        </div>
                                    </Container>
                                    <div className="fw-bold h4 mt-3" style={{color:"#2E2E2E"}}>2023</div>
                                    <Container className="ms-3">
                                        <div>
                                            <span className="me-3 fw-bold">8월</span>
                                            <span className="">나미에스엔티 인공지능 서비스(NAMISNT AI) 런칭</span>
                                        </div>
                                        <div className="mt-3">
                                            <span className="me-2 fw-bold">12월</span>
                                            <span className="">배재중학교 전자학운위시스템 2차 개발 수주</span>
                                        </div>
                                    </Container>
                                </Container>
                            </Col>
                        </Row>
                    </Row>
                    <Row style={{paddingTop:"4rem"}}>
                        <Row className="m-auto">
                            <Container className=" gx-5 align-items-center section-content">
                                <Row>
                                    <h3 className="text-center fw-bold pt-5">나미에스엔티의<br/>다양한 사업분야를 확인해보세요.</h3>
                                    <p className="text-center mb-5">나미에스엔티는 고객님께 새롭고 놀라운 서비스를 만들기 위해<br/>항상 노력하고 있습니다.</p>
                                </Row>
                                <Row ref={batchimage} >
                                    <Col lg={4} className="text-center">
                                        <h4 className="text-center fw-bold mb-3 pt-5">PC,서버 조립/유통</h4>
                                        <p className="text-center">AMD 라이젠 파트너스, Lenovo T2 파트너사인 나미에스엔티는 언제나 준비되어 있습니다.</p>                                    </Col>
                                    <Col lg={4} className="text-center">
                                        <h4 className="text-center fw-bold mb-3 pt-5">웹/모바일앱 솔루션 개발</h4>
                                        <p className="text-center">나미에스엔티는 다양한 경험을 통해 고객님께 가장 필요한 솔루션을 만들어드립니다.</p>                                    </Col>
                                    <Col lg={4} className="text-center">
                                        <h4 className="text-center fw-bold mb-3 pt-5">인공지능 API</h4>
                                        <p className="text-center">나미에스엔티 AI가 인공지능의 대중화를 선도합니다.</p>                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                    </Row>
                    <Row ref={batchimagelog} style={{paddingTop:"4rem"}}>
                        <div className="fs-3 mb-4 mt-5 fw-bold text-center">CI</div>
                        <Container className="m-auto px-3">
                            <Container className="pe-4">
                                <p>나미에스엔티의 나미는 일어로 파도를 의미하며</p>
                                <p>끊임없는 도전을 통한 혁신과 젊음을 추구하고자 하는 의지의 표현입니다.</p>
                                <p>에스엔티는 <strong>Software(또는 Solution) & Technology</strong>를 의미합니다.</p>
                                <p><strong>나미에스엔티는 역동적인 자세로, 큰 바다 한가운데 높은 파도와 같이 앞으로 나아가고자 하는 노력을 표현합니다.</strong></p>
                            </Container>
                            <Row>
                                <Col lg={4} className="p-0">
                                    <img className="w-100 pe-2 pe-lg-3" src="/assets/img/ci1.png"/>
                                </Col>
                                <Col lg={4} className="p-0">
                                    <img className="w-100 pe-2 pe-lg-3" src="/assets/img/ci2.png"/>
                                </Col>
                                <Col lg={4} className="p-0">
                                    <img className="w-100 pe-2 pe-lg-3" src="/assets/img/ci3.png"/>
                                </Col>
                            </Row>
                            <Row className="mt-lg-3">
                                <Col lg={4} className="p-0">
                                    <img className="w-100 pe-2 pe-lg-3" src="/assets/img/ci4.png"/>
                                </Col>
                                <Col lg={4} className="p-0">
                                    <img className="w-100 pe-2 pe-lg-3" src="/assets/img/ci5.png"/>
                                </Col>
                                <Col lg={4} className="p-0">
                                    <img className="w-100 pe-2 pe-lg-3" src="/assets/img/ci6.png"/>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                    <Row ref={getapicredit} style={{paddingTop:"4rem"}}>
                        <Row className="fs-3 mb-4 fw-bold">회사소개서</Row>
                        <Row className="m-auto">
                        <iframe src="https://file.namisnt.com/pdfjs/web/viewer.html?file=https://file.namisnt.com/docu/나미에스엔티_회사소개서_20240105.pdf" style={{width:"100%", height:"1000px"}}></iframe>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </div>
        </>
    );

}

export default Docs_Greeting;
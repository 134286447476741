import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../common/Loading";

function ModelInfoContent(props){
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [data, setData] = useState({uid:0, model_name:"", category_uid:0, img_loc:"", created_date:"", category:[], equipments:[]});
    const [loading, setLoading] = useState(false);

    const [swType, setSwType]= useState({drivers:"드라이버", utils:"유틸"});    

    const [osList, setOSList] = useState([]);
    const [cur_os, setCurOS] = useState(0);
    const [listData, setListData] = useState([]);
    const [swCategory, setSWCategory] = useState([]);

    const handleChange = e => {
        setQuery(e.target.value);
    }
    const handleChangeOS = e => {
        setCurOS(e.target.value);
      }
    const concatCategory = (list)=>{
        if(list == undefined)
            return;
        let str = "";
        for(var i=0; i<list.length; i++){
            str += list[i].category_name;
            if(i+1 < list.length)
                str += " > ";
        }
        return str;
    }
    const getOSList = () =>{
        axios.get('/rest/getSoftwareOS',
        { params : {model_uid : Number(props.uid)}}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                setOSList(()=>{return response.data.list});
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }

    const requestList = () =>{
        axios.get('/rest/getModelInfo', 
            {params:
            {
                uid : Number(props.uid)
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                //let totalSize = response.data.count;
                //let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setData(list => response.data.data);
                //setTotalSize(totalSize);
                //setTotalPage(totalPage_);
                getSWList();
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
	
    const getSWList = () =>{
        if(osList.length == 0)
            return;
        axios.get('/rest/searchSoftware', 
            {params:
            {
                model_uid : Number(props.uid),
                os_uid : Number(osList[cur_os].uid)
            }}).then(response => {
            if(response.data.logged === false || response.data == ''){
                navigate("/");
            }else{
                //let totalSize = response.data.count;
                //let totalPage_ = totalSize%pagelimit >0 ? (totalSize/pagelimit)+1 : totalSize == 0 ? 1 : (totalSize/pagelimit);
                setListData(list => response.data.data);
                //setTotalSize(totalSize);
                //setTotalPage(totalPage_);
            }
        }).catch(
            error=>{
                navigate("/");
                console.log(error);
            }
        );
    }
    const downloadFile = (url) => {      
        const a = document.createElement('a');
        a.href = url;
        a.download="";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
            window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
    };
    useEffect(()=>{
            requestList();
            if(listData != undefined)
                getOSList();
    }, [])
    useEffect(()=>{
        requestList();
        if(listData != undefined)
            getOSList();
    }, [props.uid])
    useEffect(()=>{
        if(osList != undefined)
            getSWList();
    }, [osList, cur_os])
    useEffect(()=>{
        props.setPage(data.model_name)
    }, [data])
    return(
    <>
        {loading == true ? <Loading/> : ""}
        <section className="section-content mb-4 subcribe-price-section">
            <div className="policy-body">
                <Row>
                    <Col lg={6} className="text-center">
                        <img className="w-100 w-md-75 px-5" src={"/rest/downloadFile?filename="+data.img_loc}/>
                    </Col>
                    <Col lg={6}>
                        <Row className="fs-4 fw-bold">
                            {data.model_name}
                        </Row>
                        <Row className="fs-6 text-secondary mb-2">
                            {concatCategory(data.category)}
                        </Row>
                        {data.equipments.map(function(a, i){
                            return(
                                <Row key={i}>
                                    <Col lg={3}>
                                        {a.type_name}
                                    </Col>
                                    <Col lg={9}>
                                        {a.equipment_name}
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>
            </div>
            <div className="policy-header my-4 pt-4 text-center border-top">
                <h4><strong>SW 다운로드</strong></h4>
                <h6></h6>
            </div>
            <Row className="mb-4">
                <Col lg={4}>
                    <Form.Group>
                        <Form.Label>OS</Form.Label>
                        <Form.Select name="os_select" value={cur_os} onChange={handleChangeOS} aria-label="OS 선택">
                            {osList.map(function(item, idx){
                                return (<option key={idx} value={idx}>{item.os_name}</option>);
                            })}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            {
                Object.keys(listData).map(function(category_name, i){
                    return(
                        <Row key={i}>
                            <Row className="fs-4 mb-3 fw-bold">{category_name}</Row>
                            <Row className="ps-lg-4">
                                {
                                    listData[category_name].map(function(a, idx){
                                        return(
                                            <Card body key={idx} className="mb-3">
                                                <div className="fw-bold pb-3">{a.file_name}</div>
                                                <Row>
                                                    <Col lg={4}>
                                                        {a.created_date}
                                                    </Col>
                                                    <Col lg={8}>
                                                        <div className="float-end">
                                                            <Button variant="secondary" onClick={(e)=>{downloadFile("/rest/downloadSoftware?software_uid="+a.uid);}}>
                                                                다운로드
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )
                                    })
                                }
                            </Row>
                        </Row>
                    )
                })
            }
        </section>
    </>
    );

}

export default ModelInfoContent;
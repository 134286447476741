import React, {useEffect, useState} from "react";
import { useMediaQuery } from "react-responsive";
import 'bootstrap/dist/css/bootstrap.css'
import '../css/common.css';

function PageSelector(props){

    const [startPage, setStartPage] = useState(0);
    const [PageArr, setPageArr] = useState([]);
    const [pagenum, setPageNum] = useState(10);
    const [isMobile, setIsmobile] = useState(false);

    const handleRes = ()=>{
        let mql = window.matchMedia("(min-width:0px) and (max-width:767px)");
        setIsmobile(mql.matches);

    }
    const makepages = ()=>{
        if(props.curPage%pagenum ==0){
            setStartPage(props.curPage-pagenum+1);
        }else{
            setStartPage(props.curPage-(props.curPage%pagenum)+1);
        }
        let new_pages = [];
        for(let i=startPage; i<=(props.totalPage-startPage-pagenum > 0 ? startPage+pagenum-1 : props.totalPage); i++){
            new_pages.push(i);
        }
        
        setPageArr(new_pages);
    }
    const goPageHandler = (page, e)=>{
        e.preventDefault();
        props.PageHandler_(page);
    };
    useEffect(()=>{
        window.addEventListener("resize", handleRes);
    },[])
    useEffect(()=>{
        
        if(isMobile == true){
            setPageNum(6);
        }else{
            setPageNum(10);
        }
        makepages();
    },[props.curPage, props.totalPage, startPage, pagenum, isMobile]);
    

    return (
        <div className="board-bt mx-auto text-center">
			<span>
                {
                PageArr.map(function(item){
                    const pages = [];
                    if(PageArr.length > 0 && item == startPage && startPage != 1){
                        pages.push(<div className="page-it" key="prev" onClick={(e)=>{goPageHandler(props.curPage-1,e)}}>&lt;</div>);
                    }
                    pages.push(<div className={`page-it ${item == props.curPage ? "cur" : ""}`} key={item} onClick={(e)=>{if(props.curPage != item)goPageHandler(item,e)}}>{item}</div>);
                    if(PageArr.length == pagenum &&  item == startPage+pagenum-1){
                        pages.push(<div className="page-it" key="next" onClick={(e)=>{goPageHandler(props.curPage+1,e)}}>&gt;</div>);
                    }
                    return pages;
                })
                }
			</span>
		</div>
    );
}

export default PageSelector;